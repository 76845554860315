/*辅助资料相关接口*/
import request from '../utils/request'

// 通过会员名称获取会员列表
export function findMemberListByName(data) {
    return request({
        url: '/auxiliaryData/findMemberList',
        method: 'get',
        params: data
    })
}

// 通过公司名称获取公司列表
export function findCompanyList(data) {
    return request({
        url: '/auxiliaryData/findCompanyList',
        method: 'get',
        params: data
    })
}

// 通过职称名称获取职称列表
export function findPostFunctionList(data) {
    return request({
        url: '/auxiliaryData/findPostFunctionList',
        method: 'get',
        params: data
    })
}

// 购买须知
export function notesToBuy(data) {
    return request({
        url: '/auxiliaryData/notesToBuy',
        method: 'get',
        params: data
    })
}
