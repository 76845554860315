import Vue from 'vue'
import VueRouter from 'vue-router'

import home from '@/view/home'
import login from '@/view/login/login'
import register from '@/view/login/register'
import forgetPsd from '@/view/login/forget_psd'
import member from '@/view/member/index'
import classify from '@/view/member/classify'
import noticeIndex from '@/view/activityNotice/index'
import messageCenter from '@/view/message_center/index'
import ActivityNotice from '@/view/activityNotice/ActivityNotice'
import ActivityNoticeDetail from '@/view/activityNotice/detail'
import project from '@/view/project/project'
import recommend from '@/view/recommend'
import user from '@/view/user'
import wallet from '@/view/wallet'
import VIP from '@/view/VIP'
import {store} from '@/vuex/store'
import * as path from "path";
import {queryByIdentify} from "@/api/homeApi";


Vue.use(VueRouter)
const routes = [
    //临时
    {
        path: '/demo',
        component: () => import('@/view/videoTalk/demo'),
        name: 'demo',

    },
    //支付结果
    {
        path: '/paymentResult',
        component: () => import('@/view/paymentResult'),
        name: '支付结果',

    },
    {
        //声网
        path: '/videoDemo',
        component: () => import('@/view/videoTalk/demo'),
    },
    {
        //视频房间
        path: '/videoRoom',
        component: () => import('@/view/videoTalk/video_room'),
    },

    {
        path: '/',
        redirect: '/home', //设置默认指向的路径
    },
    {
        path: '/home',
        component: home,
        name: 'home',
        meta: {
            title: '所罗门啊啊啊啊啊啊啊啊啊啊啊',
        }
    },
    {
        path: '/login',
        component: login,
        name: 'login',
        meta: {
            showHeader: false
        },
    },
    //注册
    {
        path: '/register',
        component: register,
        name: 'register',
        meta: {
            showHeader: false
        },
    },
    //忘记密码
    {
        path: '/forgetPsd',
        component: forgetPsd,
        name: 'forgetPsd',
        meta: {
            showHeader: false
        },
    },
    //会员
    {
        path: '/member',
        component: member,
        name: 'member',
        query: {
            id: ''
        },
    },
    //会员分类
    {
        path: '/classify',
        component: classify,
        name: 'classify',
    },
    //会员分类
    {
        path: '/companyList',
        component: () => import('@/view/company/company_list'),
        name: 'companyList',
    },
    //项目
    {
        path: '/project',
        component: project,
        name: 'project',
        children: [
            {
                path: 'index',
                component: () => import('@/components/project/index'),
            },
            {
                path: 'addProject',
                component: () => import('@/components/project/AddProject'),
            },
            {
                path: 'editProject/:id',
                component: () => import('@/components/project/EditProject'),
            },
            {
                path: 'threeProject',
                component: () => import('@/components/project/threeProject'),
            },
            {
                path: 'addProjectOfInterview',
                component: () => import('@/components/project/AddProjectOfInterview'),
            },
            {
                path: 'details/:id',
                component: () => import('@/components/project/details'),
            },
        ]
    },
    //活动公告
    {
        path: '/activityNotice',
        component: noticeIndex,
        name: 'noticeIndex',
        children: [
            {
                path: 'index',
                component: ActivityNotice,
            },
            {
                path: 'detail',
                component: ActivityNoticeDetail,
                query: {
                    id: ''
                },
            },
        ],
    },
    //消息中心
    {
        path: '/messageCenter',
        component: messageCenter,
    },
    //我的项目
    {
        path: '/myProject',
        component: () => import('@/view/my_project/index'),
        redirect: '/myProject/publishTab', //设置默认指向的路径
        children: [
            //我发布的项目
            {
                path: 'publishTab',
                component: () => import('@/view/my_project/PublishTab'),
            },
            //我参与的项目
            {
                path: 'participateTab',
                component: () => import('@/view/my_project/ParticipateTab'),
            },
            //我的访谈
            {
                path: 'interviewTab',
                component: () => import('@/view/my_project/InterviewTab'),
            },
            //我的人脉
            {
                path: 'relationshipTab',
                component: () => import('@/view/my_project/RelationshipTab'),
            },
        ],
    },
    //VIP私密推荐
    {
        path: '/vipPrivateRecommendation/:id',
        component: () => import('@/view/my_project/vip_private_recommendation/index'),
    },
    //推荐原因
    {
        path: '/recommendationReason/:id',
        component: () => import('@/view/my_project/vip_private_recommendation/RecommendReason'),
    },
    //我感兴趣愿意免费沟通的项目
    {
        path: '/interestedProject/:id',
        component: () => import('@/view/my_project/project_interest/index'),
    },
    //我推荐给其他会员的项目
    {
        path: '/meToOtherProject/:id',
        component: () => import('@/view/my_project/me_to_other_project/index'),
    },
    //其他会员推荐给我的项目
    {
        path: '/otherToMeProject/:id',
        component: () => import('@/view/my_project/other_to _me_project/index'),
    },
    //我浏览过的项目
    {
        path: '/browsingProject/:id',
        component: () => import('@/view/my_project/browsing-project/index'),
    },
    //我发布的项目
    {
        path: '/myPublishedProject',
        component: () => import('@/view/my_project/my_published_projects/index'),
        query: {
            id: ''
        },
    },
    //我发布的项目 发起多人访谈
    {
        path: '/Multiperson',
        component: () => import('@/view/my_project/my_published_projects/Multiperson'),
        query: {
            id: ''
        },
    },
    //我的访谈
    {
        //我发起的
        path: '/myInterview/publishedInterview/:id',
        component: () => import('@/view/my_project/my_interview/publishedInterview'),
    },
    {
        //平台推荐的vip
        path: '/myInterview/receiveInterviewVip/:id',
        component: () => import('@/view/my_project/my_interview/receiveInterviewVip'),
    },
    {
        //平台推荐的
        path: '/myInterview/receiveInterview/:id',
        component: () => import('@/view/my_project/my_interview/receiveInterview'),
    },
    {
        //发起三分钟
        path: '/myInterview/fiveTimeInterview',
        component: () => import('@/view/my_project/my_interview/fiveTimeInterview'),
    },
    {
        //发起多人邀约
        path: '/myInterview/publishMulti',
        component: () => import('@/view/my_project/my_interview/publishMulti'),
    },
    {
        //受邀人多人邀约
        path: '/myInterview/receiveMulti/:id',
        component: () => import('@/view/my_project/my_interview/receiveInterviewMulti'),
    },
    {
        //声网
        path: '/live',
        component: () => import('@/view/videoTalk/index'),
    },

    //
    {
        path: '/recommend',
        component: recommend,
        meta: {
            showHeader: false,
            isNav: 8,
        },
    },
    //个人中心
    {
        path: '/user',
        component: user,
        children: [
            {
                path: 'index',
                component: () => import('@/components/user/index'),
            },
            {
                path: 'redact',
                component: () => import('@/components/user/redact'),
            },
            {
                path: 'companyDetails',
                component: () => import('@/components/user/company_details'),
            },
            {
                path: 'account',
                component: () => import('@/components/user/account'),
            },
            {
                path: 'orderManagement',
                component: () => import('@/components/user/orderManagement'),
            },
        ]
    },
    //我的钱包
    {
        path: '/wallet',
        component: wallet,
    },
    //vip
    {
        path: '/VIP',
        component: VIP,
    },
    //协议
    {
        path: '/agreement',
        component: () => import('@/view/login/agreement'),
        meta: {
            showHeader: false,
            isNav: 8,
        },
    },
]
const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes,
});

const allowRouter = ["/", '/home', '/home/', '/login', '/register', '/forgetPsd', '/agreement', '/classify', '/member', '/companyList', '/project/index', '/demo', '/project/details/'];

router.beforeEach((to, from, next) => {


    if (to.path == '/' || to.path == '/home') store.commit("identifyStatus", null)
    if (store.state.identify != null) {
        //专属链接
        if (from.path == '/home' && to.path != '/user/account' && store.state.token == '') {

            to.meta.showHeader = false
            next('/user/account') // 必须使用 next ,执行效果依赖 next 方法的调用参数
        } else {
            console.log(from.path)
            console.log(to.path)

            if (from.path == '/user/account') {
                if (to.path == '/register') {
                    next()
                } else {
                    next(false)
                }
            } else {
                to.meta.showHeader = false
                next() // 必须使用 next ,执行效果依赖 next 方法的调用参数
            }
        }
    } else {
        // console.log("非专属链接")
        // console.log(store.state.isLogin)
        if (store.state.isLogin) {
            to.meta.showHeader = to.meta.showHeader !== false;
            next() // 必须使用 next ,执行效果依赖 next 方法的调用参数
        } else {

            console.log("当前路由：", to.path)
            console.log("路由是否允许：", allowRouter.includes(to.path))


            if (allowRouter.includes('/home') || allowRouter.includes('/home/')) {
                setMeta(to).then(r => {
                    // console.log("111111111111111111111111111111111111111111")
                    // console.log("ttt",(to.path).indexOf("/project/details/"))
                    if (allowRouter.includes(to.path) || (to.path).indexOf("/project/details/") != -1) {
                        to.meta.showHeader = to.meta.showHeader !== false;
                        next() // 必须使用 next ,执行效果依赖 next 方法的调用参数
                    } else {
                        next('/login')
                    }
                });
            } else {
                // console.log("tttrerrrrrrrrrrrrrrrrr")
                // console.log("ttt",(to.path).indexOf("/project/details/"))
                if (allowRouter.includes(to.path) || (to.path).indexOf("/project/details/") != -1) {
                    to.meta.showHeader = to.meta.showHeader !== false;
                    next() // 必须使用 next ,执行效果依赖 next 方法的调用参数
                } else {
                    next('/login')
                }
            }


        }
    }
});

async function setMeta(to) {
    let formData = {
        identify: '111'
    }

    await queryByIdentify(formData).then((res) => {
        console.log(res);
        if (res.code == 200) {
            to.query.imgUrl = res.data.imgUrl;
            to.query.desc = res.data.desc;
        }
    })

}


export default router
