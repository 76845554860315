export const pageMixin = {
    data() {
        return {
            pageNum: 1,
            pageSize: 10,
            total: 0,
            goPageNum: 1,
            list: [],
        };
    },
    created() {
        this.hello()
    },
    methods: {
        hello() {
            console.log('hello from mixin!')
        }
    }
}
