<template>
    <div class="container">
        <!--吐司导航-->
        <breadcrumb-nav :pageName="$t('message.message')"></breadcrumb-nav>
        <div class="main">
            <div class="message-list" v-if="list.length!=0">
                <MessageItem :key="index" :val="item" v-for="(item,index) in list"/>
            </div>
            <div style="height: 100vh;text-align: center" v-else>
                {{$t("message.noNews")}}
            </div>

        </div>
        <el-pagination
                :current-page.sync="pageNum"
                :page-size="pageSize"
                :total="total"
                @current-change="handleCurrentChange"
                layout="prev, pager, next,slot"
                style="text-align: center"
                v-if="total !== 0"
        >
            <slot>
                <div class="jump-page">
                    <span>{{$t("memberClassification.goTo")}}</span>
                    <input type="text" v-model.number="goPageNum"/>
                    <i @click="goJumpPage(goPageNum)" class="el-icon-arrow-right"></i>
                </div>
            </slot>
        </el-pagination>
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import MessageItem from "@/view/message_center/MessageItem";
    import {newsList} from "@/api/messageCenterApi"
    import {pageMixin} from "@/mixins";
    import Loading from "@/components/loading";


    export default {
        name: "index",
        mixins: [pageMixin],
        data() {
            return {
                isLoading: false,

            };
        },
        components: {
            BreadcrumbNav,
            MessageItem,
            Loading
        },
        async created() {
            await this.getDataList();
        },
        methods: {
            goJumpPage(num) {
                this.pageNum = num;
                this.getDataList();
            },
            handleCurrentChange: function handleCurrentChange(p) {
                this.pageNum = p;
                this.getDataList();
            },
            //请求数据列表（目列表）
            async getDataList() {
                let formData = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    token: localStorage.getItem("token"),
                };
                this.isLoading = true;
                let res = await newsList(formData);
                this.isLoading = false;
                if (res.code == 200) {
                    this.total = res.data?.total ?? 0;
                    this.list = res.data?.list ?? [];
                } else {
                    this.total = res.data?.total ?? 0;
                    this.list = res.data?.list ?? [];
                }
            },
        }
    }
</script>

<style scoped>
    .main {
        width: 1200px;
        height: auto;
        margin: 0 auto;
        padding: 53px 0 70px;
    }

    .jump-page {
        display: inline-block;
        vertical-align: middle;
        width: 124px;
        height: 40px;
        background: #fafafa;
        border-radius: 131px;
        margin-left: 30px;
        line-height: 40px !important;
        text-align: center;
    }

    .jump-page > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        vertical-align: middle;
    }

    .jump-page > input {
        background: transparent;
        width: 40px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin-top: 2px;
    }

    .jump-page > i {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        font-weight: 700;
        font-size: 19px;
    }
</style>
