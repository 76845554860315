<template>
    <!--弹窗-->
    <el-dialog :visible.sync="dialogFormVisible" class="dialog-wrap" width="1004px">
        <h1 class="dialog-title" slot="title">{{ $t("myWallet.applyForARefund") }}</h1>
        <!--订单信息-->
        <div class="order-content">
            <table>
                <tr>
                    <td style="width: 200px" v-show="orderInfo.order_type == 1">{{$t("myInterviewDetail.invitee")}}</td>
                    <!--受邀者-->
                    <td>{{$t("myInterviewDetail.orderSerialNumber")}}<!--订单编号--></td>
                    <td>{{$t("myInterviewDetail.orderTime")}}<!--订单时间--></td>
                    <td v-show="orderInfo.order_type == 1">{{$t("myInterviewDetail.speedOfProgress")}}<!--进度--></td>
                    <td>{{$t("myInterviewDetail.money")}}<!--金额--></td>
                    <td>{{ $t("bulletFrame.paymentStatus") }}</td>
                </tr>
                <tr>
                    <td v-show="orderInfo.order_type == 1">
                        <div class="people">
                            <div class="avatar">
                                <img :src="orderInfo.invitee_avatar" alt="" style="width: 100%;height: 100%;">
                            </div>
                            <div class="column">
                                <div class="name over">{{ orderInfo.invitee_name }}</div>
                                <div class="company over">{{ orderInfo.company_name }} · {{ orderInfo.position }}</div>
                            </div>
                        </div>
                    </td>
                    <td>{{ orderInfo.order_number }}</td>
                    <td v-if="orderInfo.pay_time != ''">{{ orderInfo.pay_time }}</td>
                    <td v-else>{{ orderInfo.create_time }}</td>
                    <td v-show="orderInfo.order_type == 1">{{ orderInfo.progress_name }}</td>
                    <td>{{ orderInfo.payment_amount }}</td>
                    <td>{{ orderInfo.payment_state_name }}</td>
                </tr>
            </table>
        </div>
        <!--退款原因-->
        <div class="reason">
            <textarea :placeholder="$t('bulletFrame.refundReason')" cols="30" id="" name="reason" rows="10"
                      v-model="reason"></textarea>
        </div>
        <div class="dialog-footer">
            <div @click="submit" class="submit-button">{{ $t("myWallet.applyForARefund") }}</div>
        </div>
        <div class="intro">
            <h1>{{ payRefundExplain }}:</h1>
            <p :key="index" v-for="(item,index) in sysDictDataList">{{ item }}</p>
        </div>
    </el-dialog>
</template>

<script>
    import {applyRefund, applyRefundForOrderInfo, getPayRefundExplain} from "@/api/refundApi";

    export default {
        name: "ApplyRefundDialog",
        data() {
            return {
                dialogFormVisible: false,
                orderId: '',
                reason: '',
                orderInfo: {},
                payRefundExplain: '',
                sysDictDataList: [],
            };
        },
        components: {},
        props: ['vipDetails'],
        methods: {
            //打开弹窗
            async openDialog(orderId) {
                this.dialogFormVisible = true;
                this.orderId = orderId;
                console.log("订单id", this.orderId)
                await this.applyRefundForOrderInfo();
                await this.getPayRefundExplain();
            },
            //关闭弹窗
            closeDialog() {
                this.dialogFormVisible = false;
            },
            //提交按钮
            async submit() {
                await this.applyRefund();

            },

            //申请退款，获取订单信息
            applyRefundForOrderInfo() {
                let formData = {
                    id: this.orderId,
                }
                applyRefundForOrderInfo(formData).then((res) => {
                    if (res.code == 200) {
                        this.orderInfo = res.data;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //购买与退款说明
            getPayRefundExplain() {
                let formData = {
                    dictType: 'pay_refund_explain',
                }
                getPayRefundExplain(formData).then((res) => {
                    if (res.code == 200) {
                        this.payRefundExplain = res.data.payRefundExplain;
                        this.sysDictDataList = res.data.sysDictDataList;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //申请退款
            applyRefund() {
                let formData = {
                    applyRefundReason: this.reason,
                    id: this.orderId,
                }
                applyRefund(formData).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        if (this.vipDetails == 'vip') {
                            this.$parent.get_interview();
                        } else {
                            this.$parent.getOrderList();
                        }
                        this.closeDialog();
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .dialog-wrap .dialog-title {
        text-align: center;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .order-content {
        width: 937px;
        margin: 0 auto;
    }

    .order-content table {
        width: 100%;
    }

    .order-content table > tr:first-child {
        width: 100%;
        height: 47px;
        background: #FAFAFA;
    }

    .order-content table > tr:first-child > td {
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        vertical-align: middle;
    }


    .order-content table > tr:last-child {
        width: 100%;
        height: 89px;
    }

    .order-content table > tr:last-child > td {
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        vertical-align: middle;
    }

    .people {
    }

    .people .avatar {
        display: inline-block;
        width: 49px;
        height: 49px;
        background-color: black;
        border-radius: 50%;
        vertical-align: middle;
    }

    .people .avatar img {
        width: 100%;
        height: 100%;
    }

    .people .column {
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
    }

    .people .column .name {
        width: 110px;
        font-size: 15px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 17px;
        color: #000000;
    }

    .people .column .company {
        width: 110px;
        margin-top: 6px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
    }

    .reason {
        width: 937px;
        margin: 21px auto 0;
        border-radius: 4px;

    }

    .reason textarea {
        width: 100%;
        background: #FAFAFA;
        border-radius: 4px;
        padding: 15px 26px;
        box-sizing: border-box;
    }

    .dialog-footer {
        margin-top: 37px;
    }

    .submit-button {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin: 0 auto;
        cursor: pointer;
    }

    .intro {
        width: 790px;
        margin: 22px auto 10px;
    }

    .intro h1 {
        font-size: 12px;
        font-family: MicrosoftYaHei-Bold;
        font-weight: bold;
        line-height: 24px;
        color: #000000;
    }

    .intro p {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
    }
</style>
