import request from '../utils/request'
//Stripe支付
//会员活动缴费Stripe发起支付
export function chargeActivities(data) {
    return request({
        url: '/stripe/create/chargeActivities',
        method: 'post',
        params: data
    })
}

//VIP服务购买Stripe发起支付
export function chargeVipService(data) {
    return request({
        url: '/stripe/create/chargeVipService',
        method: 'post',
        params: data
    })
}

//发布3分钟访谈——找人/找钱（融资）/找智慧Stripe发起支付
export function chargeThreeMinuteProject(data) {
    return request({
        url: '/stripe/create/chargeThreeMinuteProject',
        method: 'post',
        params: data
    })
}

//PayPal支付
export function createOrder(data) {
    return request({
        url: '/paypal/create/orders',
        method: 'post',
        params: data
    })
}

export function payCompleteInfo(data) {
    return request({
        url: '/paypal/capture/orders',
        method: 'get',
        params: data
    })
}


export function payByOrders(data) {
    return request({
        url: '/stripe/create/payByOrders',
        method: 'post',
        params: data
    })
}

