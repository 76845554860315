import Vue from "vue";
import Vuex from 'vuex'
import createVuexAlong from 'vuex-along'

Vue.use(Vuex)

export const store = new Vuex.Store({
    // 设置属性
    state: {
        isLogin: false,
        currentUser: null,
        token: "",
        identify: null,//专属链接字段
    },

    // 获取属性的状态
    getters: {
        //获取登录状态
        isLogin: state => state.isLogin,
        currentUser: state => state.currentUser,
        token: state => state.token,
        identify: state => state.identify,
    },

    // 设置属性状态
    mutations: {
        //保存登录状态
        userStatus(state, user) {
            if (user) {
                state.currentUser = user
                state.isLogin = true
                localStorage.setItem('token', user.token);
                state.identify = null;
            } else {
                localStorage.setItem('token', '');
                state.currentUser = null
                state.isLogin = false
                state.token = ""
            }
        },
        //更新头像
        updateAvatar(state, avatar) {
            state.currentUser.avatar = avatar
        },
        //专属链接判断
        identifyStatus(state, val) {
            if (val) {
                state.identify = val;
                //清楚登录状态
                localStorage.setItem('token', '');
                state.currentUser = null
                state.isLogin = false
                state.token = ""
            } else {
                state.identify = null;
            }
        }
    },

    // 应用mutations
    actions: {
        //获取登录状态
        userLogin({commit}, user) {
            commit("userStatus", user)
        },
    },
    plugins: [createVuexAlong()]//vuex-along
})
