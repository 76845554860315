import request from '../utils/request'

// 查询预注册、已注册页面
export function selectPcAccount(data) {
    return request({
        url: '/account/selectPcAccountInfoByTalent',
        method: 'post',
        data
    })
}

// 会员修改密码
export function changePassword(data) {
    return request({
        url: '/account/changePassword',
        method: 'post',
        data
    })
}

// 编辑个人资料
export function editTalentInfo(data) {
    return request({
        url: '/myMember/editTalentInfo',
        method: 'post',
        data
    })
}

//编辑个人资料获取
export function findTalentInfo(data) {
    return request({
        url: '/myMember/findTalentInfo',
        method: 'get',
        params: data
    })
}

//个人会员详情（自身、其他会员）
export function findMember(data) {
    return request({
        url: '/myMember/findMember',
        method: 'post',
        data
    })
}

//修改个人主页背景图
export function updateHomeBackground(data) {
    return request({
        url: '/myMember/updateHomeBackground',
        method: 'post',
        data
    })
}

//投诉
export function memberComplaint(data) {
    return request({
        url: '/member/memberComplaint',
        method: 'post',
        data
    })
}

//推荐
export function recommendContactsMember(data) {
    return request({
        url: '/member/recommendContactsMember',
        method: 'post',
        data
    })
}

//查询地区号
export function selectCountriesCodeInfo() {
    return request({
        url: '/common/selectCountriesCodeInfo',
        method: 'get'
    })
}
