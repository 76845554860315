<template>
    <div :class="{ active: isChecked}" class="custom-button">
        <slot>按钮</slot>
    </div>
</template>

<script>
    export default {
        name: "CustomButton",
        props: {
            isChecked: {
                type: Boolean,
                default: false,
            },

        },
    }
</script>

<style scoped>
    .custom-button {
        cursor: pointer;
        width: 170px;
        height: 40px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 131px;
        line-height: 40px;
        text-align: center;
        transition: .3s all;
        border: 1px solid #707070;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .active {
        color: #FFFFFF;
        background: #000000;
        border: 1px solid #000000;
    }

    .custom-button:hover {
        color: #FFFFFF;
        background: #000000;
        border: 1px solid #000000;
    }
</style>
