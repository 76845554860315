import request from '../utils/request'

// 申请退款，获取订单信息
export function applyRefundForOrderInfo(data) {
    return request({
        url: '/myOrder/applyRefundForOrderInfo',
        method: 'get',
        params: data,
    })
}

// 购买与退款说明
export function getPayRefundExplain(data) {
    return request({
        url: '/myOrder/getPayRefundExplain',
        method: 'get',
        params: data,

    })
}

// 申请退款
export function applyRefund(data) {
    return request({
        url: '/myOrder/applyRefund',
        method: 'post',
        data,
    })
}
