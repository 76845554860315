import request from "@/utils/request";


// 1、查询支付所需的订单信息
export function vipServicePurchase(data) {
    return request({
        url: '/vipService/vipServicePurchase',
        method: 'post',
        params: data
    })
}


// 2、PayPal发起支付A
export function serviceInfo(data) {
    return request({
        url: '/vipService/serviceInfo',
        method: 'get',
        params: data
    })
}
