<template>
    <div class="header">
        <div class="logo fl">
            <img alt="" height="50px" src="../../assets/images/logo@2x.png"/>
        </div>

        <div class="switchover">
            <div :class="[{'switchover_active' : type == 'en'},'switchover_item','cur','fl']"
                 @click="changeLanguage('en')">EN
            </div>
            <div :class="[{'switchover_active' : type == 'zh'},'switchover_item','cur','fl']"
                 @click="changeLanguage('zh')">CN
            </div>
            <div class="close_btn cur fl">
                <img @click="$router.go(-1)"
                     alt=""
                     height="16px" src="../../assets/images/icon_closed@2x.png" width="16px">
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="user_box out_line_box fr" style="margin-right: 100px;">
            <div
                    @click="gopage('/login')"
                    class="cur"
                    style="margin-right: 29px"
                    v-if="$route.path=='/register'"
            >
                {{ $t("user.login") }}
            </div>
            <div @click="gopage('/register')" class="cur"
                 style="margin-right: 29px" v-if="$route.path=='/login'">{{ $t("user.register") }}
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'loginHeader',
        data() {
            return {
                type: "zh",
            };
        },
        methods: {
            changeLanguage(type) {
                this.$i18n.locale = type;
                this.type = type;
                localStorage.setItem("languageSet", this.$i18n.locale); //将用户设置存储到localStorage以便用户下次打开时使用此设置
            },
            gopage(url) {
                this.$router.push(url);
            },
        },
    }
</script>

<style scoped>
    .header {
        position: relative;
        height: 66px;
        line-height: 66px;
        border-bottom: 1px solid #e5e5e5;
        padding: 0 40px;
    }

    .switchover {
        font-size: 12px;
        font-family: BentonSans;
        font-weight: 500;
        color: #949494;
        position: absolute;
        top: 20px;
        right: 40px;
    }

    .switchover_item {
        width: 28px;
        height: 27px;
        line-height: 27px;
        background: #ffffff;
        border-radius: 4px;
        text-align: center;
        margin-left: 6px;
    }

    .close_btn {
        line-height: 26px;
        margin-left: 22px;
    }

    .switchover_active {
        border: 1px solid #f1f1f1;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
    }
</style>
