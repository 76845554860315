<template>
    <div id="app">
        <showHeader v-if="$route.meta.showHeader"></showHeader>
        <!--<transition name="transitionRouter" mode="out-in">-->
        <router-view :key="$route.fullPath" @publicHeader="publicHeader" v-if="isRouterAlive"></router-view>
        <!--</transition>-->
        <showFooter></showFooter>
    </div>
</template>

<script>
    import showHeader from "./components/showHeader";
    import showFooter from "./components/showFooter";

    export default {
        name: "App",
        provide() {
            return {
                reload: this.reload
            }
        },
        components: {
            showHeader,
            showFooter,
        },
        data() {
            return {
                isRouterAlive: true,
            };
        },
        watch: {
            $route(to, from) {
                console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
                window.scrollTo(0, 0);
            }
        },
        methods: {
            publicHeader(bool) {
                this.headerShow = bool;
                console.log(bool, 'headerShow')
            },
            reload() {
                this.isRouterAlive = false;
                this.$nextTick(function () {
                    this.isRouterAlive = true
                })
            }
        },
    };
</script>

<style lang="css">
    @import "reset.css";

    .transitionRouter-enter-active, .transitionRouter-leave-active {
        transition: all .3s;
    }

    .transitionRouter-enter, .transitionRouter-leave {
        transform: translate3d(100%, 0, 0);
    }
</style>
