<template>
    <el-dialog :visible.sync="dialogVisible" width="400px">
        <div class="c-body">
            <img alt="" class="logo" src="@/assets/images/skPayLogo.png">
            <h1>www.BlessBoss.com</h1>
            <!--<p class="jd">HK$14 {{$t('stripePay.quarterly')}}&lt;!&ndash;每季度&ndash;&gt;</p>-->
            <div @click="pay" class="pay-btn">Pay</div>
            <div class="division">
                <div class="line"></div>
                <span class="line-intro">{{$t('stripePay.payWithBankCard')}}<!--或用银行卡支付--></span>
                <div class="line"></div>
            </div>
            <div class="custom-box">
                <div class="label">{{$t('accountManagement.fullName')}}<!--姓名--></div>
                <div class="input-area">
                    <input :placeholder="$t('publish.input')/*请输入*/" type="text" v-model="stripPayForm.name">
                </div>
            </div>
            <div class="custom-box">
                <div class="label">{{$t('stripePay.mail')}}<!--邮件--></div>
                <div class="input-area">
                    <input :placeholder="$t('publish.input')/*请输入*/" type="text" v-model="stripPayForm.email">
                </div>
            </div>
            <div class="custom-box">
                <div class="label">{{$t('stripePay.bankCard')}}<!--银行卡--></div>
                <div class="input-combine" style="padding: 0 23px">
                    <input :placeholder="$t('stripePay.cardNumber')" style="text-align: left; width: 120px;" type="text"
                           v-model="stripPayForm.number">
                    <div class="support">
                        <img alt="" src="@/assets/images/zffs1@2x.png">
                        <img alt="" src="@/assets/images/zffs2@2x.png">
                        <img alt="" src="@/assets/images/zffs3@2x.png">
                        <img alt="" src="@/assets/images/zffs4@2x.png">
                    </div>
                </div>
                <div class="input-combine">
                    <input :placeholder="$t('stripePay.monthYear')/*月月/年年*/" type="text"
                           v-model="stripPayForm.expMonthAndYear">
                    <div class="v-line"></div>
                    <input :placeholder="$t('stripePay.cardSecurityCode')/*卡安全码(CVC)*/" type="text"
                           v-model="stripPayForm.cvc">
                </div>
            </div>
            <div class="custom-box">
                <div class="label">{{$t('stripePay.countryOrAddress')}}<!--国家或地址--></div>
                <el-select :placeholder="$t('message.pleaseSelect')/*请选择*/" filterable v-model="stripPayForm.country">
                    <el-option :key="item.value" :label="item.label" :value="item.value"
                               v-for="item in options"></el-option>
                </el-select>
                <div class="input-area">
                    <input :placeholder="$t('stripePay.zipCode')/*邮编*/" type="text" v-model="stripPayForm.postcode">
                </div>
            </div>
            <div @click="bankPay" class="zf-btn">{{$t('myWallet.payment')}}<!--支付--></div>
        </div>

    </el-dialog>
</template>

<script>
    import {countryEn, countryZh} from "@/utils/country-data";

    export default {
        name: "SkPayDialog",
        data() {
            return {
                dialogVisible: false,
                country: "CN",
                stripPayForm: {
                    amount: "",
                    email: "",
                    number: "",
                    expMonthAndYear: "",
                    cvc: "",
                    name: "",
                    country: "",
                    postcode: "",
                },
            };
        },
        computed: {
            options() {
                return this.$i18n.locale == 'zh' ? countryZh : countryEn;
            }
        },
        methods: {
            open() {
                this.dialogVisible = true;
            },
            close() {
                this.dialogVisible = false;
            },
            //pay支付
            pay() {
                this.$emit("pay", "")
            },
            //银行支付
            bankPay() {
                console.log("bankPay1")
                this.$emit("bankPay", this.stripPayForm)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .el-dialog__body {
        .c-body {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .logo {
                width: 142px;
                height: 128px;
            }

            h1 {
                width: 100%;
                text-align: center;
                font-size: 26px;
                font-weight: bold;
                color: #000000;
                margin-top: 25px;
            }

            .jd {
                width: 100%;
                text-align: center;
                font-size: 16px;
                color: #000000;
                margin-top: 20px;

            }

            .pay-btn {
                width: 300px;
                height: 50px;
                background: linear-gradient(180deg, #000000 0%, #232321 100%);
                border-radius: 131px;
                text-align: center;
                line-height: 50px;

                font-size: 14px;
                color: #FFFFFF;
                margin-top: 47px;
            }

            .division {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 31px;
                margin-bottom: 25px;

                .line {
                    width: 91px;
                    height: 0;
                    border: 1px solid #D6D6D6;
                }

                .line-intro {
                    font-size: 14px;
                    color: #949494;
                }
            }

            .custom-box {
                margin-bottom: 24px;

                ::v-deep.el-select {
                    margin-top: 12px;
                    width: 350px;

                    .el-input__inner {
                        height: 50px;
                        padding-left: 27px;
                    }
                }

                .label {
                    font-size: 16px;
                    color: #000000;
                }

                .input-area {
                    width: 350px;
                    height: 50px;
                    background: #FAFAFA;
                    border-radius: 131px;
                    margin-top: 12px;

                    input {
                        padding: 0 27px;
                        font-size: 14px;
                        height: 100%;
                        background-color: transparent;
                    }
                }

                .input-combine {
                    box-sizing: border-box;
                    width: 350px;
                    height: 50px;
                    background: #FAFAFA;
                    border-radius: 131px;
                    margin-top: 12px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .support {
                        img {
                            width: 24.94px;
                            height: 15.42px;
                            margin-left: 6px;
                        }
                    }

                    .v-line {
                        width: 0;
                        height: 22px;
                        border: 1px solid #D6D6D6;
                    }

                    input {
                        font-size: 14px;
                        background-color: transparent;
                        text-align: center;
                    }

                    .arrow {
                        width: 6.94px;
                    }
                }
            }

            .zf-btn {
                width: 300px;
                height: 50px;
                background: linear-gradient(180deg, #000000 0%, #232321 100%);
                border-radius: 131px;
                text-align: center;
                line-height: 50px;

                font-size: 14px;
                color: #FFFFFF;
                margin-top: 25px;
            }

        }
    }


</style>
