module.exports = {
    language: {
        name: 'English'
    },
    user: {
        login: 'Login',
        register: 'Register',
        loginUsername: 'Please input email or phone',
        ploadSucceeded: 'Upload succeeded',
        forgetPsdSendEmail: 'Email sent successfully',
        registSucceeded: 'Registration succeeded! The audit results will be fed back to your contact email within 24 hours. Please check it',
        notesToBuy: "Click to view all contents",
    },
    header: {
        home: 'Home',//首页
        memberClassify: 'Membership Classification',//会员分类
        member: 'Member',//会员
        company: 'Company',//公司
        project: 'Project',//项目
        personalCenter: 'Personal Center',//个人中心
        wallet: 'Wallet',//我的钱包
        affiche: 'Attachment',//活动公告
        message: 'Message',//消息中心
        editData: 'Edit Data',//编辑资料
        companyMaintains: 'Company Information',//公司信息
        accountManagement: 'Account Management',//账号管理
        orderManagement: 'Order Management',//订单管理
        myProject: 'My Project',//我的项目
        myWallet: 'My Wallet',//我的钱包
        logOut: 'LogOut',//退出登录
    },
    project: {
        title: 'Project',
        whole: 'Whole',
        talent: 'Find Talents',
        financing: 'Find Money',
        smart: 'Find Wisdom',
        keyword: 'Keyword',
        publish: 'Publish',
        state: 'State',
        progress: 'Project In Progress',
        end: 'End Of Project',
        sort: 'Sort',
        composite: 'Comprehensive Ranking',
        views: 'Number Of Views',
        total: 'Items In Total',
        go: 'Go',
        details: 'Project Details',
        view: 'View',
        interested: 'I Am Interested In Free Communication',
        eliminate: 'Eliminate free communication',
        recommend: 'Recommend',
        buyNow: 'Buy Now',
        follow: 'Follow',
        followed: 'Followed',
        pTime: 'Time',
        pReason: 'Reason',
        videoCall: 'Video Call',
        leaveCurrentChannel: 'Leave Current Channel',
        endCurrentInterview: 'End the Current Interview',
        remoteUser: 'Remote User',
        joinedRoom: 'Successfully joined the room',
    },
    publish: {
        title: 'Publish Project',
        position: 'Title Of Position',
        treatment: 'Treatment',
        dollar: 'Dollar',
        yuan: 'Yuan',
        brief: 'Project Brief',
        input: 'Please Input',
        inputStr: 'Please Input The Content',
        releaseMode: 'Release Mode',
        publicRelease: 'Public Release',
        vipSketch: 'Free Premium Private Recommendation In The First Month',
        hide: 'Hide Publisher',
        private: 'Premium Private Recommendation',
        whatSketch: 'What Is Premium Private Recommendation',
        content: 'Content',
        whole: 'The Minimum Width Of An Image Designed To Span The Width Of The Story Section Should Be 695 Pixels. Images Wider Than 695 Pixels Will Be Resized Proportionally.',
        text: 'Please Enter The Text',
        phone: 'Phone Number',
        mailbox: 'Mailbox',
        bottomStr: 'Get Free Accurate Network Recommendation',
        button: 'SAVE',
        tips: 'Tips',
        unPublished: 'The Project Was UnPublished',
        switch1: 'Switch To Looking For People ?',
        switch2: 'Switch To Looking For Money ?',
        switch3: 'Switch To Looking For Wisdom ?',
        cancel: 'Cancel',
        determine: 'Confirm',
        projectName: 'Project Name',
        financingStage: 'Financing Stage',
        financingAmount: 'Financing Amount',
        transferOfShares: 'Transfer Of Shares',
        otherDemands: 'Other Financing Demands',
        budget: 'Budget',
        fiveTime: 'You can make an appointment for 5 time periods. It is recommended to choose different dates. Selectable date range: 7-37 days after the system time',
        kefuliuyan: 'Message about making an appointment to customer service',
        selectDateTime: 'Select date and time',
        selectDateTime1: 'Select date and time one',
        selectDateTime2: 'Select date and time two',
        selectDateTime3: 'Select date and time three',
        selectDateTime4: 'Select date and time four',
        selectDateTime5: 'Select date and time five',
        releaseInterview: 'Release interview',
        releasedOneDayAgo: 'Released 1 day ago',
        delPayPal: 'This operation will delete the PayPal account. Do you want to continue',
    },
    home: {
        home: 'Home',
        findTalents: 'Find Talents',
        findMoney: 'Find Money',
        findWisdom: 'Find Wisdom',
        categories: 'Categories',
        selectUp: 'Select Up To 1 Item',
        leaveUs: 'Leave Us A Message',
        phone: 'Phone',
        messageContent: 'Message Content',
        more: 'More',
        submit: 'Submit',
    },
    //活动公告
    activityNotice: {
        activityNotice: 'Event announcement',//活动公告
        all: 'ALL',//全部
        enrolling: 'Registering',//报名中
        enrolled: 'Signed Up',//已报名
        enrollDead: 'Registration Deadline',//报名截止
        enrollEnd: 'Registration End',//报名结束
        activityDead: 'End Of Activity',//活动结束
        keyword: 'keyword',//关键字
        publishedProjects: 'Published Projects',//已发布项目
        iWantToSignUp: 'Sign Up',//我要报名
        payImmediately: 'Pay Immediately',//立即缴费
        payed: 'payed',//已缴费
        eventDetails: 'Event Details',//活动详情
    },
    //消息中心
    message: {
        message: 'Message',//消息中心
        project: 'project',//项目
        communicate: '沟通',//沟通
        customerServiceReply: 'Customer service reply',//客服回复
        tongyi: 'Agree!',
        noNews: 'No News',
        member: 'Member',
        designation: 'Designation',
        suozaididian: 'Location',
        pleaseSelect: 'Please Select',
        noData: 'No Data',
        memberName: 'Member Name',
        companyName: 'Company Name',
        immediateAttention: 'Follow',
        professionalSearchBar: 'Professional search',
        theGlobalMatching: 'The global matching',
        searchIndustry: 'Search industry',
        noInformation: 'No information about this member was obtained',
    },
    //VIP私密推荐项目
    vipRecommend: {
        detailsOfMyProjects: 'Details of my projects',//我参与的项目详情
        vipPrivateRecommendation: 'VIP Private Recommendation',//VIP私密推荐
        projectName: 'Project Name',//项目名称
        buyNow: 'Buy now',//立即购买
        unfollow: 'unfollow',//取消关注
        reasonsForRecommendation: 'Reasons for recommendation',//推荐原因
        reasonsForRefuse: 'Reasons for refuse',//拒绝原因
        postedComment: 'Posted Comment',
        projectDetail: 'Project Detail',//项目详情
        appointmentTime: 'Appointment Time',//预约时间
        messageAboutBookingToCustomerService: 'Message about booking to customer service',//关于预约给客服的留言
        publisherCancelsReason: 'Cancellation reason',//取消原因'The publisher cancels the reason'
        mobileNumber: 'mobile number',//手机号
        email: 'email',//邮箱
        recommendReason: 'Recommended reasons', //推荐原因
    },
    // 我的项目
    myProject: {
        all: 'ALL',
        // 我的项目
        myProject: 'My Project',
        // 我发布的项目
        myPublishedProject: 'My Published Projects',
        // 我参与的项目
        myJoinProject: 'My Projects',
        // 我的访谈
        myInterview: 'My Interview',
        // 我的人脉
        myContacts: 'My Contacts',
        // 我感兴趣愿意免费沟通项目
        iAmInterestedInFreeCommunicationProjects: 'I am interested in free communication projects',
        // 我推荐给其他会员的项目
        itemsIRecommendToOtherMembers: 'Items I recommend to other members',
        // 其他会员推荐给我项目
        otherMembersRecommendProjectsToMe: 'Other members recommend projects to me',
        // 我浏览过的项目
        projectsIBrowsed: 'Projects I\'ve browsed',
        // 被推荐者
        recommendedPerson: 'recommended person',
        // 我发起的 3 分钟访谈
        init3Minute: 'I initiated a three minute interview',
        //我收到的 3 分钟访谈
        receive3Minute: 'Received 3’ Interview',
        // 会员推荐给我的人脉
        vipRecommendContact: 'Contacts recommended by members',
        // 平台推荐给我的人脉
        platformRecommendContact: 'Network recommended by the platform',
        // 我推荐给会员的人脉
        meToVipContact: 'Contacts I recommend to members',
        // 我关注的人脉
        iFollowContact: 'My contacts',
        // number 人可免费沟通
        personFreeCommunication: ' Person Free Communication',
        // number 人同意
        personAgreed: 'Person Agreed',
        // number 人查看
        toView: ' Person To View',
        // number 人推荐过此项目
        recommendProject: ' Person Recommended This Project',
        // 多人沟通时间
        multiUserCommunicationTime: 'Multi Person Communication Time',
        // VIP 到期时间
        vipExpireTime: 'Premium Expiration Time',
        // VIP 订单
        vipOrder: 'Premium Order',
        // 订单编号
        orderSerialNumber: 'Order Number',
        // 付款方银行 ID
        payerBankId: 'Payer Bank Id',
        // VIP 交费时间
        vipPaymentTime: 'Premium Payment Time',
        // 交费金额
        paymentAmount: 'Payment Amount($)',
        // 支付状态
        paymentStatus: 'Payment Status',
        // 评估结果
        assessmentResults: 'Assessment Results',
        // VIP 开始日期
        vipStartDate: 'Premium Start Date',
        // VIP 到期日
        vipExpirationDate: 'Premium Expiration Date',
        // 售后
        afterSales: 'After Sales',
        // 已同意
        agreed: 'Agreed',
        // 已拒绝
        rejected: 'Rejected',
        // 评估中
        underEvaluation: 'Under Evaluation',
        // 感兴趣
        beInterested: 'Be Interested',
        // 会员推荐
        memberRecommendation: 'Member Recommendation',
        // 浏览过
        have: 'Browsed',
        // 全选
        selectAll: 'All',
        //免费沟通
        freeCommunication: 'Free Communication',
        // 已拒绝
        // 对方同意
        otherSideAgreed: 'The Other Side Agreed',
        // 对方拒绝
        otherSideRefused: 'The Other Side Refused',
        // 取消预约
        cancelReservation: 'Cancel Reservation',
        // 对方已取消
        otherPartyHasCancelled: 'The Other Party Has Cancelled',
        // 访谈
        interview: 'Interview',
        // 访谈完成
        interviewCompleted: 'Interview Completed',
        // 立即评价
        immediateEvaluation: 'Immediate Evaluation',
        // 多人邀约记录
        multiPersonInvitationRecord: 'Multi Person Invitation Record',
        // 多人沟通时间
        multiPersonCommunicationTime: 'Multi Person Communication Time',
        // 邀约人进度
        progressOfInvitees: 'Progress Of Invitees',
        // 已同意
        // 待反馈
        waitingForFeedback: 'Waiting For Feedback',
        // 发起邀约日期
        dateOfInvitation: 'Date Of Invitation',
        // 推荐原因
        recommendedTemplate: 'Recommended reasons',
        // 预约时间
        timeOfAppointment: 'Time Of Appointment',
        // 关于预约给客服的留言
        messageBookingToCustomer: 'Message About Booking To Customer Service',
        // 发起访谈
        initiateAnInterview: 'Initiate An Interview',
        // 多人免费沟通，您可以预约 1 个时间段。可选择日期范围：系统时间往后 7-37 天之间
        multiPersonFreeCommunicationContent: 'Multi Person Free Communication, You Can Make An Appointment For A Period Of Time. Optional Date Range: 7-37 Days After The System Time',
        // 发布方式
        releaseMode: 'Release Mode',
        // 公开发布
        publicRelease: 'Public Release',
        // 前往
        goTo: 'Go To',
        // 项目基本信息
        basicProjectInfo: 'Project Details',
        // 推荐原因
        reasonsForRecommendation: 'Reasons For Recommendation',
        // 访谈时间
        interviewTime: 'Interview Time',
        // 操作
        operation: 'Operation',
        // 进度
        speedOfProgress: 'Process',
        // 发起时间
        startTime: 'Start Time',
        recommendedDetails: 'Recommended for details',
        // 立即购买
        buyNow: 'Buy Now',
        // 立即关注
        immediateAttention: 'Follow',
        // 愿意免费沟通
        willingToCommunicateForFree: 'Willing To Communicate For Free',
        //多人免费沟通
        multiPersonFreeCommunication: 'Multi person free communication',
        // 拒绝
        refuse: 'Refuse',
        // 我感兴趣的项目
        interestedProject: 'I\'m Interested In The Project',
        // VIP 私密推荐
        vipPrivateRecommendation: 'Premium Private Recommendation',
        // 我推荐的项目
        recommendItem: 'Items I recommend',
        // 推荐给我的项目
        recommendedProjectToMe: 'The Project Recommended To Me',
        // 多人沟通
        multiPersonCommunication: 'Multi Person Communication',
        // 被邀约人
        invitee: 'Invitees',
        // VIP 私密推荐人脉
        vipPrivateRecommendedContact: 'Contacts',
        // 价格
        price: 'Price',
        // VIP 私密推荐我的 3 分钟访谈
        vipPrivateRecommendation3Minute: 'VIP 3’ Interview\n',
        // 推荐类型
        recommendedType: 'Recommended Type',
        // 会员推荐给我的人脉
        contactsRecommendedByMember: 'Contacts Recommended By Members',
        // 平台推荐给我的人脉
        networkRecommendedByPlatform: 'Network Recommended By The Platform',
        // 我推荐给会员的人脉
        contactsRecommendToMember: 'Contacts I Recommend To Members',
        // 推荐时间
        recommendedTime: 'Recommended Time',
        // 关注时间
        focusOnTime: 'Focus On Time',
        // 推荐者
        recommender: 'Recommender',
        // 获取免费精准人脉推荐
        getFreeAccurateNetworkRecommendation: 'Get Free Accurate Network Recommendation',
        // 接收者
        receiver: 'The Receiver',
        // 被关注者
        follower: 'By Followers',
        // 取消关注
        unfollow: 'Unfollow',
        // 关注相关提示消息
        followMsg: {
            // 关注成功
            followSuccess: 'Follow on success',
            // 取消关注成功
            unfollowSuccess: 'Unfollow on success'
        },
        // 点击频繁提示消息
        clickFrequentMsg: 'Too many clicks!',
        overLimit: 'The quantity exceeds the maximum limit!',
        // 发布
        publish: 'Publish',
        // 查看详情
        detail: 'View Details',
        delete: 'Delete',
        edit: 'Edit',
        // VIP 购买
        vipBuy: 'Premium Buy',
        // 无订单
        noOrder: 'There Is No Order',
        // 参与人
        participant: 'Participant',
        // 请输入
        pleaseInput: 'Please Input',
        // 本次访谈评价
        evaluationInterview: 'Evaluation Of This Interview',
        // 提交
        submit: 'Submit',
        editor: 'Editor',
        // RecommendTable 中的消息提示
        recommendTableMsg: {
            // 拒绝成功
            refuseSucess: 'Refuse to success',
            // 取消预约成功
            cancelSucess: 'Cancellation successful',
            // 发表评论成功
            postedCommentSuccess: 'Comment successfully posted'
        },
        // 邀约人数
        invitationNum: 'The number of invitations',
        // 已同意人数
        agreedNum: 'Number of agreed',
        confirmDel: 'This operation will permanently delete this item. Do you want to continue?',
    },
    //我发布的项目
    myPublishedProjects: {
        projectDetails: 'Project details',//项目详情
        multiperson: "multi person interview",// 发起多人访谈
        editProject: "Edit Project",
        declinedInvitation: "You have declined the invitation",
        reasonInvitation: "Reason for rejecting the invitation",
        communTopic: "Communication topic",
        cancelledAppointment: 'You have cancelled your appointment',
    },
    ///我感兴趣愿意免费沟通的项目
    interestedProject: {
        publisherPush5Times: 'Publisher push 5 times',//发布者推送5个时间
    },
    ///我推荐给其他会员的项目
    participateProjectProgress: {
        participateProjectProgress: 'Participate in project progress',//参与项目进度

    },
    //我的访谈
    myInterview: {
        myInterviewDetail: 'my interview detail',//我的访谈详情
    },
    memberClassification: {
        globalCapital: 'Global Capital',//全球资本
        globalUniversities: 'Global Universities',//全球院校
        globalHeadhunters: 'Global Headhunters',//全球猎头
        companiesNumber: 'Number Of Companies',//公司数量
        peopleNumber: 'Number Of People',//人数
        company: 'Company',//公司
        enterCompanyName: 'Please enter company name',//请输入公司名称
        location: 'Location',//所在地点
        select: 'Select',//请选择
        corporateName: 'Corporate Name',//公司名称
        goTo: 'Go To',//前往
        globalAi: 'Global Transportation&AI',//全球交通AI领域
        individualAngel: 'Individual  Angel',//个人/天使
    },
    personalCenter: {
        personCenter: 'Personal Center',//个人中心
        cancelAttention: 'Following',//取消关注
        buyNow: 'Buy Now',//立即购买
        videoCommunication: 'Video Communication',//视频沟通
        audioCommunication: 'Audio Communication',//音频沟通
        planExpiry: 'Plan Expiry',//购买须知
        openTo: 'Open to',//购买须知
        previewAttachment: 'Preview attachment',//购买须知
        evaluate: 'Evaluate',//评价
        complaint: 'Complaint',//投诉
        mobileNumber: 'Mobile Number',//手机号
        companyWebsite: 'Company Website',//公司官网
        contactTime: 'Contact Time',//联系时间段
        changeBackground: 'Change Background',//更换背景
        recommend: 'recommend',
        acceptInvitation: 'Accept the Invitation',//同意邀约
        buy3Minites: 'Buy His/ Her 3 Minites Communication',//立刻购买他的3分钟沟通
        gender: 'Gender',//性别
        male: 'Male', //男
        female: 'Female', //女
        unknown: 'Unknown' //未知
    },
    // 推荐会员
    recommendMember: {
        recommendMember: 'Recommend Member',
        disclaimerAndMembership: 'Disclaimer And Membership',
        disclaimer: 'Disclaimer Is A Statement That The Parties Concerned Are Not Liable For Breach Of Contract For Their Failure To Perform The Contract. Force Majeure, The Natural Nature Of The Goods, The Reasonable Loss Of The Goods And The Fault Of The Creditor Are The Conditions For Exemption. Disclaimer Is A Statement That The Parties Concerned Are Not Liable For Breach Of Contract For Their Failure To Perform The Contract. Force Majeure, The Natural Nature Of The Goods, The Reasonable Loss Of The Goods And The Fault Of The Creditor Are The Conditions For Exemption. Disclaimer Is A Statement That The Parties Concerned Are Not Liable For Breach Of Contract For Their Failure To Perform The Contract. Force Majeure, The Natural Nature Of The Goods, The Reasonable Loss Of The Goods And The Fault Of The Creditor Are The Conditions For Exemption.',
        nonMembersRecommend: 'You Can Upload The Business Card, Resume And Linkedin Website Of Non Members To Recommend Them To Become Members Of BlessBoss Website',
        linkedinWebsite: 'Linkedin Website',
        uploadBusinessCardResume: 'Upload Business Card / Resume',
        supportJpgPngPdfNotMoreThan3MB: 'Support Jpg, Png, Pdf Not More Than 3MB',
        submit: 'Submit',
        clickUpload: 'Click upload',//点击上传
        sender: 'Sender',//发送人
    },
    // 账号管理
    accountManagement: {
        accountManagement: 'Account Management',
        accountNumber: 'Account Number',
        originalPassword: 'Original Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
        pleaseEnterTheOriginalPassword: 'Please Enter The Original Password',
        pleaseEnterANewPassword: 'Please Enter A New Password',
        pleaseEnterTheNewPasswordAgain: 'Please Enter The New Password Again',
        changePassword: 'Change Password',
        logOut: 'Log Out',
        registerNow: 'Register Now',
        thirdPartySocialAccountLogin: 'Third Party Social Account Login',
        editingMaterials: 'Editing Materials',
        selectFile: 'Select File',
        noFilesSelected: 'No Files Selected',
        fullName: 'Full Name',
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        officialWebsiteAddress: 'Official Website Address',
        professionalTitle: 'Professional title',
        voiceIntroduction: 'Voice introduction',
        uploadEnclosure: 'Upload enclosure',
        title: 'Title',
        priceOf3MinutesCommunication: 'Price of 3 Minutes Communication',
        lowestPrice: 'Lowest price',
        wikipediaLink: 'Wikipedia Link',
        pleasePasteTheLink: 'Please Paste The Link',
        link: 'Link',
        addMore: 'Add More',
        privacyRequirements: 'Privacy Requirements',
        welcomeToContact: 'Welcome To Contact',
        refuseAllContact: 'Refuse All Contact',
        pleaseContactMeInTheFollowingTimePeriod: 'Please Contact Me In The Following Time Period',
        addTo: 'Add To',
        see: 'See',
        companyMaintenance: 'Company Maintenance',
        project: 'Project',
        mobile: 'Mobile',
        email: 'Email',
        companyName: 'Company Name'
    },

    // VIP
    vip: {
        vip: 'VIP',
        premiumFinancingServiceCharge: 'Premium Financing Service Charge',
        serviceContent: 'Service Content ',
        premiumChargesForFindingPeopleMoneyAndWisdom: 'Premium Charges For Finding People, Money And Wisdom',
        initiateA3MinuteInterview: 'Initiate A 3-Minute Interview',
        anAppointmentRecommended: 'You Can Make An Appointment For 5 Time Periods. It Is Recommended To Select The Date. Optional Date Range: 7-37 Days After The System Time',
        myProject: 'My Project',
        buyNow: 'Buy Now'
    },
    //我的钱包
    myWallet: {
        all: 'ALL',
        wallet: 'Wallet',
        paypalAccount: 'Paypal Account',
        modify: 'Modify',
        delete: 'Delete',
        toBePaid: 'To Be Paid',
        paid: 'Paid',
        Refunded: 'Refunded ',
        Date: 'Date',
        selectADate: 'Select A Date',
        query: 'Query',
        orderId: 'Order Id',
        transactionDate: 'Transaction Date',
        paymentStatus: 'Payment Status',
        amountUSD: 'Amount (USD)',
        orderContent: 'Order Content',
        recordsInTotal: 'Records In Total',
        noDataAvailable: 'No Data Available',
        payImmediately: 'Pay Immediately',
        applyForARefund: 'Apply For A Refund',
        refundInProgress: 'Refund In Progress',
        refundFailed: 'Refund Failed',
        refundRequested: 'Refund Requested',
        refundApplicationSuccessful: 'Refund Application Successful',
        paymentFailed: 'Payment Failed',
        itWillJumpToMyWalletInSeconds: 'It Will Jump To My Wallet In 5 Seconds',
        backToWallet: 'Back To Wallet',
        youHavePaidSuccessfully: 'You Have Paid Successfully',
        newlyAdded: 'Newly Added',
        premium: 'Premium',
        modifyPayPal: 'Modify PayPal Account',//修改PayPal账户
        addPayPal: 'Add PayPal Account',//修改PayPal账户
        payment: 'Payment'

    },
    //弹框
    bulletFrame: {
        cancelReason: 'Please Enter The Reason For Rejection',
        updatePersonBack: 'Modify Personal Home Page Background',
        uploadPictures: 'Upload Pictures',
        personMainPage: 'Big Picture Of Personal Main Page',
        saveSettings: 'Save Settings',
        operationSuccessful: 'Operation Successful',
        confirm: 'Confirm',
        confirmSubmission: 'Confirm Submission',
        recommendThisMember: 'You Can Recommend This Member To Other Members',
        recommendNow: 'Recommend Now',
        complaint: 'Complaint',
        theReasonForTheComplaint: 'Please Enter The Reason For The Complaint',
        publishComments: 'Publish Comments',
        paymentStatus: 'Payment Status',
        refundReason: 'Please Enter The Refund Reason',
        purchaseAndRefundInstructions: 'Purchase And Refund Instructions',
        recommendNonMembers: 'Recommend Non Members',
        blessBoss: 'Upload The Name Card, Resume And Linkedin Address Of Non Members And Recommend Them To Become Members Of BlessBoss Website',
        agreeToMakeAnAppointment: 'Agree To Make An Appointment',
        reasonForCancellation: 'Reason For Cancellation',
        reasonsRejection: 'Reasons For Rejection',
        recommendOther: 'You can recommend other members to this member',//您可将其他会员推荐给此会员
        appointmentTwoHours: 'You only can cancel the appointment 2 hours before the interview',//若要取消预约，请在访谈2小时前操作
    },
    //我的访谈详情
    myInterviewDetail: {
        vipBuy: 'Premium Buy',
        publicRelease: 'Public Release',
        alreadyPublicRelease: 'Already Public Release',
        private: 'Premium Private Recommendation',
        interviewDetails: 'Interview Details',
        selectAll: 'All',
        referenceInformation: 'Reference information',
        reasonsForRecommendation: 'Reasons for recommendation',
        interviewTime: 'Interview Time',
        operation: 'Operation',
        speedOfProgress: 'Speed Of Progress',
        startTime: 'Start Time',
        recommendMail: 'Recommend mail',
        willingToCommunicateForFree: 'Willing To Communicate For Free',
        refuse: 'Refuse',
        cancelReservation: 'Cancel Reservation',
        interview: 'Interview',
        anImmediateRefund: 'An immediate refund',
        refundHasBeenAppliedFor: 'Refund has been applied for',
        successfullyApplyForRefund: 'Successfully apply for refund',
        failedToApplyForRefund: 'Failed to apply for refund',
        immediateEvaluation: 'Immediate Evaluation',
        haveEvaluation: 'Have evaluation',
        go: 'Go To',
        invitee: 'invitee',
        orderSerialNumber: 'Order Number',
        orderTime: 'Order time',
        money: 'Money',
        paymentStatus: 'Payment Status',
        multiPersonCommunication: 'Multi Person Communication',
        manyPeopleOffer: 'Many people offer',
        invitationBecause: 'Invitation because',
        phone: 'Phone',
        email: 'Email',
        invitationTime: 'Invitation time',
        optionDate: 'option date',
        selectTime: 'select time',
        cancel: 'Cancel',
        determine: 'Confirm',
        multiPersonInvitationRecord: 'Multi Person Invitation Record',
        multiUserCommunicationTime: 'Multi Person Communication Time',
        progressOfInvitees: 'Progress Of Invitees',
        agreed: 'Agreed',
        waitingForFeedback: 'Waiting For Feedback',
        dateOfInvitation: 'Date Of Invitation',
        invitationNum: 'The number of invitations',
        agreedNum: 'Number of agreed',
        attention: 'Follow',
        YouHaveBeenWillingToCommunicateForFree: 'You have been willing to communicate for free',
        ThePublisherHasBeenCancelled: 'The publisher has been cancelled',
        YouHaveToCancel: 'You have to cancel',
        declinedInvitation: 'Declined The Invitation',
        selectTime: 'Please Select A Time Period To Accept Your Booking Application',
        acceptInvitation: 'Accept The Invitation',
        haveInvitation: 'You Have Accepted The Invitation',
        publisher: 'Publisher',
        aPersonInterview: 'Initiate a single person interview',
    },
    companyDetail: {
        title: 'Company details',
    },
    stripePay: {
        quarterly: 'Quarterly',
        payWithBankCard: 'Or pay with a bank card',
        mail: 'Mail',
        bankCard: 'Bank Card',
        cardNumber: 'Card number',
        monthYear: 'Month / year',
        cardSecurityCode: 'Card security code(CVC)',
        countryOrAddress: 'Country or address',
        zipCode: 'Zip code',
    }
}
