<template>
    <div class="announcement-item-container" v-if="avtivityList != undefined">
        <div class="content fl">
            <img :src="avtivityList.event_img_url" alt="" class="item-image">
            <div @click="$router.push('detail?id='+avtivityList.id)" class="basic-info  fl">
                <h1 class="over" style="height: 50px">{{ avtivityList['event_title'] }}</h1>
                <h2 class="over4" style="height: 50px">{{ avtivityList.event_intro }}</h2>
                <div class="lb">
                    <div class="address">
                        <img alt="" src="@/assets/images/describe_icon1@2x.png">
                        <div class="text-limit over" style="width: 470px;height: 20px">
                            <span>{{ avtivityList.event_site }}</span>
                        </div>
                    </div>
                    <div class="datetime">
                        <img alt="" src="@/assets/images/icon_time@2x.png">
                        <span>{{ avtivityList.event_time }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-group-container fr">
            <div class="btn-group">
                <div v-if="avtivityList.event_status==1  && avtivityList.is_open_apply ==1">
                    <div @click="registration(avtivityList.id)" class="btn_group_item"
                         v-if="avtivityList.is_apply == 1">
                        {{$t("activityNotice.iWantToSignUp")}}
                    </div>
                    <div class="btn_group_item"
                         v-if="avtivityList.is_pay == 1 && avtivityList.is_apply == 2 && avtivityList.is_open_pay == 1">
                        <!--@click="payForActivities(avtivityList.id)">-->
                        <div class="btn-pay-group">
                            <div @click="payForActivities(avtivityList.id)" class="save_btn cur">
                                PayPal {{ $t("myWallet.payment") }}
                            </div>
                            <div @click="$refs.skPayDialog.open()" class="save_btn cur"><!--@click="publishInterview"-->
                                Stripe {{ $t("myWallet.payment") }}
                            </div>
                        </div>
                    </div>
                    <div class="btn_group_item btn-type2" v-if="avtivityList.is_apply == 2">
                        {{$t('activityNotice.enrolled')}}
                    </div>
                </div>
                <div v-else-if="avtivityList.event_status==2">
                    <div class="btn_group_item btn-type2">{{$t('activityNotice.enrollDead')}}</div>
                </div>
                <div v-else-if="avtivityList.event_status==3">
                    <div class="btn_group_item btn-type2">{{$t('activityNotice.activityDead')}}</div>
                </div>
            </div>
        </div>

        <!--Stripe支付弹窗-->
        <SkPayDialog @bankPay="post_submitStripe" ref="skPayDialog"></SkPayDialog>
    </div>

</template>

<script>
    import EnrollButton from "@/components/activityNotice/EnrollButton";
    import {payForActivities, registrationActivities} from '@/api/activityApi'
    import {Loading} from "element-ui";
    import {createOrder, chargeActivities} from "@/api/payment";
    import SkPayDialog from "@/components/dialog/SkPayDialog";

    let loadingInstance = null;
    export default {
        name: "AnnouncementItem",
        props: ['avtivityList'],
        components: {
            EnrollButton,
            SkPayDialog
        },
        data() {
            return {};
        },
        methods: {
            //  活动报名
            registration(id) {
                let formData = {
                    eventId: id,
                    token: localStorage.getItem('token')
                }
                registrationActivities(formData).then((res) => {
                    this.$message.success(res.msg);
                    this.avtivityList.is_apply = 2;
                })
            },
            //会员活动缴费Stripe
            post_submitStripe(stripPayForm) {
                let expMonthAndYears = stripPayForm.expMonthAndYear.split("/")
                let formData = {
                    eventId: this.avtivityList.id,
                    slmToken: localStorage.getItem('token'),
                    //amount: stripPayForm.amount,
                    email: stripPayForm.email,
                    number: stripPayForm.number,
                    expMonth: expMonthAndYears[0],
                    expYear: expMonthAndYears[1],
                    cvc: stripPayForm.cvc,
                    name: stripPayForm.name,
                    country: stripPayForm.country,
                    postcode: stripPayForm.postcode,
                }
                loadingInstance = Loading.service({
                    text: 'Request PayPal...',
                    // spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                console.log("会员活动缴费StripeFormData")
                console.log(formData)
                chargeActivities(formData).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        loadingInstance.close();
                        this.$refs.skPayDialog.close();
                        this.$parent.getDataList();
                    } else if (res.code == 204) {
                        loadingInstance.close();
                        this.$refs.skPayDialog.close();
                        this.$message.error(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                });
            },
            //会员活动缴费PayPal
            payForActivities(id) {
                let formData = {
                    eventId: id,
                    token: localStorage.getItem('token'),
                }
                payForActivities(formData).then((res) => {
                    if (res.code == 200) {
                        this.getPayUrl(res.data);
                    } else if (res.code == 204) {
                        this.$message.error(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                });
            },
            getPayUrl(item) {
                let formData = {
                    orderContent: item.orderContent,
                    orderNumber: item.orderNumber,
                    paymentAmount: item.paymentAmount,
                }
                loadingInstance = Loading.service({
                    text: 'Request PayPal...',
                    // spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                createOrder(formData).then((res) => {
                    if (res.code == 200) {
                        window.location.href = res.data.approval_url;
                    } else {
                        loadingInstance.close();
                        this.$message.error(res.msg);
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .btn-pay-group {
        justify-content: start;

        .save_btn {
            margin-right: 20px;
        }
    }

    .announcement-item-container {
        width: 100%;
        height: 201px;
        margin-bottom: 67px;
        overflow: hidden;
    }

    .announcement-item-container:last-child {
        margin-bottom: 0;
    }

    .item-image {
        width: 304px;
        height: 201px;
        border-radius: 4px;
        float: left;
        margin-right: 36px;
    }

    .basic-info {
        height: 201px;
        width: 545px;
        position: relative;
    }

    .basic-info > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .basic-info > h2 {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-top: 14px;
    }

    .lb {
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .address {

    }

    .address > img {
        vertical-align: bottom;

        display: inline-block;
        height: 17px;
        margin-right: 12px;
    }

    .address > .text-limit {
        display: inline-block;
        vertical-align: bottom;

    }

    .address > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .datetime {

        margin-top: 11px;
    }

    .datetime > img {
        vertical-align: bottom;
        display: inline-block;
        height: 16px;
        margin-right: 12px;
        margin-left: 1px;
    }

    .datetime > span {
        vertical-align: bottom;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 12px;
    }

    .btn-group-container {
        height: 100%;
        display: table;
    }

    .btn-group {
        display: table-cell;
        vertical-align: middle;
    }

    .bg-item {
        margin-bottom: 23px;
    }

    .bg-item:last-child {
        margin-bottom: 0;
    }

    .btn_group_item {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 50px;
        margin-bottom: 50px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        cursor: pointer;
    }

    .btn-type1 {
        border-color: #FAFAFA;
        color: #949494;
        cursor: default;
    }

    .btn-type2 {
        border-color: #F1F1F1;
        color: #949494;
        cursor: default;

    }

</style>
