import Vue from 'vue'


import App from './App.vue'
import {store} from './vuex/store'
import router from './router'
import Router from 'vue-router'
import QS from 'qs';
import G from './global'
import preventReClick from './utils/plugins.js'
import waterfall from 'vue-waterfall2'

Vue.use(waterfall)
Vue.use(G);
// 引入美洽
import meiqia from '@/utils/meiqia'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import en from 'element-ui/lib/locale/lang/en'
import zh from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'
//中英文切换
import VueI18n from 'vue-i18n'


// import Swiper from 'swiper';
import 'swiper/css/swiper.min.css'

import moment from 'moment'

Vue.use(require('vue-moment'));
Vue.prototype.moment = moment

Vue.prototype.$elLangEn = () => {
    locale.use(en)
}
Vue.prototype.$elLangZh = () => {
    locale.use(zh)
}

Vue.config.productionTip = false
Vue.prototype.$qs = QS

Vue.use(VueI18n)
Vue.use(ElementUI);
// Vue.use(Swiper);

import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor, /* { default global options } */)

const i18n = new VueI18n({
    locale: localStorage.getItem('languageSet') || 'en',   //从localStorage里获取用户中英文选择，没有则默认en
    messages: {
        'zh': require('./components/language/zh'),
        'en': require('./components/language/en')
    }
})

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}
Vue.filter('dateYMDHMSFormat', function (dateStr, pattern = 'DD/MM/YYYY HH:mm:ss') {
    return moment(dateStr).format(pattern);
})
Vue.filter('dateYMDFormat', function (dateStr, pattern = 'DD/MM/YYYY HH:mm:ss') {
    return moment(dateStr).format(pattern);
})
Vue.filter('dateYMDHMSFormat2', function (dateStr, pattern = 'DD/MM/YYYY') {
    return moment(dateStr).format(pattern);
})

new Vue({
    router,
    i18n,
    store,
    render: h => h(App),
    //这里与vue.config.js中的事件名相对应

}).$mount('#app')
