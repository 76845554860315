import request from '../utils/request'

const baseUrl = "/api"
export const uploadAvatarOrBackdrop = baseUrl + "/common/uploadingAvatarOrBackdrop";
export const uploadingResume = baseUrl + "/common/uploadingResume";
export const uploadingProjectImg = baseUrl + "/common/uploadingProjectImg";
export const uploadingProjectVideo = baseUrl + "/common/uploadingProjectVideo";
export const uploadingForRichText = baseUrl + "/common/uploadingForRichText";//富文本上传图片(.png 或 .jpg 格式)
export const uploadingMp3 = baseUrl + "/common/uploadingProjectVoice";//mp3
export const uploadingEnclosure = baseUrl + "/common/uploadingFile";//附件

export function uploadImage(data) {
    return request({
        url: '/common/uploadingForRichText',
        method: 'post',
        data
    })
}
