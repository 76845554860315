<!-- 此文件无任何作用 -->
<template>
    <div class="class_box">
        <div class="class_first">
            <div class="fl class_title">
                一级分类：
            </div>
            <ul class="fl">
                <li class="fl cur class_active">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
            </ul>
            <div class="more_btn fr cur">
                更多
                <img alt="" src="../../assets/images/more_gray@2x.png" width="11px">
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="class_first">
            <div class="fl class_title">
                一级分类：
            </div>
            <ul class="fl">
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
            </ul>
            <div class="clearfix"></div>
        </div>
        <div class="class_first">
            <div class="fl class_title">
                一级分类：
            </div>
            <ul class="fl">
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
                <li class="fl cur">一级分类一</li>
            </ul>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "classify",

    }
</script>

<style scoped>
    .class_first {
        position: relative;
        line-height: 75px;
        border-bottom: 2px solid #E5E5E5;
    }

    .class_title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        width: 100px;
    }

    .class_first ul {
        width: 1100px;
    }

    .class_first li {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 45px;
        transition: .3s all;
    }

    .class_first li:hover {
        font-weight: bold !important;
    }

    .class_active {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold !important;
        color: #000000;
    }

    .more_btn {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }
</style>
