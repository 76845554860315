/*项目相关*/
import request from '../utils/request'

//获取项目列表
export function mainProjectList(data) {
    return request({
        url: '/project/mainProjectList',
        method: 'get',
        params: data
    })
}

//查看项目详情
export function projectDetails(data) {
    return request({
        url: '/projectMinute/projectDetailPage',
        method: 'post',
        params: data
    })
}

// 参与项目 查看项目详情
export function myJoinprojectDetails(data) {
    return request({
        url: '/myJoinProject/projectDetails',
        method: 'get',
        params: data
    })
}

// 参与项目 多人 查看项目详情
export function myJoinmultiProDetails(data) {
    return request({
        url: '/myJoinProject/multiProDetails',
        method: 'get',
        params: data
    })
}

//我感兴趣愿意免费沟通
export function followProject(data) {
    return request({
        url: '/project/followProject',
        method: 'post',
        data
    })
}

//会员给项目推荐其他会员
export function recommendOtherMember(data) {
    return request({
        url: '/project/recommendOtherMember',
        method: 'post',
        data
    })
}

//会员给项目推荐非会员
export function projectRecommend(data) {
    return request({
        url: '/project/projectRecommend',
        method: 'post',
        data
    })
}

//发布项目——找人
export function insertProjectForSomeone(data) {
    return request({
        url: '/project/insertProjectForSomeone',
        method: 'post',
        data
    })
}

//发布项目——找钱（融资）
export function insertProjectForFinancing(data) {
    return request({
        url: '/project/insertProjectForFinancing',
        method: 'post',
        data
    })
}

//发布项目——找智慧
export function insertProjectForWisdom(data) {
    return request({
        url: '/project/insertProjectForWisdom',
        method: 'post',
        data
    })
}

// 会员浏览项目处理
export function browseProject(data) {
    return request({
        url: '/project/browseProject',
        method: 'get',
        params: data
    })
}


// 发起多人邀请提交
export function initiatePeopleInterview(data) {
    return request({
        url: '/myCenter/initiatePeopleInterview',
        method: 'POST',
        params: data
    })
}


// 发起单人邀请提交
export function initiatorFeedback(data) {
    return request({
        url: '/myCenter/initiatorFeedback',
        method: 'POST',
        params: data
    })
}

// 受邀人邀约反馈提交
export function inviteeFeedback(data) {
    return request({
        url: '/myJoinProject/inviteeFeedback',
        method: 'POST',
        params: data
    })
}


// 受邀人评论提交
export function inviteeEvaluate(data) {
    return request({
        url: '/myJoinProject/inviteeEvaluate',
        method: 'POST',
        params: data
    })
}

// 受邀人邀约取消
export function inviteeCancel(data) {
    return request({
        url: '/myJoinProject/inviteeCancel',
        method: 'POST',
        params: data
    })
}

// 发布人邀约取消
export function initiatorCancelReservation(data) {
    return request({
        url: '/myCenter/initiatorCancelReservation',
        method: 'POST',
        params: data
    })
}

// 被推荐会员 反馈提交
export function referralFeedback(data) {
    return request({
        url: '/myJoinProject/referralFeedback',
        method: 'POST',
        params: data
    })
}


// 被推荐会员 反馈提交
export function vip_initiatorFeedback(data) {
    return request({
        url: '/projectMinute/initiatorFeedback',
        method: 'POST',
        params: data
    })
}

// 多人沟通 反馈
export function multiInviteeFeedback(data) {
    return request({
        url: '/myJoinProject/multiInviteeFeedback',
        method: 'POST',
        params: data
    })
}

// 发起人 取消
export function post_initiatorCancel(data) {
    return request({
        url: '/projectMinute/initiatorCancel',
        method: 'POST',
        params: data
    })
}

// 发布人发表评论（vip私密推荐）
export function initiatorEvaluatel(data) {
    return request({
        url: '/projectMinute/initiatorEvaluate',
        method: 'POST',
        params: data
    })
}

// 发布人发表评论（感兴趣、会员推荐、浏览过）
export function initiatorEvaluateForOther(data) {
    return request({
        url: '/myCenter/initiatorEvaluate',
        method: 'POST',
        params: data
    })
}


// 多人沟通取消
export function multiInviteeCancel(data) {
    return request({
        url: '/myJoinProject/multiInviteeCancel',
        method: 'POST',
        params: data
    })
}

// 推荐原因
export function introduceTemplate(data) {
    return request({
        url: '/projectMinute/introduceTemplate',
        method: 'get',
        params: data
    })
}
