<template>
    <div class="container">
        <BreadcrumbNav :pageName="$t('message.member')"></BreadcrumbNav>
        <div class="body_box auto">
            <div class="classify_nav">
                <div class="classify_nav_two fl">
                    <div class="nav_two_title fl">{{$t("message.designation")}}</div>
                    <div class="fl" style="margin-right: 22px;">
                        <el-select :placeholder="$t('message.pleaseSelect')" @change="getMemberList()" clearable
                                   filterable style="width:124px !important;" v-model="positionValue">
                            <el-option :key="item.id" :label="item.functionName" :value="item.functionName"
                                       v-for="item in positionList">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="nav_two_title fl">{{$t("message.suozaididian")}}</div>
                    <div class="fl">
                        <el-select :placeholder="$t('message.pleaseSelect')" @change="getMemberList()" clearable
                                   filterable style="width:124px !important;" v-model="locationValue">
                            <el-option :key="item.id" :label="item.localtionName" :value="item.localtionName"
                                       v-for="item in locationList">
                            </el-option>
                        </el-select>
                    </div>
                    <!--<div class="nav_two_title fl" style="margin-left:20px;">{{$t("message.professionalSearchBar")}}</div>-->

                </div>
                <!--  搜索  -->
                <div class="classify_nav_three fr">
                    <input :placeholder="$t('message.memberName')+'/'+$t('message.companyName')"
                           @keyup.enter="getMemberList()" type="text" v-model="keyword">
                    <div @click="getMemberList()" class="fr search_btn cur">
                        <img alt="" height="18" src="../../assets/images/icon_search@2x.png" width="18">
                    </div>
                </div>
                <!--专业搜索-->
                <div class="fl classify_nav_three fr" style="width:200px;margin-right: 18px">
                    <input :placeholder="$t('message.theGlobalMatching')" style="width:170px;" type="text"
                           v-model="professionalSearch">
                </div>
                <div class="clearfix"></div>
            </div>
            <!--  列表  -->
            <div class="people_list" style="text-align: center">
                <h1 v-if="memberList.length==0">{{$t('myWallet.noDataAvailable')}}</h1>

                <div :key="index" class="people_item fl" v-for="(item,index) in memberList">
                    <p class="photo">
                        <img :onerror="default_cattle" :src="item.avatar" @click="jumpPersonCenter(item)" alt=""
                             height="120px" style="cursor: pointer" width="120px">
                    </p>
                    <h2 class="over">{{ item.name }}</h2>
                    <p class="company_name over">{{ item.company_name }}</p>
                    <p class="company_name over">{{ item.position }}</p>
                    <p class="introduce over3">{{ item.profile }}</p>
                    <!--<div class="btn_box" v-show="item.is_hidden_issuer==1 || (item.is_hidden_issuer=='' && item.memberId != '')">-->
                    <div class="btn_box">
                        <div @click="$router.push({path: '/user/index',query:{userType:item.userType,talentId:item.id,}})"
                             class="fl cur">
                            {{$t("personalCenter.buyNow")}}
                        </div>
                        <div @click.prevent="attention($event,2,item.id,item.name,index)"
                             class="fr cur attention_active"
                             v-if="item.followed == 1" v-preventReClick>{{$t("personalCenter.cancelAttention")}}
                        </div>
                        <div @click.prevent="attention($event,1,item.id,item.name,index)" class="fr cur" v-else
                             v-preventReClick>
                            {{$t("message.immediateAttention")}}
                        </div>
                    </div>
                </div>

                <div class="clearfix"></div>
            </div>
            <!--  分页  -->
            <div class="Pagination_box">
                <el-pagination
                        :current-page="pageNum"
                        :page-size="40"
                        :total="pageCount"
                        @current-change="handleCurrentChange"
                        layout="prev, pager, next,slot"
                        style="text-align: center">
                    <slot>
                        <div class="jump-page">
                            <span>{{$t("memberClassification.goTo")}}</span>
                            <input @keyup.enter="goJumpPage(jumpPageNum)" type="text" v-model="jumpPageNum">
                            <i @click="goJumpPage(jumpPageNum)" class="el-icon-arrow-right"></i>
                        </div>
                    </slot>
                </el-pagination>
            </div>

            <!--  免责声明及会员资格  -->
            <div class="statement">
                <h2 class="statement_title">{{ $i18n.locale=='zh'? statementInfo.title:statementInfo.titleEnglish
                    }}</h2>
                <div class="content"
                     v-html="$i18n.locale=='zh'?statementInfo.content:statementInfo.contentEnglish"></div>

                <h2 class="recommend_title">{{ $t("recommendMember.recommendMember") }}</h2>
                <p class="hint_two">
                    {{ $t("recommendMember.nonMembersRecommend") }}
                </p>
                <div class="input_box auto">
                    <input :placeholder="$t('recommendMember.linkedinWebsite')" style="width: 100%;" type="text"
                           v-model="linkedinWebsite">
                </div>
                <p class="file-name">{{ fileName }}</p>
                <div class="upload_btn auto cur">
                    <img alt="" src="../../assets/images/icon_upload@2x.png" width="20px">
                    {{ $t("recommendMember.uploadBusinessCardResume") }}
                    <el-upload
                            :action="actionUrl"
                            :before-upload="handleBefore"
                            :on-change="handleChange"
                            :on-error="handleError"
                            :on-success="handleSuccess"
                            :show-file-list="false"
                            class="upload-area"
                            list-type="text"
                            ref="upload"
                    >
                        <el-button size="small" type="primary">{{$t("accountManagement.selectFile")}}选择文件</el-button>
                    </el-upload>
                </div>
                <div class="hint_three">
                    {{ $t("recommendMember.supportJpgPngPdfNotMoreThan3MB") }}
                </div>
                <div @click="recommend()" class="submit_btn auto cur">
                    {{ $t("recommendMember.submit") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Classify from '@/components/member/classify'
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import {
        positionList,
        locationList,
        disclaimer,
        recommend,
        memberList,
        followTalent,
        getStatementInfo
    } from '@/api/memberApi'
    import {uploadingResume} from '@/api/uploadApi'
    import {Loading as elLoading} from "element-ui";

    let loadingInstance = null;
    export default {
        name: "index",
        data() {
            return {
                default_cattle: 'this.src="' + require('../../assets/images/defaultphoto@2x.png') + '"', //加载图片报错时处理方法

                fileName: '',
                actionUrl: uploadingResume,
                resume: '',
                memberList: [],
                pageNum: 1,
                pageCount: 0,
                jumpPageNum: '',
                keyword: '',
                professionalSearch: '',
                disclaimerMsg: [],
                linkedinWebsite: '',//领英网址
                position: '',
                positionList: [],
                locationList: [],
                statementInfo: {},
                positionValue: '',
                locationValue: '',
                showPosition: true,
            }
        },
        components: {
            Classify,
            BreadcrumbNav,
        },
        methods: {
            jumpPersonCenter(item) {
                this.$router.push({path: '/user/index', query: {userType: item.userType, talentId: item.id,}})
                /*if(item.memberId == null || item.memberId == ''){
                  this.$message({
                    message: this.$t('message.noInformation'),
                    type: 'error'
                  });
                }else {
                  this.$router.push({path: '/user/index',query:{userType:item.userType, talentId:item.id, }})
                }*/
            },
            handleChange(file, fileList) {
                if (fileList.length > 1) {
                    this.$refs.upload.uploadFiles.splice(0, 1)
                }
            },
            handleSuccess(response, file, fileList) {
                loadingInstance.close();
                if (response.code === 200) {
                    this.fileName = file.name;
                    this.$message({
                        message: this.$t('user.ploadSucceeded'),
                        type: 'success'
                    });
                    this.resume = response.data.path;
                } else {
                    this.$message.error(response.msg)
                }
            },
            handleError(err, file, fileList) {
                this.$message.error(err)
            },
            handleBefore(file) {
                loadingInstance = elLoading.service({
                    text: 'Desperately uploading',//拼命上传中',
                    // spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            gopage(url) {
                this.$router.push(url)
            },
            //获取会员列表
            getMemberList() {
                let formData = {
                    keyword: this.keyword,
                    majorSearch: this.professionalSearch,
                    pageNum: this.pageNum,
                    pageSize: 40,
                    token: localStorage.getItem('token'),
                    position: this.positionValue,
                    industryId: this.$route.query.functionName ? this.$route.query.functionName : 0,
                    location: this.positionValue,
                    secIndustryId: this.$route.query.id,
                    financingId: this.$route.query.financingId,
                }
                memberList(formData).then((res) => {
                    if (res.code == 200) {
                        this.memberList = res.data.list;
                        this.pageCount = res.data.total;
                    } else {
                        this.memberList = [];
                        this.pageCount = 1;
                        this.$message.error(res.code)
                    }
                })
            },

            goJumpPage(num) {
                this.pageNum = num;
                this.getMemberList();
            },

            handleCurrentChange: function handleCurrentChange(p) {
                this.pageNum = p;
                this.getMemberList();
            },

            //关注
            attention(event, type, id, name, index) {
                if (event.target.disabled) {
                    // 点击太频繁了
                    this.$message.warning(this.$t('myProject.clickFrequentMsg'))
                    return
                }
                let formData = {
                    operateType: type,
                    talentId: id,
                    talentName: name,
                    token: localStorage.getItem('token')
                }
                followTalent(formData).then((res) => {
                    if (res.code == 200) {
                        if (type == 1) {
                            this.memberList[index].followed = 1
                            this.$message.success(this.$t("myProject.followMsg.followSuccess"))
                        } else {
                            this.memberList[index].followed = 0
                            this.$message.success(this.$t("myProject.followMsg.unfollowSuccess"))
                        }
                    }
                })
            },

            //推荐会员
            recommend() {
                let formData = {
                    linkedinWebsite: this.linkedinWebsite,
                    resume: this.resume,
                    sourceType: 2,
                    token: localStorage.getItem('token')
                }
                recommend(formData).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            // 免责声明
            getDisclaimer() {
                let formData = {
                    dictType: 'disclaimer_membership'
                }
                disclaimer(formData).then((res) => {
                    this.disclaimerMsg = res.data;
                })
            },

            // 职称查询条件列表
            getPositionList() {
                let formData = {
                    token: localStorage.getItem('token'),
                }
                positionList(formData).then((res) => {
                    this.positionList = res.data;
                })
            },

            // 地区查询条件列表
            getLocationList() {
                let formData = {
                    token: localStorage.getItem('token'),
                }
                locationList(formData).then((res) => {
                    this.locationList = res.data;
                })
            },
            /** 获取会员免责声明 */
            getStatement() {
                getStatementInfo().then(response => {
                    this.statementInfo = response.data;
                });
            },
        },


        mounted() {
            this.getDisclaimer();
            this.getMemberList();
            this.getPositionList();
            this.getLocationList();
            this.getStatement();
        }
    }
</script>

<style scoped>
    .upload-area {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
    }

    .container {
        width: 100%;
        min-height: 88vh;
    }

    .body_box {
        width: 1200px;
    }

    .classify_nav {
        padding-bottom: 28px;
        box-sizing: border-box;
        border-bottom: 2px solid #E5E5E5;
        vertical-align: middle;
        margin-top: 28px;
        line-height: 40px;
    }

    .nav_one_btn {
        margin-right: 25px;
        /*width: 124px;*/
        height: 40px;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        line-height: 38px;
        vertical-align: middle;
        padding: 0 18px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .nav_one_btn img {
        margin-top: 14px;
    }

    .classify_nav_two {
        /*margin-left: 66px;*/
    }

    .nav_two_title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-right: 9px;
    }

    .el-select {
        width: 124px !important;
        height: 40px;
        background: #FAFAFA !important;
        opacity: 1;
        border-radius: 131px !important;
    }

    .classify_nav_three {
        width: 343px;
        height: 40px;
        background: #FAFAFA;
        border-radius: 131px;
        line-height: 40px;
    }

    .classify_nav_three input {
        width: 230px;
        background: #FAFAFA;
        margin-left: 18px;
    }

    .search_btn {
        width: 65px;
        height: 40px;
        background: #000000;
        border-radius: 131px;
        text-align: center;
        line-height: 40px;
    }

    .search_btn img {
        margin-bottom: 4px;
    }

    /*  列表  */

    .people_list {
        width: 100%;
        margin-top: 35px;
    }

    .people_item {
        width: 283px;
        height: 352px;
        background: #FFFFFF;
        border-radius: 10px;
        box-sizing: border-box;
        margin-right: 22px;
        margin-bottom: 50px;
        transition: .3s all;
    }

    .people_item:nth-child(4n) {
        margin-right: 0;
    }

    .people_item:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.09);
    }

    .people_item .photo {
        text-align: center;
        margin-top: 20px;
    }

    .photo img {
        border-radius: 50%;
    }

    .people_item > h2 {
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 20px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 23px;
        color: #000000;
        text-align: center;
        margin-top: 18px;
    }

    .people_item .company_name {
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        text-align: center;
        margin-top: 5px;
    }

    .people_item .introduce {
        height: 49px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #949494;
        padding: 0 46px;
        box-sizing: border-box;
        text-align: center;
        margin-top: 5px;
    }

    .btn_box {
        margin-top: 19px;
        padding: 0 28px;
    }

    .btn_box > div {
        width: 100px;
        height: 40px;
        border: 1px solid #707070;
        border-radius: 25px;
        text-align: center;
        line-height: 38px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .attention_active {
        border: 1px solid #F1F1F1 !important;
    }

    .Pagination_box {
        height: 40px;
        margin-top: 45px;
    }

    .statement {
        margin-top: 92px;
    }

    .statement_title {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 40px;
        color: #000000;
        text-align: center;
        margin-bottom: 37px;
    }

    .hint_one {
        width: 1200px;
        height: 114px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 29px;
        color: #000000;
    }

    .recommend_title {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
        text-align: center;
        margin-top: 200px;
    }

    .hint_two {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #707070;
        margin-top: 14px;
        text-align: center;
    }

    .input_box {
        width: 500px;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #D6D6D6;
        margin-top: 40px;
        box-sizing: border-box;
    }

    .file-name {
        margin-top: 46px;
        text-align: center;
    }

    .upload_btn {
        width: 274px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        text-align: center;
        line-height: 48px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-top: 10px;
        position: relative;
    }

    .hint_three {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #949494;
        text-align: center;
        margin-top: 5px;
    }

    .submit_btn {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        line-height: 48px;
        margin-top: 32px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        transition: .3s all;
        margin-bottom: 100px;
    }

    .submit_btn:hover {
        background: #000000;
        color: #FFFFFF;
    }

    .jump-page {
        display: inline-block;
        vertical-align: middle;
        width: 124px;
        height: 40px;
        background: #FAFAFA;
        border-radius: 131px;
        margin-left: 30px;
        line-height: 40px !important;
        text-align: center;

    }

    .jump-page > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        vertical-align: middle;
        line-height: 23px !important;
    }

    .jump-page > input {
        background: transparent;
        width: 40px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin-top: 2px;
    }

    .jump-page > i {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        font-weight: 700;
        font-size: 19px;
    }
</style>
