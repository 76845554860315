<template>
    <div class="notice-container">
        <!--吐司导航-->
        <breadcrumb-nav :pageName="$t('activityNotice.activityNotice')"></breadcrumb-nav>
        <div class="main">
            <!--条件区域-->
            <div class="condition">
                <div class="condition-l">
                    <div class="v-center">
                        <CustomButton :is-checked="tabIndex===null" @click.native="changeTab(null)" class="cl-btn fl">
                            {{ $t("activityNotice.all") }}
                        </CustomButton>
                        <CustomButton :is-checked="tabIndex===1" @click.native="changeTab(1)" class="cl-btn fl">{{
                            $t("activityNotice.enrolling")
                            }}
                        </CustomButton>
                        <CustomButton :is-checked="tabIndex===2" @click.native="changeTab(2)" class="cl-btn fl">{{
                            $t("activityNotice.enrollDead")
                            }}
                        </CustomButton>
                        <CustomButton :is-checked="tabIndex===3" @click.native="changeTab(3)" class="cl-btn fl">{{
                            $t("activityNotice.activityDead")
                            }}
                        </CustomButton>
                    </div>
                </div>
                <div class="condition-r">
                    <div class="v-center">
                        <div class="search-wrap fl">
                            <input :placeholder="$t('activityNotice.keyword')" @keyup.enter="getDataList()" type="text"
                                   v-model="keyword">
                            <div @click="getDataList(true)" class="search-btn cur">
                                <img alt="" src="@/assets/images/icon_search@2x.png">
                            </div>
                        </div>
                        <!--<CustomButton class="cl-btn fl" :is-checked="true">{{-->
                        <!--    $t("activityNotice.publishedProjects")-->
                        <!--  }}-->
                        <!--</CustomButton>-->
                    </div>
                </div>

            </div>
            <!--公告列表-->
            <div class="announcement-list">
                <announcement-item :avtivityList="item" :key="index" v-for="(item,index) in avtivityList"/>
                <div style="text-align: center;padding-top: 20px" v-if="avtivityList.length==0">
                    {{$t('myWallet.noDataAvailable')}}<!--暂无数据--></div>
                <el-pagination
                        :total="avtivityCount"
                        @current-change="handleCurrentChange"
                        layout="prev, pager, next,slot"
                        style="text-align: center"
                        v-if="avtivityCount > 11">
                    <slot>
                        <div class="jump-page">
                            <span>{{$t("memberClassification.goTo")}}</span>
                            <input @keyup.enter="goJumpPage(jumpPageNum)" type="text" v-model="jumpPageNum">
                            <i @click="goJumpPage(jumpPageNum)" class="el-icon-arrow-right"></i>
                        </div>
                    </slot>
                </el-pagination>
            </div>
            <br>
        </div>

    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import CustomButton from "@/components/activityNotice/CustomButton";
    import AnnouncementItem from "@/components/activityNotice/AnnouncementItem";
    import {getAnnouncementList, payForActivities} from "@/api/activityApi"

    export default {
        data() {
            return {
                tabIndex: null,
                pageNum: 1,
                countPage: 0,
                jumpPageNum: '',
                avtivityList: [],//活动列表
                avtivityCount: [],
                page: 0,
                keyword: '',//搜索关键字
            }
        },
        components: {
            BreadcrumbNav,
            CustomButton,
            AnnouncementItem,
        },
        created() {
            this.getDataList();
        },
        methods: {

            goJumpPage(num) {
                this.pageNum = num;
                this.getDataList();
            },
            //
            changeTab(index) {
                // this.avtivityList = [];
                this.tabIndex = index;
                this.getDataList();
            },
            //活动公告列表
            getDataList(isKey) {
                isKey = isKey || false
                let formData = {
                    eventStatus: this.tabIndex,
                    keyword: isKey ? this.keyword : "",
                    pageNum: this.pageNum,
                    pageSize: 10,
                    token: localStorage.getItem('token'),
                }
                getAnnouncementList(formData).then((res) => {
                    if (typeof (res.data) != "undefined") {
                        this.avtivityList = res.data.list;
                        this.avtivityCount = res.data.total;
                    } else {
                        this.avtivityList = [];
                        this.avtivityCount = [];
                    }
                    if (res.code == 204) {
                        this.avtivityList = [];
                    }
                });
            },

            handleCurrentChange: function handleCurrentChange(p) {
                this.pageNum = p;
                this.getDataList();
            }
        }
    }
</script>

<style scoped>
    @import "../../assets/css/element_reset.css";

    .notice-container {
        background-color: #FFFFFF;
    }

    .main {
        width: 1200px;
        min-height: calc(100vh - 340px);
        margin: 0 auto;
    }

    .condition {
        width: 100%;
        height: 99px;
        border-bottom: 1px solid #E5E5E5;
    }

    .condition-l {
        float: left;
        height: 99px;
        line-height: 99px;
    }

    .condition-r {
        float: right;
        height: 99px;
        line-height: 99px;

    }

    .v-center {
        display: inline-block;
        vertical-align: middle;
    }

    .cl-btn {
        margin-right: 20px;
    }

    .search-wrap {
        /*width: 343px;*/
        height: 40px;
        background: #FAFAFA;
        border-radius: 131px;
        margin-right: 20px;
    }

    .search-wrap > input {
        float: left;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        width: 250px;
        height: 100%;
        padding-left: 18px;
        background-color: transparent;
    }

    .search-btn {
        float: left;
        width: 65px;
        height: 40px;
        background: #000000;
        border-radius: 131px;
        text-align: center;
        line-height: 40px;
    }

    .search-btn img {
        display: inline-block;
        width: 18px;
        height: 18px;
    }

    .announcement-list {
        width: 100%;
        height: auto;
        padding: 39px 0;
    }

    .jump-page {
        display: inline-block;
        vertical-align: middle;
        width: 124px;
        height: 40px;
        background: #FAFAFA;
        border-radius: 131px;
        margin-left: 30px;
        line-height: 40px !important;
        text-align: center;

    }

    .jump-page > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        vertical-align: middle;
        line-height: 23px !important;
    }

    .jump-page > input {
        background: transparent;
        width: 40px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin-top: 2px;
    }

    .jump-page > i {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        font-weight: 700;
        font-size: 19px;
    }
</style>
