<template>
    <div class="container">
        <BreadcrumbNav :page-name="$t('vip.vip')"></BreadcrumbNav>
        <div class="vip_top">
            <span> Bless Boss</span>
            <div class="vip_card_wrap">
                <div class="vip_card">
                    <h2>VIP</h2>
                    <p>Bless Boss</p>
                    <div class="btn-pay-group">
                        <div @click="getVipServicePurchase()" class="save_btn cur">
                            PayPal {{ $t("myWallet.payment") }}
                        </div>
                        <div @click="$refs.skPayDialog.open()" class="save_btn cur">
                            Stripe {{ $t("myWallet.payment") }}
                        </div>
                    </div><!--@click="getPayUrl()"-->
                </div>
            </div>
        </div>
        <div class="vip_con_one">
            <h2>{{ VipMsg.serviceName }}</h2>
            <p class="vip_one_price">${{ VipMsg.servicePrice }}</p>
            <p class="vip_one_date">{{ VipMsg.serviceTime }} Months</p>
            <p class="vip_one_title">{{ $t("vip.serviceContent") }}：</p>
            <img :src="VipMsg.serviceFlowUrl" alt="" style="width: 750px">
        </div>

        <!--Stripe支付弹窗-->
        <SkPayDialog @bankPay="post_submitStripe" ref="skPayDialog"></SkPayDialog>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import {serviceInfo, vipServicePurchase} from "@/api/vipApi"
    import PayPal from 'vue-paypal-checkout'
    import {createOrder, chargeVipService} from '@/api/payment'
    import {Loading} from "element-ui";
    import SkPayDialog from "@/components/dialog/SkPayDialog";

    let loadingInstance = null;
    export default {
        name: "VIP",
        data() {
            return {
                type: '',
                VipMsg: {},
                orderData: {},
            }
        },
        components: {
            BreadcrumbNav,
            PayPal,
            SkPayDialog
        },
        created() {
            this.type = this.$route.query.type
        },
        async mounted() {
            await this.getVip();
        },
        methods: {
            //Stripe支付
            post_submitStripe(stripPayForm) {
                let expMonthAndYears = stripPayForm.expMonthAndYear.split("/")
                let formData = {
                    slmToken: localStorage.getItem('token'),
                    projectId: this.$route.query.projectId,
                    projectType: this.type,
                    serviceId: this.VipMsg.id,
                    //amount: this.stripPayForm.amount,
                    email: stripPayForm.email,
                    number: stripPayForm.number,
                    expMonth: expMonthAndYears[0],
                    expYear: expMonthAndYears[1],
                    cvc: stripPayForm.cvc,
                    name: stripPayForm.name,
                    country: stripPayForm.country,
                    postcode: stripPayForm.postcode,
                }
                console.log("VIP服务购买缴费StripeFormData")
                console.log(formData)
                loadingInstance = Loading.service({
                    text: 'Request Stripe...',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                chargeVipService(formData).then((res) => {
                    if (res.code == 200) {
                        loadingInstance.close();
                        this.$refs.skPayDialog.close();
                        this.$message.success(res.msg);
                        this.$router.go(-1);
                    } else if (res.code == 204) {
                        loadingInstance.close();
                        this.$refs.skPayDialog.close();
                        this.$message.error(res.msg)
                    } else {
                        loadingInstance.close();
                        this.$message.error(res.msg)
                    }
                });
            },

            getVip() {
                let formData = {
                    projectIssueType: this.type,
                }
                serviceInfo(formData).then((res) => {
                    this.VipMsg = res.data;
                    //this.getVipServicePurchase()
                })
            },
            getVipServicePurchase() {
                let formData = {
                    projectId: this.$route.query.projectId,
                    projectType: this.type,
                    serviceId: this.VipMsg.id,
                    token: localStorage.getItem('token'),
                }
                vipServicePurchase(formData).then((res) => {
                    if (res.code == 200) {
                        this.orderData = res.data;
                        this.getPayUrl();
                    } else {
                        this.$message.error(res.msg);
                    }

                })
            },
            getPayUrl() {
                let formData = {
                    orderContent: this.orderData.orderContent,
                    orderNumber: this.orderData.orderNumber,
                    paymentAmount: this.orderData.paymentAmount,
                }
                loadingInstance = Loading.service({
                    text: 'Request PayPal...',
                    // spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                createOrder(formData).then((res) => {
                    if (res.code == 200) {
                        window.location.href = res.data.approval_url;
                    } else {
                        loadingInstance.close();
                        this.$message.error(res.msg);
                    }
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .container {
        width: 100%;
        min-height: 100%;
    }

    .vip_top {
        width: 100%;
        height: 165px;
        background: #FAFAFA;
        font-size: 124px;
        font-family: Playfair Display;
        font-weight: bold;
        line-height: 165px;
        color: #EEEEEE;
        text-align: center;
        position: relative;
    }

    .vip_card_wrap {
        position: absolute;
        bottom: -131px;
        left: 0;
        width: 100%;
    }

    .vip_card {
        width: 425px;
        height: 222px;
        background: url("../assets/images/vip_car@2x.png") no-repeat;
        background-size: 100% 100%;
        border-radius: 12px;
        margin: 0 auto;
        text-align: left;

        .btn-pay-group {
            display: flex;
            justify-content: center;
        }
    }

    .vip_card h2 {
        font-size: 32px !important;
        font-family: Roboto;
        font-weight: bold;
        line-height: 43px;
        color: #FFFFFF;
        margin-left: 45px;
        padding-top: 34px;
    }

    .vip_card p {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 18px;
        color: #FFFFFF;
        margin-left: 45px;
    }

    .vip_card .save_btn {
        &:first-child {
            margin-right: 20px;
        }

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        width: 140px;
        height: 50px;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 48px;
        box-sizing: border-box;
        margin-top: 30px;
    }

    .vip_con_one {
        padding-top: 171px;
        text-align: center;
    }

    .vip_con_one h2 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
        margin-bottom: 25px;
    }

    .vip_one_price {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 26px;
        color: #000000;
        margin-bottom: 9px;
    }

    .vip_one_date {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .vip_one_title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-top: 63px;
    }

    .vip_con_one img {
        margin-top: 30px;
        margin-bottom: 88px;
    }
</style>
