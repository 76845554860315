import request from '../utils/request'

// 领英授权登录
export function loginByLinkedin(data) {
    return request({
        url: 'oauth/callback/linkedin',
        method: 'post',
        data
    })
}

// 推特授权登录
export function loginByTwitter(data) {
    return request({
        url: 'oauths/callback/twitter',
        method: 'post',
        data
    })
}

// 脸书授权登录
export function loginByFacebook(data) {
    return request({
        url: 'oauthf/callback/facebook',
        method: 'post',
        data
    })
}
