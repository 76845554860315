<template>
    <div class="container-item">
        <div class="type fl">{{ val.news_scene }}</div>
        <div class="content fl">
            <h1>{{ val.news_title }}</h1>
            <!--<h2><pre>{{ val.news_detail }}</pre></h2>-->
            <h2>{{ val.news_detail }}</h2>
            <div class="feedback">
                <span class="key">{{$t("recommendMember.sender")}}：{{val.create_by}}</span>
            </div>
        </div>
        <div class="date fr">{{val.create_time}}</div>

    </div>
</template>

<script>
    export default {
        name: "MessageItem",
        props: ["val"]
    }
</script>

<style scoped>
    .container-item {
        /*清楚浮动*/
        overflow: hidden;
        width: 100%;
        /*min-height: calc(100vh - 700px);*/
        margin-bottom: 60px;
    }

    .container:last-child {
        margin-bottom: 0;
    }

    .type {
        width: 122px;
        height: 51px;
        background: #FAFAFA;
        border-radius: 131px;
        text-align: center;
        line-height: 51px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 32px;
    }

    .content {
        width: 880px;
    }

    .content > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .content > h2 {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 26px;
        color: #000000;
        margin-top: 14px;
    }

    .feedback {
        margin-top: 23px;
    }

    .feedback > .key {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 26px;
        color: #949494;
    }

    .feedback > .value {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 26px;
        color: #000000;
    }

    .date {
        padding-top: 7px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }
</style>
