<template>
    <div class="container">
        <BreadcrumbNav :pageName="$t('myWallet.wallet')"></BreadcrumbNav>
        <div class="wallet_wrap auto">
            <div class="wallet_box">
                <h2>{{ $t("myWallet.paypalAccount") }}</h2>
                <div class="wallet_con">
                    <img alt="" class="fl" src="../assets/images/paypal@2x.png" width="84px">
                    <p class="fl">{{ accountMsg.paypalAccount }}</p>
                    <div v-if="hasPaypal">
                        <div @click="dialogFormVisible = true" class="fl wallet_btn cur">{{ $t("myWallet.modify") }}
                        </div>
                        <div @click="deleteDialog()" class="fl wallet_btn cur">{{ $t("myWallet.delete") }}</div>
                    </div>
                    <div v-else>
                        <div @click="dialogFormVisible = true" class="fl wallet_btn cur" style="width: 150px">{{
                            $t("myWallet.newlyAdded") }}
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
        <el-dialog :title="hasPaypal? $t('myWallet.modifyPayPal'):$t('addPayPal.modifyPayPal')"
                   :visible.sync="dialogFormVisible">
            <el-form :model="form">
                <el-form-item :label="$t('myWallet.paypalAccount')" :label-width="formLabelWidth">
                    <el-input autocomplete="off" v-model="form.account"></el-input>
                </el-form-item>
            </el-form>
            <div class="dialog-footer" slot="footer">
                <el-button @click="dialogFormVisible = false">{{$t("myInterviewDetail.cancel")}}</el-button>
                <el-button @click="accountSetting(1)" style="background-color: black" type="primary">
                    {{$t("myInterviewDetail.determine")}}
                </el-button>
            </div>
        </el-dialog>
        <div class="order_wrap auto">
            <div class="order_nav">
                <ul class="order_nav_list fl">
                    <li :class="{order_nav_list_active:checkedTab==0}" @click="changeTab(0)" class="cur  fl">{{
                        $t("myWallet.all") }}
                    </li>
                    <li :class="{order_nav_list_active:checkedTab==1}" @click="changeTab(1)" class="cur fl">{{
                        $t("myWallet.toBePaid") }}
                    </li>
                    <li :class="{order_nav_list_active:checkedTab==2}" @click="changeTab(2)" class="cur fl">{{
                        $t("myWallet.paid") }}
                    </li>
                    <li :class="{order_nav_list_active:checkedTab==4}" @click="changeTab(4)" class="cur fl">{{
                        $t("myWallet.Refunded") }}
                    </li>
                    <div class="clearfix"></div>
                </ul>
                <div class="order_nav_date fl">
                    <span class="fl">{{ $t("myWallet.Date") }}</span>
                    <div class="fl pick_date">
                        <el-date-picker
                                :placeholder="$t('myWallet.selectADate')"
                                type="date"
                                v-model="startTime"
                                value-format="yyyy-MM-dd">
                        </el-date-picker>
                        <img alt="" src="../assets/images/icon_date@2x.png">
                    </div>
                    <span class="fl">-</span>
                    <div class="fl pick_date">
                        <el-date-picker
                                :placeholder="$t('myWallet.selectADate')"
                                type="date"
                                v-model="endTime"
                                value-format="yyyy-MM-dd">
                        </el-date-picker>
                        <img alt="" src="../assets/images/icon_date@2x.png">
                    </div>
                    <div @click="selectOrderByDate()" class="fl search_btn cur">{{ $t("myWallet.query") }}</div>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="order_title">
                <div class="fl order_title_id">
                    {{ $t("myWallet.orderId") }}
                </div>
                <div class="fl order_title_date">
                    {{ $t("myWallet.transactionDate") }}
                </div>
                <div class="fl order_title_state">
                    {{ $t("myWallet.paymentStatus") }}
                </div>
                <div class="fl order_title_price">
                    {{ $t("myWallet.amountUSD") }}
                </div>
                <div class="fl order_title_con">
                    {{ $t("myWallet.orderContent") }}
                </div>
                <div class="fr order_title_record">
                    <span>{{ this.total }}</span> {{ $t("myWallet.recordsInTotal") }}
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="order_list">
                <p style="text-align: center" v-if="list.length==0">{{ $t("myWallet.noDataAvailable") }}</p>
                <div :key="index" class="order_item" v-for="(item,index) in list">
                    <div class="fl order_title_id">
                        {{ item.order_number }}
                    </div>
                    <div class="fl order_title_date">
                        {{ item.create_time }}
                    </div>
                    <div class="fl order_title_state">
                        <!--{{ item.payment_state_name }}已支付-->
                        <div v-if="item.payment_state != 1">
                            {{$t('myWallet.paid')}}
                        </div>
                        <div v-else>
                            {{ item.payment_state_name }}
                        </div>
                    </div>
                    <div class="fl order_title_price">
                        {{ item.payment_amount }}
                    </div>
                    <div class="fl order_title_con">
                        {{ item.order_content }}
                    </div>
                    <div class="fr order_title_record">
                        <!--order_item_btn_one-->
                        <div @click="getPayUrl(item)" class="order_item_btn cur fl" v-if="item.payment_state==1">PayPal
                            Pay
                        </div>
                        <div @click="getStripe(item)" class="order_item_btn cur fl"
                             v-if="item.payment_state==1 && item.order_type != 1">Stripe Pay
                        </div>
                        <div @click="$refs.applyRefundDialog.openDialog(item.id)" class="order_item_btn cur"
                             style="margin: 0 auto"
                             v-if="item.payment_state==2 && item.order_type != 4 && item.is_return == 1">{{
                            $t("myWallet.applyForARefund") }}
                        </div>
                        <span v-if="item.payment_state==3">{{ $t("myWallet.refundInProgress") }}</span>
                        <!--<span>退款申请失败不符合退款条件</span>-->
                        <span v-if="item.payment_state==4">{{ $t("myWallet.refundApplicationSuccessful") }}</span>
                        <span v-if="item.payment_state==5">{{ item.after_sale_fail_reason }}</span>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>

        <el-pagination
                :current-page.sync="pageNum"
                :page-size="pageSize"
                :total="total"
                @current-change="handleCurrentChange"
                layout="prev, pager, next,slot"
                style="text-align: center"
                v-if="total!==0"
        >
            <slot>
                <div class="jump-page">
                    <span>{{$t("memberClassification.goTo")}}</span>
                    <input type="text" v-model.number="goPageNum">
                    <i @click="goJumpPage(goPageNum)" class="el-icon-arrow-right"></i>
                </div>
            </slot>
        </el-pagination>
        <ApplyRefundDialog ref="applyRefundDialog"/>
        <!--Stripe支付弹窗-->
        <SkPayDialog @bankPay="post_submitStripe" ref="skPayDialog"></SkPayDialog>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import {findPcAccountInfo, updatePcAccountInfo, findOrderList} from "@/api/homeApi"
    import {pageMixin} from '@/mixins';
    import ApplyRefundDialog from "@/components/dialog/ApplyRefundDialog";
    import {Loading} from "element-ui";
    import {createOrder, payByOrders} from "@/api/payment";
    import SkPayDialog from "@/components/dialog/SkPayDialog";
    import PayPal from "vue-paypal-checkout";

    let loadingInstance = null;
    export default {
        name: "wallet",
        mixins: [pageMixin],

        data() {
            return {
                hasPaypal: true,//是否存在paypal账户
                dialogFormVisible: false,//新增修改弹窗开启标识
                form: {
                    account: ''
                },
                formLabelWidth: '120px',
                checkedTab: 0,
                accountMsg: [],
                payPalAccount: '',
                pageNum: 1,
                startTime: '',
                endTime: '',
                payment_amount: 0,
                id: 0,
            }
        },
        created() {
            this.getAccount();
            this.getOrderList();
        },
        methods: {
            goJumpPage(num) {
                this.pageNum = num;

                this.getOrderList();

            },
            getStripe(item) {
                this.payment_amount = item.payment_amount;
                this.id = item.id;
                this.$refs.skPayDialog.open();
            },
            //Stripe支付
            post_submitStripe(stripPayForm) {
                let expMonthAndYears = stripPayForm.expMonthAndYear.split("/")
                let formData = {
                    slmToken: localStorage.getItem('token'),
                    orderId: this.id,
                    amount: this.payment_amount,
                    email: stripPayForm.email,
                    number: stripPayForm.number,
                    expMonth: expMonthAndYears[0],
                    expYear: expMonthAndYears[1],
                    cvc: stripPayForm.cvc,
                    name: stripPayForm.name,
                    country: stripPayForm.country,
                    postcode: stripPayForm.postcode,
                }
                console.log("订单支付StripeFormData")
                console.log(formData)
                loadingInstance = Loading.service({
                    text: 'Request Stripe...',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                payByOrders(formData).then((res) => {
                    if (res.code == 200) {
                        loadingInstance.close();
                        this.$refs.skPayDialog.close();
                        this.$message.success(res.msg);
                        this.$router.go(-1);
                    } else if (res.code == 204) {
                        loadingInstance.close();
                        this.$refs.skPayDialog.close();
                        this.$message.error(res.msg)
                    } else {
                        loadingInstance.close();
                        this.$message.error(res.msg)
                    }
                });
            },
            //切换tab
            changeTab(tab) {
                this.checkedTab = tab;
                this.pageNum = 1;
                this.goPageNum = 1;
                this.getOrderList();
            },
            //分页指示器钩子
            handleCurrentChange: function handleCurrentChange(p) {
                this.pageNum = p;
                this.getOrderList();
            },
            //按时间查询
            selectOrderByDate() {
                this.pageNum = 1;
                this.goPageNum = 1;
                this.getOrderList();
            },
            //获取账户信息
            getAccount() {
                let formData = {
                    token: localStorage.getItem('token'),
                }
                findPcAccountInfo(formData).then((res) => {
                    this.accountMsg = res.data;
                    this.form.account = res.data.paypalAccount;
                    this.hasPaypal = this.accountMsg.isPayPal == 1;
                })
            },

            //新增  修改 删除
            accountSetting(setType) {
                let formData = {
                    id: this.accountMsg.id,
                    operationType: setType,
                    payPalAccount: this.form.account,
                }
                updatePcAccountInfo(formData).then((res) => {
                    if (res.code == 200) {
                        this.dialogFormVisible = false;
                        this.$message.success(res.msg)
                        this.getAccount();
                    } else {
                        this.$message.error(res.msg)
                    }

                })
            },

            // 我的订单列表
            getOrderList() {
                let formData = {
                    token: localStorage.getItem('token'),
                    paymentState: this.checkedTab,
                    beginTime: this.startTime,
                    endTime: this.endTime,
                    talentId: this.accountMsg.id,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                }
                findOrderList(formData).then((res) => {
                    this.total = res.data?.total ?? 0;
                    this.list = res.data?.list ?? [];
                })
            },
            deleteDialog() {
                this.$confirm(this.$t('publish.delPayPal' + "?"), this.$t('publish.tips'), {
                    confirmButtonText: this.$t('publish.determine'),
                    cancelButtonText: this.$t('publish.cancel'),
                    confirmButtonClass: 'confirm-btn',
                    type: 'warning'
                }).then(() => {
                    this.accountSetting(2);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t('myInterviewDetail.YouHaveToCancel')
                    });
                });
            },
            getPayUrl(item) {
                let formData = {
                    orderContent: item.order_content,
                    orderNumber: item.order_number,
                    paymentAmount: item.payment_amount,
                }
                loadingInstance = Loading.service({
                    text: 'Request PayPal...',
                    // spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                createOrder(formData).then((res) => {
                    if (res.code == 200) {
                        window.location.href = res.data.approval_url;
                    } else {
                        loadingInstance.close();
                        this.$message.error(res.msg);
                    }
                })
            },


        },
        components: {
            BreadcrumbNav,
            ApplyRefundDialog,
            SkPayDialog
        },
    }
</script>

<style lang="scss">
    .confirm-btn {
        background-color: black !important;
        color: #FFFFFF !important;
    }

    .container {
        width: 100%;
        min-height: calc(100vh - 304px);
        padding-bottom: 30px;
    }

    .wallet_wrap {
        width: 1200px;
        padding-top: 47px;
    }

    .wallet_box {

    }

    .wallet_box > h2 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
        margin-bottom: 8px;
    }

    .wallet_con {
        line-height: 32px;
        padding-bottom: 27px;
        border-bottom: 1px solid #E5E5E5;
    }

    .wallet_con img {
        margin-top: 5px;
        margin-right: 10px;
    }

    .wallet_con p {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        color: #000000;
        margin-left: 40px;
        margin-right: 34px;
    }

    .wallet_btn {
        width: 89px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        line-height: 30px;
        text-align: center;
        margin-right: 19px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .order_wrap {
        width: 1200px;
        min-height: 78vh;
        padding-top: 31px;
    }

    .order_nav {
        margin-bottom: 28px;
        line-height: 40px;
    }

    .order_nav_list {

    }

    .order_nav_list li {
        width: 124px;
        height: 40px;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        line-height: 38px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        transition: .3s all;
        margin-right: 20px;
    }

    .order_nav_list li:nth-child(4) {
        margin-right: 0;
    }

    .order_nav_list_active {
        color: #FFFFFF !important;
        background: #000000;
    }

    .order_nav_list li:hover {
        color: #FFFFFF;
        background: #000000;
    }

    .el-input__prefix {
        display: none;
    }

    .pick_date .el-date-editor.el-input {
        width: 145px !important;
        height: 40px;
    }

    .el-input__inner {
        background: #FAFAFA !important;
        border: none !important;
    }

    .el-icon-date:before {
        content: ''
    }

    .el-input--prefix .el-input__inner {
        padding-left: 0;
    }

    .order_nav_date {
        margin-left: 40px;
    }

    .order_nav_date span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        padding: 0 10px;
    }

    .pick_date {
        width: 207px;
        height: 40px;
        background: #FAFAFA;
        border-radius: 131px;
        padding-left: 20px;
        box-sizing: border-box;
    }

    .pick_date img {
        width: 20px;
    }

    .search_btn {
        width: 86px;
        height: 40px;
        background: #000000;
        border-radius: 131px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 20px;
    }

    .order_title {
        width: 1200px;
        height: 47px;
        line-height: 47px;
        background: #FAFAFA;
        border-top: 1px solid #E5E5E5;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        text-align: center;
    }

    .order_title_id {
        width: 100px;
        text-align: left;
        padding-left: 17px;
        box-sizing: border-box;
    }

    .order_title_date {
        width: 170px;
        margin-left: 30px;
    }

    .order_title_state {
        width: 150px;
        margin-left: 20px;
    }

    .order_title_price {
        width: 110px;
        margin-left: 40px;
    }

    .order_title_con {
        width: 150px;
        margin-left: 50px;
    }

    .order_title_record {
        width: 350px;
        text-align: center;
        padding-right: 12px;
        box-sizing: border-box;
        padding-left: 17px;
    }

    .order_list {
        margin-top: 22px;
    }

    .order_item {
        line-height: 32px;
        margin-bottom: 22px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        text-align: center;
    }

    .order_item .order_title_id {
        padding-left: 0;
    }

    .order_item .order_title_record {
        text-align: center;
    }

    .order_item_btn {
        /*margin: 0 auto;*/
        width: 150px;
        height: 35px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;

        &:first-child {
            margin-right: 20px;
        }
    }

    .order_item_btn_one {
        width: 99px;
        height: 32px;
        background: #FAFAFA !important;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        border: none !important;
    }

    .order_item_btn_two {
        width: 99px;
        height: 32px;
        background: #FAFAFA !important;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        border: none !important;

    }

    .jump-page {
        display: inline-block;
        vertical-align: middle;
        width: 124px;
        height: 40px;
        background: #FAFAFA;
        border-radius: 131px;
        margin-left: 30px;
        line-height: 40px !important;
        text-align: center;

    }

    .jump-page > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        vertical-align: middle;
    }

    .jump-page > input {
        background: transparent;
        width: 40px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin-top: 2px;
    }

    .jump-page > i {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        font-weight: 700;
        font-size: 19px;
    }
</style>
