<template>
    <div class="container">
        <loginHeader></loginHeader>
        <div class="forget_box auto">
            <h2>Forgot</h2>
            <p class="hint_one">Your Password?</p>
            <p class="hint_two">No worries.Enter your email address and we'llemail you a link to reset it.</p>
            <div class="input_box">
                <input placeholder="Your email" type="text" v-model="account"/>
            </div>
            <div class="input_box">
                <input placeholder="Verification code" type="text" v-model="code"/>
                <div @click="sendAuthCodeToEmail" class="cur fr get_code">
                    {{content}}
                </div>
            </div>
            <div class="input_box">
                <input placeholder="Password" type="password" v-model="password"/>
            </div>
            <div @click="submit" class="confirm_btn cur">
                Confirm
            </div>
            <div @click="goback()" class="backto cur">
                Back to sign in
            </div>
        </div>
    </div>
</template>

<script>
    import loginHeader from "../../components/login/loginHeader";
    import {forgetPassword, sendAuthCodeToEmail} from "@/api/loginApi";

    export default {
        name: "forget_psd",
        data() {
            return {
                account: '',
                code: '',
                password: '',
                content: 'Send',
                is_disabled: true,
            };
        },
        components: {
            loginHeader,
        },
        methods: {
            goback() {
                this.$router.go(-1)
            },
            // 获取邮箱验证码
            sendAuthCodeToEmail() {
                let formData = {
                    email: this.account,
                }
                sendAuthCodeToEmail(formData).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(this.$t('user.forgetPsdSendEmail'))
                        this.getCode();
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //  忘记密码提交
            submit() {
                let formData = {
                    account: this.account,
                    authCode: this.code,
                    password: this.password,
                }
                forgetPassword(formData).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.$router.go(-1)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            getCode() {
                //按钮倒计时
                if (this.is_disabled == true) {
                    this.is_disabled = false
                    var second = 60;
                    var temp = setInterval(() => {
                        if (second == 0) {
                            clearInterval(temp);
                            this.content = 'again';
                            this.is_disabled = true
                        } else {
                            second--;
                            this.content = second + 's'
                        }
                    }, 1000)
                }
            }
        },
    }
</script>

<style scoped>
    .container {
        height: 93.5vh;
    }

    .forget_box {
        width: 500px;
        margin-top: 10%;
    }

    .forget_box > h2 {
        font-size: 38px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 44px;
        color: #000000;
    }

    .forget_box .hint_one {
        font-size: 38px;
        font-family: BentonSans;
        line-height: 44px;
        color: #707070;
    }

    .forget_box .hint_two {
        width: 600px;
        font-size: 15px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 18px;
        color: #707070;
        margin-top: 15px;
        margin-bottom: 40px;
    }

    .input_box {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #d6d6d6;
        margin-bottom: 22px;
    }

    .input_box input {
        width: 300px;
    }

    .get_code {
        width: 86px;
        height: 36px;
        background: #000000;
        border-radius: 131px;
        text-align: center;
        line-height: 36px;
        color: #FFFFFF;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
    }

    .confirm_btn {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        margin: 33px auto 0;
        transition: .3s all;
    }

    .confirm_btn:hover {
        background: #000000;
        color: #FFFFFF;
    }

    .backto {
        width: 110px;
        font-size: 15px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 18px;
        color: #000000;
        margin: 12px auto;
        padding-bottom: 2px;
    }

    .backto:hover {
        border-bottom: 1px solid #707070;
    }
</style>
