import request from '../utils/request'

// 上传简历
export function uploadResume(data) {
    return request({
        url: '/common/uploadResume',
        headers: {
            "Content-Type": "multipart/form-data"
        },
        method: 'put',
        data
    })
}

// 我发布的项目、访谈项目-详情页-  VIP订单
export function buyVipService(data) {
    return request({
        url: '/projectMinute/buyVipService',
        method: 'get',
        params: data
    })
}

// 7、我发布的项目、访谈项目-详情页-3、VIP私密推荐A
export function vipPrivateRecommended(data) {
    return request({
        url: '/projectMinute/vipPrivateRecommended',
        method: 'get',
        params: data
    })
}

//项目页：项目类型、项目状态、项目排序
export function projectDictTypeFilter(data) {
    return request({
        url: '/common/projectDictTypeFilter',
        method: 'get',
        params: data
    })
}

//查询公司详情
export function getCompanyDetail(data) {
    return request({
        url: '/company/selectCompanyBasicInfoForMapResultById',
        method: 'get',
        params: data
    })
}

//查询公司详情
export function getCompanyDetail2(data) {
    return request({
        url: '/memberClassify/selectCorporationDetailsById',
        method: 'get',
        params: data
    })
}

// 获取发布项目页面什么是VIP私密推荐内容
export function findVipRecommen() {
    return request({
        url: '/auxiliaryData/findVipRecommen',
        method: 'get',
    })
}

// 获取用户未读推送信息
export function selectNewsOfMemberUnRead(data) {
    return request({
        url: '/homePage/selectNewsOfMemberUnRead',
        method: 'get',
        params: data

    })
}
