import request from '../utils/request'

// 活动公告列表
export function getAnnouncementList(data) {
    return request({
        url: '/announcement/announcementList',
        method: 'get',
        params: data
    })
}

// 活动公告详情
export function getAnnouncementDetail(data) {
    return request({
        url: '/announcement/announcementDetails',
        method: 'get',
        params: data
    })
}

// 会员报名活动
export function registrationActivities(data) {
    return request({
        url: '/announcement/registrationActivities',
        method: 'post',
        data,
    })
}

// 会员报名活动
export function payForActivities(data) {
    return request({
        url: '/announcement/payForActivities',
        method: 'post',
        data,
    })
}
