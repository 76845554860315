import request from '../utils/request'

// 非会员注册
export function nonmemberRegister(data) {
    return request({
        url: '/account/nonmemberRegister',
        method: 'post',
        data
    })
}

// 预注册会员注册
export function preRegisteredMemberRegister(data) {
    return request({
        url: '/account/preRegisteredMemberRegister',
        method: 'post',
        data
    })
}

// 登录
export function memberLogin(data) {
    return request({
        url: '/account/memberLogin',
        method: 'post',
        data
    })
}

//忘记密码
export function forgetPassword(data) {
    return request({
        url: '/account/forgetPassword',
        method: 'post',
        data
    })
}

//忘记密码
export function sendAuthCodeToEmail(data) {
    return request({
        url: '/common/sendAuthCodeToEmail',
        method: 'post',
        data
    })
}

//退出当前登录账户
export function logOutCurrentAccount(data) {
    return request({
        url: '/account/logOutCurrentAccount',
        method: 'post',
        data
    })
}






