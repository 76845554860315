import axios from "axios"
import qs from 'qs';
import {store} from '@/vuex/store'

const baseURL = '/api'  //线上
// const baseURL = '/dev'  //本地


const service = axios.create({
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    baseURL: baseURL,
    timeout: 10000
})

// service.defaults.headers.post['Access-Control-Allow-Headers'] = "x-requested-with,Authorization,token, content-type";

//响应拦截器
service.interceptors.response.use(
    response => {

        if (response.status === 200) {
            if (response.data.code === 300 && store.state.identify == null) {
                window.location.href = '/login'
                console.log("300", response)
                store.dispatch("userLogin", null)
            } else {
                return Promise.resolve(response.data);
            }
        } else {
            return Promise.reject(response.data);
        }
    }
);

service.interceptors.request.use(function (request) {
        request.data = qs.stringify(request.data)
        return request
    }, function (error) {
        return Promise.reject(error)
    }
);

export default service
