import request from '../utils/request'

// 免责声明及会员资格
export function disclaimer(data) {
    return request({
        url: '/member/disclaimerMembership',
        method: 'get',
        params: data
    })
}

// 职称查询条件列表
export function positionList(data) {
    return request({
        url: '/member/getPositionList',
        method: 'post',
        params: data
    })
}

// 地区查询条件列表
export function locationList(data) {
    return request({
        url: '/member/getLocationList',
        method: 'post',
        params: data
    })
}

// 会员页非会员推荐
export function recommend(data) {
    return request({
        url: '/member/memberRecommend',
        method: 'post',
        data
    })
}

// 获取会员列表
export function memberList(data) {
    return request({
        url: '/member/memberList',
        method: 'get',
        params: data
    })
}

// 关注
export function followTalent(data) {
    return request({
        url: '/member/followTalent',
        method: 'post',
        data
    })
}

// 会员免责声明
export function getStatementInfo(data) {
    return request({
        url: '/disclaimer/statement',
        method: 'get',
        data
    })
}

//获取全球交通AI领域 A
export function getAIOfGlobalTraffic(data) {
    return request({
        url: '/memberClassify/getAIOfGlobalTraffic',
        method: 'get',
        params: data
    })
}

//获取全球资本
export function getGlobalCapital(data) {
    return request({
        url: '/memberClassify/getGlobalCapital',
        method: 'get',
        params: data
    })
}

//获取全球院校分部地区
export function getGlobalAcademy(data) {
    return request({
        url: '/memberClassify/getGlobalAcademy',
        method: 'get',
        params: data
    })
}

//获取全球猎头
export function getGlobalExecutiveRecruiters(data) {
    return request({
        url: '/memberClassify/getGlobalExecutiveRecruiters',
        method: 'get',
        params: data
    })
}
