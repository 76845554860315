<!-- 会员分类页面 -->
<template>
    <div class="container">
        <!--    <BreadcrumbNav :pageName="$i18n.locale=='zh'?'会员分类':'huiyuanfenlei'"></BreadcrumbNav>-->
        <BreadcrumbNav :pageName="$t('memberClassification.companiesNumber')"></BreadcrumbNav>
        <div class="body_box" v-show="globalTrafficData.length!=0">
            <h1 class="title">{{$t("memberClassification.globalAi")}}</h1>
            <div class="waterfall">
                <waterfall :col="3" :data="[1,2,3,4,5,6,7,8,9,10,11]">
                    <!--:style="{height:item+ 'px' }"-->
                    <template>
                        <div :key="index" class="item" v-for="(item,index) in globalTrafficData">
                            <div class="item-title">{{$i18n.locale=='zh'? item.industryName:item.industryNameEnglish
                                }}
                            </div>
                            <div class="item-content">
                                <div :key="index" class="ic-1" v-for="(val,index) in item.subClassify">
                                    <p class="name">{{$i18n.locale=='zh'? val.industryName:val.industryNameEnglish
                                        }}</p>
                                    <div class="attr">
                                        <div class="fl">
                                            <span class="key">{{$t("memberClassification.companiesNumber")}}：</span>
                                            <span @click="$router.push({path:'/companyList',query:{id:val.id}})"
                                                  class="value cur">{{ val.companyNum }}</span>
                                        </div>
                                        <div class="fr">
                                            <span class="key">{{$t("memberClassification.peopleNumber")}}：</span>
                                            <span @click="$router.push({path:'/member',query:{id:val.id}})"
                                                  class="value cur">{{ val.personNum }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </waterfall>
            </div>
        </div>
        <!--全球资本-->
        <div class="body_box" v-show="globalCapitalData.length!=0">
            <h1 class="title">{{ $t("memberClassification.globalCapital") }}</h1>
            <div class="waterfall">
                <waterfall :col="3" :data="[1,2,3,4,5,6,7,8,9,10,11]">
                    <template>
                        <div :key="index" class="item" style="margin-bottom: 0"
                             v-for="(item,index) in globalCapitalData">
                            <div class="item-title">{{$i18n.locale=='zh'? item.classifyName:item.classifyNameEnglish}}
                            </div>
                            <div class="item-content">
                                <div class="ic-2">
                                    <div class="attr">
                                        <div class="fl">
                                            <span class="key">{{$t("memberClassification.companiesNumber")}}：</span>
                                            <span @click="$router.push({path:'/companyList',query:{financingId:item.id}})"
                                                  class="value cur">{{item.companyNum}}</span>
                                        </div>
                                        <div class="fr">
                                            <span class="key">{{$t("memberClassification.peopleNumber")}}：</span>
                                            <span @click="$router.push({path:'/member',query:{financingId:item.id}})"
                                                  class="value cur">{{item.personNum}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </waterfall>
            </div>
        </div>

        <!--全球猎头-->
        <div class="body_box" v-show="globalExecutiveRecruitersData.length!=0">
            <h1 class="title">{{ $t("memberClassification.globalHeadhunters") }}</h1>
            <div class="waterfall">
                <waterfall :col="3" :data="[1]">
                    <template>
                        <div :key="index" class="item" v-for="(item,index) in globalExecutiveRecruitersData">
                            <div class="item-title">{{ item.home_country }}</div>
                            <div class="item-content">
                                <p :key="index"
                                   @click="val.companyId?$router.push({path:'/user/companyDetails',query:{id:val.companyId}}):null"
                                   class="lie-tou cur"
                                   v-for="(val,index) in item.companyList">{{ val.companyName }}</p>
                            </div>
                        </div>
                    </template>
                </waterfall>
            </div>
        </div>

        <!--全球院校-->
        <div class="body_box" v-show="globalAcademyData.length!=0">
            <h1 class="title">{{ $t("memberClassification.globalUniversities") }}</h1>
            <div class="waterfall">
                <waterfall :col="3" :data="[1,2,3,4,5,6,7,8,9,10,11]">
                    <template>
                        <div :key="index" class="item" style="margin-bottom: 22px"
                             v-for="(item,index) in globalAcademyData">
                            <div class="item-title">{{$i18n.locale=='zh'? item.country:item.countryEnglishName }}</div>
                            <div class="item-content">
                                <p :key="index"
                                   @click="val.companyId?$router.push({path:'/user/companyDetails',query:{id:val.companyId}}):null"
                                   class="lie-tou cur"
                                   v-for="(val,index) in item.academyList">{{ val.companyName }}</p>
                            </div>
                        </div>
                    </template>
                </waterfall>
            </div>
        </div>

    </div>
</template>

<script>
    import Classify from '@/components/member/classify'
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import {
        getAIOfGlobalTraffic,
        getGlobalAcademy,
        getGlobalCapital,
        getGlobalExecutiveRecruiters,
        memberList
    } from "@/api/memberApi";


    export default {
        name: "classify",
        data() {
            return {
                data: [],
                col: 5,
                globalTrafficData: [],
                globalCapitalData: [],
                globalAcademyData: [],
                globalExecutiveRecruitersData: [],
                globalInvestorData: [],
            }
        },
        computed: {
            itemWidth() {
                // #rem布局 计算宽度
                return (138 * 0.5 * (document.documentElement.clientWidth / 375))
            },
            gutterWidth() {
                // #rem布局 计算x轴方向margin(y轴方向的margin自定义在css中即可)
                return (9 * 0.5 * (document.documentElement.clientWidth / 375))
            }
        },

        mounted() {
            this.getAIOfGlobalTraffic();
            this.getGlobalCapital();
            this.getGlobalAcademy();
            this.getGlobalExecutiveRecruiters();
        },
        methods: {
            scroll(scrollData) {
                console.log(scrollData)
            },
            switchCol(col) {
                this.col = col
                console.log(this.col)
            },
            loadmore(index) {
                this.data = this.data.concat(this.data)
            },
            //获取全球交通AI领域
            getAIOfGlobalTraffic() {
                let formData = {}
                getAIOfGlobalTraffic(formData).then((res) => {
                    if (res.code == 200) {
                        this.globalTrafficData = res.data;
                    }
                })
            },
            //获取全球资本
            getGlobalCapital() {
                let formData = {}
                getGlobalCapital(formData).then((res) => {
                    if (res.code == 200) {
                        this.globalCapitalData = res.data;
                    }
                })
            },
            //获取全球院校分部地区
            getGlobalAcademy() {
                let formData = {}
                getGlobalAcademy(formData).then((res) => {
                    if (res.code == 200) {
                        this.globalAcademyData = res.data;
                    }
                })
            },
            //获取全球猎头
            getGlobalExecutiveRecruiters() {
                let formData = {}
                getGlobalExecutiveRecruiters(formData).then((res) => {
                    if (res.code == 200) {
                        this.globalExecutiveRecruitersData = res.data;
                    }
                })
            },
        },
        components: {
            Classify,
            BreadcrumbNav,
        },

    }

</script>

<style scoped>
    .container {
        min-height: 88vh;
        background-color: #FAFAFA;
    }

    .body_box {
        width: 1200px;
        /*height: 100px;*/
        margin: 24px auto 0;
        background-color: white;
        padding: 40px 67px;
    }

    .body_box .title {
        font-size: 26px;
        font-family: Roboto;
        font-weight: bold;
        line-height: 34px;
        color: #000000;
    }

    .body_box .waterfall {
        width: 100%;
        margin: 30px -13px 0;
        /*margin-top: 30px;*/
        /*border: 1px solid black;*/
        position: relative;
    }

    .body_box .waterfall .item {
        width: 370px;
        margin: 0 13px 38px;
    }

    .body_box .waterfall .item .item-title {
        width: 100%;
        height: 47px;
        background: #FAFAFA;
        border-bottom: 1px solid #707070;

        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 47px;
        color: #000000;
        padding-left: 22px;
        box-sizing: border-box;
    }

    .body_box .waterfall .item .item-content {
        width: 100%;
        padding: 0 22px;
        box-sizing: border-box;
    }

    .body_box .waterfall .item .item-content .lie-tou {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 17px;
        color: #000000;
        margin-bottom: 10px;
    }

    .body_box .waterfall .item .item-content .lie-tou:first-child {
        margin-top: 17px;
    }

    .body_box .waterfall .item .item-content .ic-1 {
        height: 70px;
        width: 100%;
        border-bottom: 1px solid #E5E5E5;
    }

    .body_box .waterfall .item .item-content .ic-2 {
        height: 54px;
        width: 100%;
    }

    .body_box .waterfall .item .item-content .name {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 17px;
        color: #000000;
        margin-top: 17px;
    }

    .body_box .waterfall .item .item-content .attr {
        margin-top: 10px;
        overflow: hidden;
    }

    .body_box .waterfall .item .item-content .attr .key {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 25px;
        color: #949494;
    }

    .body_box .waterfall .item .item-content .attr .value {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 25px;
        color: #000000;
    }

</style>
