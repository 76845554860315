<template>
    <div class="breadcrumb-container">
        <div class="bc-content">
            <ul>
                <li>
                    <div @click="$router.push(`/`)" class="breadcrumb-item">{{ $t("header.home") }}</div>
                </li>
                <li>/</li>
                <li>
                    <div class="breadcrumb-item checked">{{ pageName }}</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BreadcrumbNav",
        props: {
            pageName: {
                type: String,
                default: "未知",
            },
        },
    }
</script>

<style scoped>
    .breadcrumb-container {
        width: 100%;
        height: 66px;
        background: url(../assets/images/topbg@2x.png) no-repeat;
        background-size: cover;
        overflow: hidden;
    }

    .bc-content {
        margin: 0 auto;
        width: 1200px;
        height: 100%;
    }

    ul {
        line-height: 66px;
    }

    ul li {
        float: left;
        margin-right: 5px;
        color: #FFFFFF;
        cursor: pointer;
    }

    .breadcrumb-item {
        color: #949494;
        padding-bottom: 3px;
    }

    .checked {
        border-bottom: 1px solid #FFFFFF;
        color: #FFFFFF;

    }

</style>
