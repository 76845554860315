<template>
    <div class="detail-container">
        <!--吐司导航-->
        <breadcrumb-nav :pageName="$t('activityNotice.eventDetails')"></breadcrumb-nav>
        <!--内容-->
        <div class="main">
            <img :src="activeDetails.event_img_url" alt="" class="d-image">
            <div class="d-content">
                <h1 class="over2">{{activeDetails.event_title}}</h1>
                <p class="text">
                    {{activeDetails.event_intro}}
                </p>
                <div class="address-time">
                    <div class="address">
                        <img alt="" src="@/assets/images/describe_icon1@2x.png">
                        <div class="text-limit over" style="width: 420px;height: 20px">
                            <span>{{activeDetails.event_site}}</span>
                        </div>
                    </div>
                    <div class="datetime">
                        <img alt="" src="@/assets/images/icon_time@2x.png">
                        <span>{{activeDetails.event_time}}</span>
                        <!--            <span>16:31:35</span>-->
                    </div>
                </div>
                <EnrollButton :b-type="0" @click.native="registration(activeDetails.id)" class="btn"
                              v-if="activeDetails.event_status == 1 && activeDetails.is_open_apply ==1"/>
                <EnrollButton :b-type="2" class="btn" v-if="activeDetails.event_status == 2"/>
                <EnrollButton :b-type="3" class="btn" v-if="activeDetails.event_status == 3"/>
                <EnrollButton :b-type="1" class="btn"
                              v-if="activeDetails.event_status == 1 && activeDetails.is_apply == 2"/>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import EnrollButton from "@/components/activityNotice/EnrollButton";
    import {getAnnouncementDetail, registrationActivities} from "@/api/activityApi";

    export default {
        name: "detail",
        data() {
            return {
                butnShow: true,
                activeDetails: []
            }
        },
        components: {
            BreadcrumbNav,
            EnrollButton
        },
        created() {
            this.getDetails();
        },
        methods: {
            //获取详情
            getDetails() {
                let formData = {
                    eventId: this.$route.query.id,
                    token: localStorage.getItem('token')
                }
                getAnnouncementDetail(formData).then((res) => {
                    this.activeDetails = res.data;
                })
            },
            //  活动报名
            registration(id) {
                let formData = {
                    eventId: id,
                    token: localStorage.getItem('token')
                }
                registrationActivities(formData).then((res) => {
                    this.$message.success(res.msg);
                    this.activeDetails.is_apply = 2;
                    this.$forceUpdate();
                })
            }
        }
    }
</script>

<style scoped>
    .detail-container {
        background-color: #FFFFFF;
    }

    .main {
        width: 1200px;
        height: auto;
        margin: 0 auto;
        padding: 71px 0 207px;
        overflow: hidden;
    }

    .d-image {
        float: left;
        width: 695px;
        height: 460px;
        background: rgba(0, 0, 0, 0);
        border-radius: 4px;
    }

    .d-content {
        float: right;
        width: 468px;
        height: 460px;
        position: relative;
    }

    .d-content > h1 {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 46px;
        color: #000000;
    }

    .d-content > .text {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 29px;
        color: #000000;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        overflow: hidden;
        margin-top: 28px;
    }

    .address-time {
        position: absolute;
        left: 0;
        bottom: 98px;
    }

    .address {

    }

    .address > img {
        vertical-align: bottom;

        display: inline-block;
        height: 17px;
        margin-right: 12px;
    }

    .address > .text-limit {
        display: inline-block;
        vertical-align: bottom;

    }

    .address > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .datetime {

        margin-top: 11px;
    }

    .datetime > img {
        vertical-align: bottom;
        display: inline-block;
        height: 16px;
        margin-right: 12px;
        margin-left: 1px;
    }

    .datetime > span {
        vertical-align: bottom;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 12px;
    }

    .btn {
        position: absolute;
        left: 0;
        bottom: 0;
    }
</style>
