<template>
    <div class="container">
        <div class="home_wrap auto">
            <!-- one -->
            <div class="home_one">
                <h2>{{ personMsg.name }}</h2>
                <div class="one_img_box auto">
                    <img :src="personMsg.avatar" alt="" height="100%" width="100%"/>
                    <div class="one_img_msg">
                        <p class="one_img_name">{{ personMsg.company_name }}</p>
                        <p class="one_img_name">{{ personMsg.position }}</p>
                        <span></span>
                        <p class="one_img_id">ID：{{ personMsg.company_id }}</p>
                    </div>
                </div>
                <!--<p class="one_title">{{ personMsg.position }}</p>-->
                <p class="one_title">If we invest you enough money
                    Could you set up your own company overnight?</p>
            </div>
            <!-- two -->
            <div class="home_two">
                <!--  按钮一 -->
                <div @click="popIndustry = true" class="Categories_btn btn_com cur">
                    Categories
                    <img alt="" src="../assets/images/icon_right@2x.png" width="8px">
                </div>
                <!--  按钮二 -->
                <div @click="popVocation = true" class="Financing_btn btn_com cur">
                    Financing
                    <img alt="" src="../assets/images/icon_right@2x.png" width="8px">
                </div>
                <!-- 树状图一图 -->
                <img alt="" class="bg_one" src="../assets/images/icon_line@2x 2.png"/>
                <div class="classify_first_one classify_first">Build Up Team</div>
                <div class="classify_first_two classify_first">Financing</div>
                <!-- 树状图一 -->
                <ul class="classify_second_one classify_second">
                    <li :class="[{classify_second_active : item.id == functionClassifyId ,fl:true,cur:true}]"
                        :key="index"
                        @click="changeTabOne(index,1,item)"
                        v-for="(item,index) in functionList">
                        <p>{{ item.function_name }}</p>
                    </li>
                </ul>
                <!-- 树状图二  -->
                <ul class="classify_second_two classify_second">
                    <li :class="[{classify_second_active : item.id == functionClassifyId ,fl:true,cur:true}]"
                        :key="index"
                        @click="changeTabOne(index,2, item)"
                        v-for="(item,index) in financingPersonList"
                    >
                        <p>{{ item.function_name }}</p>
                    </li>
                </ul>
                <!-- 树状图三图 -->
                <img alt="" class="bg_two" src="../assets/images/icon_line@2x 4.png">
                <!-- 树状图三 -->
                <ul class="classify_second_three classify_second">
                    <li :class="[{classify_second_active : item.id == functionClassifyId ,fl:true,cur:true}]"
                        :key="index"
                        @click="changeTabOne(index,3, item)"
                        v-for="(item,index) in subClassify">
                        <p>{{ item.function_name }}</p>
                    </li>
                </ul>
            </div>
            <!-- three照片墙-->
            <div class="home_three">
                <div @scroll="scrollPhoto" class="home_three_list" ref="scrollDiv">
                    <div :key="index"
                         @click="$router.push({
           path: '/user/index',query:{
             userType:2,
             talentId:item.id,
           }})" class="home_three_item fl cur"
                         v-for="(item,index) in photoWallList">
                        <img :src="item.avatar" alt="" height="110px" width="110px">
                        <p class="over">
                            {{ item.name }}
                        </p>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="three_btn_box auto">
                    <div @click="$router.push('/project/addProject?projectIndex=1')" class="fl three_btn cur">{{
                        $t("home.findTalents") }}
                    </div>
                    <div @click="$router.push('/project/addProject?projectIndex=2')" class="fl three_btn cur">{{
                        $t("home.findMoney") }}
                    </div>
                    <div @click="$router.push('/project/addProject?projectIndex=3')" class="fl three_btn cur">{{
                        $t("home.findWisdom") }}
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
            <!-- four底部文章 -->
            <div class="home_four">
                <div class="home_four_box">
                    <img :src="bottomText.image" alt="" class="logo">
                    <p class="title">{{ bottomText.domainName }}</p>
                    <div class="content"
                         v-html="$i18n.locale=='zh'?bottomText.content : bottomText.contentEnglish"></div>
                </div>
            </div>
            <!-- 融资分类 -->
            <div class="pop_bg" v-if="popVocation">
                <div class="pop_vocation auto">
                    <p class="close_btn"><img @click="popVocation = false" alt=""
                                              src="../assets/images/icon_closed@2x.png"></p>
                    <h2>Classification</h2>
                    <p class="pop_hint_one">{{ $t("home.categories") }}</p>
                    <p class="pop_hint_two">{{ $t("home.selectUp") }}</p>
                    <div class="pop_choose">
                        <ul>
                            <li :key="item.id" @click="goMember(item.id)" class="cur" v-for="(item) in financingList">
                                {{ $i18n.locale=='zh'? item.classifyName:item.classifyNameEnglish }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- 行业分类 -->
            <div class="pop_bg" v-if="popIndustry">
                <div class="pop_vocation auto">
                    <p class="close_btn"><img @click="popIndustry = false" alt=""
                                              src="../assets/images/icon_closed@2x.png"></p>
                    <h2>Classification</h2>
                    <p class="pop_hint_one">{{ $t("home.categories") }}</p>
                    <p class="pop_hint_two">{{ $t("home.selectUp") }}</p>
                    <div class="industry_choose">
                        <!--<div class="industry_pop_item" v-if="" v-for="(item) in industryList" :key="item.id">
                          <h2>{{ $i18n.locale=='zh'? item.industryName:item.industryNameEnglish }}</h2>
                          <ul>
                            <li v-for="(a) in item.subClassify" class="cur" :key="a" @click="goMember(a.id)">
                              {{ $i18n.locale=='zh'? a.industryName:a.industryNameEnglish }}
                            </li>
                          </ul>
                        </div>-->
                        <ul>
                            <li :key="item.id" @click="goMember(item.id)" class="cur" v-for="(item) in industryList">
                                {{ $i18n.locale=='zh'? item.industryName:item.industryNameEnglish }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!--<div class="kefu">
          <div class="avatar">
            <img src="@/assets/images/BrowserPreview_tmp@2x.png" style="width: 100%;height: 100%" alt="">
            <div class="isOnline"></div>
          </div>
          <p class="name">Reyna Chung</p>
          <p class="state">Reyna Chung</p>
          <p class="text">Welcome to you! what do you want we to do?</p>
        </div>-->
    </div>
</template>
<script>
    import {
        industryClassify,
        financingClassify,
        positionAndFinancerClassify,
        copyInfo,
        selectPreRegistrationInfo,
        photoWall, homePageCopyInfo, queryByIdentify
    } from "@/api/homeApi";
    import getUrlQuery from "@/utils/getUrlQuery";

    export default {
        data() {
            return {
                isMore: false,
                popVocation: false,
                popIndustry: false,
                industryList: [],//行业分类列表
                industryCount: '',
                financingList: [],//融资分类列表
                financingCount: '',
                functionList: [],//职能列表
                functionCount: '',
                functionTab: '0',
                financingPersonList: [],//融资人员类型列表
                financingPersonCount: '',
                financingPersonTab: 0,
                financingPersonThreeTab: 0,
                subClassify: [],//三级分类列表
                homeMsg: [],
                personMsg: [],//顶部用户信息
                functionClassifyId: '',//职能id
                photoWallList: [],//照片墙
                //登陆的id
                formData: {
                    identify: '1'
                },
                pageSize: 90,
                pageNum: 1,
                bottomText: "",
            };
        },

        methods: {
            goMember(name) {
                this.$router.push({
                    path: "/member",
                    query: {
                        functionName: name,
                    }
                });
            },
            //  行业父子分类
            getIndustryClassify() {
                let formData = {}
                industryClassify(formData).then((res) => {
                    this.industryList = res.data.list;
                    this.industryCount = res.data.total;
                })
            },

            //  融资分类
            getFinancingClassify() {
                let formData = {}
                financingClassify(formData).then((res) => {
                    this.financingList = res.data.list;
                    this.financingCount = res.data.total;
                })
            },
            //  底部文案
            getHomePageCopyInfo() {
                let formData = {}
                homePageCopyInfo(formData).then((res) => {
                    this.bottomText = res.data;
                })
            },

            //职称分类、融资人员分类
            positionAndFinancerClassify(type) {
                let formData = {
                    categoryType: type
                }
                positionAndFinancerClassify(formData).then((res) => {
                    if (type == 1) {
                        res.data.list.splice(10);
                        this.functionList = res.data.list;
                        this.functionCount = res.data.total;
                        this.subClassify = this.functionList[5].subClassify.slice(0, 4);
                        //职能id 复制
                        this.functionClassifyId = this.functionList[0].id;
                        this.getPhotoWall();
                    } else {
                        res.data.list.splice(3);
                        this.financingPersonList = res.data.list;
                        this.financingPersonCount = res.data.total;
                    }
                })
            },

            //照片墙
            getPhotoWall(isRefresh) {
                if (isRefresh) {
                    this.pageNum = 1;
                    this.pageSize = 90;
                }
                let formData = {
                    functionClassifyId: this.functionClassifyId,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                }
                photoWall(formData).then((res) => {
                    if (res.code == 200) {
                        if (isRefresh) {
                            this.photoWallList = this.photoWallList = res.data.list;
                        } else {
                            this.photoWallList = this.photoWallList.concat(res.data.list);
                        }
                        this.getUserMsg();
                    } else {
                        this.$message.error(res.msg)
                    }

                })
            },

            //
            scrollPhoto(e) {
                e.preventDefault()
                this.photoWallList.splice(0, 10)
                this.pageNum++;
                this.pageSize = 10;
                this.getPhotoWall();
            },

            //首页文案信息
            getHomeMsg() {
                let formData = {}
                copyInfo(formData).then((res) => {
                    this.homeMsg = res.data;
                })
            },

            //专属链接调用接口，获取用户基本信息
            getUserMsg() {
                let fromData = {
                    identify: this.$route.query.identify,
                    token: localStorage.getItem('token'),
                };
                selectPreRegistrationInfo(fromData).then((res) => {
                    if (res.code == 200) {
                        this.personMsg = res.data;
                    } else {
                        //随机数
                        const random = Math.ceil(Math.random() * 10);
                        this.personMsg = this.photoWallList[random];
                    }
                })
            },

            //切换职称分类
            changeTabOne(index, type, item) {
                this.functionClassifyId = item.id;
                if (type == 1) {
                    this.functionTab = index;
                    this.getPhotoWall(true);
                } else if (type == 2) {
                    this.position = index;
                    this.getPhotoWall(true);
                } else if (type == 3) {
                    this.financingPersonThreeTab = index;
                    this.getPhotoWall(true);
                }
            },

            gopage(url) {
                this.$router.push(url)
            }

        },
        created() {
            this.$store.commit("identifyStatus", getUrlQuery.getUrlKey('identify'))
            this.$emit("publicHeader", true);
        },
        mounted() {
            this.getIndustryClassify();
            this.getFinancingClassify();
            this.positionAndFinancerClassify(1);
            this.positionAndFinancerClassify(2);
            // this.getPhotoWall();
            this.getHomeMsg();
            this.getHomePageCopyInfo();
        }
    };
</script>

<style lang="css" scoped>
    .kefu {
        position: fixed;;
        width: 355px;
        height: 120px;
        background: #000000;
        opacity: 1;
        right: 0;
        top: 65vh;
    }

    .kefu .avatar {
        position: absolute;
        top: -60px;
        left: -20px;
        width: 131px;
        height: 131px;
        border-radius: 181px;
    }

    .kefu .avatar .isOnline {
        position: absolute;
        top: 102px;
        right: 8px;
        width: 18px;
        height: 18px;
        background: #62B492;
        border-radius: 50%;
    }

    .kefu .name {
        font-size: 19px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 26px;
        color: #FFFFFF;
        margin-left: 129px;
        margin-top: 14px;
    }

    .kefu .state {
        font-size: 14px;
        font-family: BentonSans;
        font-weight: normal;
        line-height: 26px;
        color: #949494;
        margin-left: 129px;
        margin-top: 11px;
    }

    .kefu .text {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 26px;
        color: #FFFFFF;
        margin-left: 18px;
        margin-top: 12px;
    }

    .home_wrap {
        width: 1200px;
    }

    .home_one {
        padding-top: 30px;
    }

    .home_one h2 {
        text-align: center;
        font-size: 68px;
        font-family: Playfair Display;
        font-weight: bold;
        line-height: 91px;
        color: #000000;
    }

    .one_img_box {
        width: 275px;
        height: 274px;
        border-radius: 20px;
        margin-top: 16px;
        position: relative;
    }

    .one_img_box img {
        border-radius: 20px;
    }

    .one_img_msg {
        position: absolute;
        top: 122px;
        left: -244px;
        width: 308px;
        height: 121px;
        background: #000000;
        opacity: 0.74;
        border-radius: 4px;
        color: #ffffff;
        /*padding: 9px 5px;*/
        box-sizing: border-box;
        text-align: center;
    }

    .one_img_name {
        font-size: 18px;
        font-family: BentonSans;
        font-weight: 500;
        color: #ffffff;
        margin-top: 19px;

    }

    .one_img_name:nth-child(2) {
        margin-top: 13px;

    }

    .one_img_msg span {
        display: block;
        width: 45px;
        height: 0px;
        border: 1px solid #707070;
        margin: 11px auto 7px;
    }

    .one_img_id {
        font-size: 13px;
        font-family: BentonSans;
        font-weight: normal;
        color: #ffffff;
    }

    .one_title {
        width: 718px;
        font-size: 33px;
        font-family: Playfair Display;
        font-weight: bold;
        line-height: 44px;
        color: #000000;
        text-align: center;
        margin: 19px auto 20px;
    }

    /* two */
    .home_two {
        width: 1200px;
        height: 705px;
        margin-bottom: 60px;
        position: relative;
    }

    .bg_one {
        position: absolute;
        top: 0;
        left: 42px;
        width: 1115px;
    }

    .classify_first {
        position: absolute;
        width: 190px;
        height: 50px;
        background: #000000;
        border-radius: 4px;
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        font-family: Roboto;
        font-weight: 500;
        color: #ffffff;
    }

    .classify_first_one {
        top: 49px;
        left: 113px;
    }

    .classify_first_two {
        top: 49px;
        right: 113px;
    }

    .classify_second_one {
        position: absolute;
        top: 270px;
    }

    .classify_second li {
        width: 85px;
        height: 110px;
        background: #fafafa;
        border-radius: 4px;
        margin-right: 5px;
        display: table;
        padding: 0 5px;
        box-sizing: border-box;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 14px;
        color: #000000;
        word-wrap: break-word;
    }

    .classify_second li p {
        vertical-align: middle;
        display: table-cell;
        max-width: 70px;
        word-wrap: break-word;
    }

    .classify_second_two {
        position: absolute;
        right: 0;
        top: 270px;
    }

    .classify_second_active {
        width: 85px;
        height: 174px;
        background: #000000 !important;
        border-radius: 4px;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 14px;
        color: #FFFFFF !important;
    }

    .classify_second_three {
        position: absolute;
        left: 313px;
        top: 460px;
    }

    .bg_two {
        position: absolute;
        top: 380px;
        left: 352px;
        width: 280px;
    }

    /* three */
    .home_three {

    }

    .home_three_list {
        height: 960px;
        overflow-y: scroll;
    }

    .home_three_list::-webkit-scrollbar {
        display: none;;
    }

    .home_three_item {
        width: 110px;
        height: 110px;
        position: relative;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .home_three_item:nth-child(10n) {
        margin-right: 0;
    }

    .home_three_item p {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        line-height: 26px;
        height: 26px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 100%);
        border-radius: 0px 0px 4px 4px;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        color: #FFFFFF;
    }

    .three_btn_box {
        width: 580px;
        margin-top: 60px;
    }

    .three_btn {
        width: 160px;
        height: 50px;
        background: linear-gradient(180deg, #000000 0%, #232321 100%);
        border-radius: 131px;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        font-family: BentonSans;
        font-weight: 500;
        color: #FFFFFF;
        margin-right: 50px;
    }

    .three_btn:nth-child(3) {
        margin-right: 0;
    }

    /* four */
    .home_four {
        margin-top: 158px;
        margin-bottom: 168px;
    }

    .home_four_box {
        position: relative;
    }

    .home_four_box img {
        margin: 0 auto;
        display: block;
        width: 142px;
        height: 128px;
    }

    .home_four_box .title {
        font-size: 26px;
        font-family: Playfair Display;
        font-weight: bold;
        color: #000000;
        text-align: center;
        margin-top: 5px;
    }

    .home_four_box .content {
        width: 1200px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 300;
        line-height: 26px;
        color: #000000;
        margin-top: 42px;
    }

    .four_text {
        position: absolute;
        right: 0;
        top: 72px;
        width: 764px;
        height: 367px;
        background: #000000;
        border: 1px solid #707070;
        color: #FFFFFF;
        padding: 56px 50px 0;
        box-sizing: border-box;
    }

    .four_text h2 {
        font-size: 20px;
        font-family: BentonSans;
        font-weight: 900;
        line-height: 23px;
        color: #FFFFFF;
        margin-bottom: 30px;
    }

    .four_text p {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 300;
        line-height: 26px;
        color: #FFFFFF;
    }

    .four_text div {
        width: 112px;
        height: 49px;
        background: #000000;
        border: 1px solid #949494;
        border-radius: 25px;
        text-align: center;
        line-height: 49px;
        box-sizing: border-box;
        margin-top: 26px;
    }

    .btn_com {
        width: 204px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 4px;
        line-height: 50px;
        padding: 0 25px;
        box-sizing: border-box;
        position: absolute;
        z-index: 2;
    }

    .btn_com img {
        float: right;
        margin-top: 19px;
    }

    .Categories_btn {
        top: 136px;
        left: 243px;
    }

    .Financing_btn {
        top: 136px;
        right: 243px;
    }

    .pop_bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.22);
        z-index: 3;
    }

    .pop_vocation {
        width: 460px;
        height: 80vh;
        background: #FFFFFF;
        border-radius: 8px;
        margin-top: 5%;
        padding: 23px;
        box-sizing: border-box;
    }

    .close_btn {
        text-align: right;
    }

    .close_btn img {
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    .pop_vocation > h2 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
        text-align: center;
        margin-bottom: 9px;
    }

    .pop_vocation .pop_hint_one {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #707070;
        text-align: center;
    }

    .pop_hint_two {
        padding-left: 46px;
        box-sizing: border-box;
        margin-top: 28px;
    }

    .pop_choose {
        margin-top: 30px;
        margin-left: 80px;
        overflow-y: scroll;
        height: 75%;
        background: #FFFFFF;
        border-radius: 8px;
        line-height: 50px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 600;
        color: #000000;
    }

    .industry_choose {
        margin-top: 20px;
        margin-left: 46px;
        line-height: 40px;
        overflow-y: scroll;
        height: 75%;
    }

    .industry_choose h2 {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 900;
        color: #000000;
    }

    .industry_choose li {
        margin-left: 30px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 600;
        color: #000000;
    }
</style>
