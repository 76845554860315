<template>
    <div class="container">
        <loginHeader></loginHeader>
        <div class="sign_box auto">
            <h2>Sign in</h2>
            <p>Don't have an account? Sign up here.</p>
            <div class="input_boxa">
                <input placeholder="Your email" type="text" v-model="account"/>
            </div>
            <div class="input_boxa">
                <input placeholder="Password" type="password" v-model="password"/>
                <span @click="gopage('/forgetPsd')" class="fr cur">Lost your password?</span>
            </div>
            <div class="remember_box">
                <img
                        @click="isChoose = true"
                        alt=""
                        class="cur"
                        height="16px"
                        src="../../assets/images/radio_n@2x.png"
                        v-if="!isChoose"
                        width="16px"
                />
                <img
                        @click="isChoose = false"
                        alt=""
                        class="cur"
                        height="16px"
                        src="../../assets/images/radio_s@2x.png"
                        v-else
                        width="16px"
                />
                <span>Remember me</span>
            </div>
            <!-- 登录 -->
            <div @click="loginIn" class="login_btn cur">sign in</div>
            <!-- 三方登录 -->
            <div class="three_login">
                <p>Continue with your social network:</p>
                <img @click="linkedinLogin()" alt="" class="cur" src="../../assets/images/logoin_sfdl1@2x.png"
                     width="18px"/><!--领英-->
                <img @click="login('twitter')" alt="" class="cur" src="../../assets/images/logoin_sfdl2@2x.png"
                     width="18px"/><!--Twitter-->
                <img @click="login('facebook')" alt="" class="cur" src="../../assets/images/logoin_sfdl3@2x.png"
                     width="18px"/><!--Facebook-->
            </div>
        </div>

        <el-dialog :visible.sync="dialogFormVisible" title="绑定邮箱" width="600px">
            <el-form>
                <el-form-item label="邮箱" label-width="60px">
                    <el-input v-model="twitterMail"></el-input>
                </el-form-item>
            </el-form>
            <div class="dialog-footer" slot="footer">
                <el-button @click="loginByTwitter" type="info">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import loginHeader from "../../components/login/loginHeader";
    import {memberLogin} from "@/api/loginApi";
    import {loginByLinkedin, loginByTwitter, loginByFacebook} from "@/api/threeLoginApi.js";
    //import axios from "axios";
    import {Loading} from "element-ui";
    import hello from 'hellojs/dist/hello.all';

    let loadingInstance = null;
    export default {
        name: "login",
        data() {
            return {
                isChoose: false,
                account: '',
                password: '',
                facebookData: {},
                dialogFormVisible: false,
                twitterMail: "",
                twitterUserId: "",
                twitterUserName: "",
                twitterAvatar: "",
            };
        },
        components: {
            loginHeader,
        },
        created() {
            let account = localStorage.getItem("account");
            if (account) {
                this.isChoose = true
                this.account = account;
            } else {
                this.isChoose = false
            }
        },
        methods: {
            gopage(url) {
                this.$router.push(url)
            },
            // 登录
            loginIn() {
                let formData = {
                    account: this.account,
                    password: this.password
                }
                loadingInstance = Loading.service({
                    text: this.$i18n.locale == 'zh' ? '正在登录' : 'Logging in',
                    // spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                memberLogin(formData).then((res) => {
                    loadingInstance.close();

                    if (res.code == 200) {
                        if (this.isChoose) {
                            localStorage.removeItem('account');
                            localStorage.setItem('account', this.account);
                        } else {
                            localStorage.removeItem('account');
                        }
                        this.$store.dispatch("userLogin", res.data)
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.$router.replace('/')
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },

            //领英登录
            linkedinLogin() {
                var url = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=780zfar2d5gddp' +
                    '&redirect_uri=https://www.blessboss.com/login&scope=r_liteprofile%20r_emailaddress%20w_member_social';
                window.open(url, "_blank");
            },

            //领英回调
            linkedinLoginCode() {
                console.log("lingkeind code = " + this.$route.query.code)
                let linkedinData = {
                    code: this.$route.query.code,
                }
                loginByLinkedin(linkedinData).then((res) => {
                    if (res.code == 200) {
                        this.$store.dispatch("userLogin", res.data)
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.$router.replace('/')
                    } else {
                        this.$message({
                            message: "Error: authorization login failed",
                            type: 'error'
                        });
                    }
                })
            },
            login: function (network) {
                var this_com = this;
                hello(network).login().then((response) => {
                    // console.log("=============response=============");
                    // console.log(response);
                    if (response.network == "twitter") {
                        this_com.dialogFormVisible = true;
                        this_com.twitterUserId = response.authResponse.user_id;
                        this_com.twitterUserName = response.authResponse.screen_name;
                    }
                    if (response.network == "facebook") {
                        hello(network).api("me").then(function (json) {
                            // console.log("=============memememememe=============");
                            // console.log(json)
                            this_com.facebookData = {
                                userId: json._id,
                                userName: json.name,
                                avatar: json.picture,
                                email: json.email,
                            };
                            loginByFacebook(this_com.facebookData).then((res) => {
                                // console.log("=============resresresresresresres=============");
                                // console.log(res)
                                if (res.code == 200) {
                                    this_com.$store.dispatch("userLogin", res.data)
                                    this_com.$message({
                                        message: res.msg,
                                        type: 'success'
                                    });
                                    this_com.$router.replace('/')
                                } else {
                                    this.$message({
                                        message: "Error: authorization login failed",
                                        type: 'error'
                                    });
                                }
                            });
                        });
                    }
                }, (e) => {
                    console.log(e);
                });
            },
            //Twitter绑定邮箱登录
            loginByTwitter() {
                let twitterData = {
                    userId: this.twitterUserId,
                    userName: this.twitterUserName,
                    avatar: this.twitterAvatar,
                    email: this.twitterMail,
                };
                // console.log("=============twitter=============");
                // console.log(twitterData)
                loginByTwitter(twitterData).then((res) => {
                    // console.log("=============twitterDatatwitterDatatwitterData=============");
                    // console.log(res)
                    if (res.code == 200) {
                        this.$store.dispatch("userLogin", res.data)
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.dialogFormVisible = false;
                        this.$router.replace('/')
                    } else {
                        this.$message({
                            message: "Error: authorization login failed",
                            type: 'error'
                        });
                    }
                })
            },

        },
        mounted() {
            if (this.$route.query.code) {
                this.linkedinLoginCode();
            }
            hello.init({
                facebook: '1350761151942887',
                twitter: 'mpaIzelt3PHMYcdXsC3NS6Y5O',
            }, {redirect_uri: 'https://www.blessboss.com/login'}, {scope: 'email'});
        }
    };
</script>

<style lang="css" scoped>
    .container {
        height: 93.5vh;
    }

    .sign_box {
        width: 500px;
        margin-top: 10%;
    }

    .sign_box > h2 {
        text-align: center;
        font-size: 38px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 44px;
        color: #000000;
    }

    .sign_box > p {
        text-align: center;
        font-size: 15px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 18px;
        color: #707070;
        margin-top: 14px;
        margin-bottom: 35px;
    }

    .input_boxa {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #d6d6d6;
        margin-bottom: 25px;
    }

    .input_boxa input {
        width: 300px;
    }

    .input_boxa span {
        font-size: 15px;
        font-family: Roboto;
        font-weight: 400;
        color: #000000;
    }

    .remember_box span {
        font-size: 15px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 18px;
        color: #000000;
        padding-left: 7px;
    }

    .login_btn {
        width: 140px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 50px;
        box-sizing: border-box;
        margin: 24px auto 44px;
        transition: .3s all;
    }

    .login_btn:hover {
        color: #FFFFFF;
        background: #000000;
    }

    .three_login {
        text-align: center;
    }

    .three_login img {
        margin-right: 14px;
        margin-top: 28px;
    }

    .three_login img:nth-child(4) {
        margin-right: 0;
    }
</style>
