import request from '../utils/request'

// 行业父子分类
export function industryClassify(data) {
    return request({
        url: '/homePage/industryClassify',
        method: 'get',
        data
    })
}

// 行业父子分类
export function financingClassify(data) {
    return request({
        url: '/homePage/financingClassify',
        method: 'get',
        data
    })
}

// 职称分类、融资人员分类
export function positionAndFinancerClassify(data) {
    return request({
        url: '/homePage/positionAndFinancerClassify',
        method: 'get',
        params: data
    })
}

// 首页文案信息
export function copyInfo(data) {
    return request({
        url: '/homePage/copyInfo',
        method: 'get',
        params: data
    })
}

// 专属链接调用接口，获取用户基本信息
export function selectPreRegistrationInfo(data) {
    return request({
        url: '/homePage/selectPreRegistrationInfo',
        method: 'get',
        params: data
    })
}

// 首页照片墙
export function photoWall(data) {
    return request({
        url: '/homePage/photoWall',
        method: 'get',
        params: data
    })
}


//我的钱包-账号信息接口
export function findPcAccountInfo(data) {
    return request({
        url: '/myOrder/findPcAccountInfo',
        method: 'post',
        data
    })
}

//我的钱包-账户设置
export function updatePcAccountInfo(data) {
    return request({
        url: '/myOrder/updatePcAccountInfo',
        method: 'post',
        data
    })
}

//我的钱包-列表
export function findOrderList(data) {
    return request({
        url: '/myOrder/findOrderList',
        method: 'post',
        params: data
    })
}

//我的钱包-列表
export function homePageCopyInfo(data) {
    return request({
        url: '/homePage/copyInfo',
        method: 'get',
        params: data
    })
}

//获取专属链接信息
export function queryByIdentify(data) {
    return request({
        url: '/homePage/queryByIdentify',
        method: 'get',
        params: data
    })
}
