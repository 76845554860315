<template>
    <div :class="[btnClass]"
         class="enroll-button">{{ name }}
    </div>
</template>

<script>
    export default {
        name: "EnrollButton",
        data() {
            return {}
        },
        computed: {
            name() {
                switch (this.bType) {
                    case 0:
                        return this.$t('activityNotice.iWantToSignUp');//我要报名
                    case 1:
                        return this.$t('activityNotice.enrolled');//已报名
                    case 2:
                        return this.$t('activityNotice.enrollDead');//报名截止
                    case 3:
                        return this.$t('activityNotice.enrollEnd');//报名结束
                    case 4:
                        return this.$t('activityNotice.payed');//已缴费
                    case 5:
                        return this.$t('activityNotice.payImmediately');//立即缴费
                    default:
                        return "";
                }
            },
            btnClass() {
                if (this.bType === 1 || this.bType === 4) {
                    return "btn-type1";
                } else if (this.bType === 2 || this.bType === 3) {
                    return "btn-type2";
                } else {
                    return ""
                }
            }

        },
        props: {
            bType: {
                type: Number,
                default: 0,
                required: true
            },
        },
    }
</script>

<style scoped>
    .enroll-button {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 50px;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        cursor: pointer;
    }

    .btn-type1 {
        /*border: 1px solid #FAFAFA;*/
        color: #000000;
        cursor: default;
    }

    .btn-type2 {
        border: 1px solid #F1F1F1;
        color: #949494;
        cursor: default;

    }
</style>
