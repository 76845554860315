<template>
    <div class="footer">
        <div class="footer_box auto">
            <!--About us-->
            <div class="fl">
                <h1 class="title">{{$i18n.locale=='en'?"About us":"关于我们"}}</h1>
                <div class="line"></div>
                <p class="content" style="margin-top: 16px" v-if="$i18n.locale=='en'">Microhunter Solomon Consulting Pvt
                    Ltd</p>
                <p class="content" style="margin-top: 16px" v-else>内蒙古微聘人力资源管理顾问有限公司</p>
            </div>
            <!--Contact us-->
            <div class="fr">
                <h1 class="title">{{$i18n.locale=='en'?"Contact us":"联系我们"}}</h1>
                <div class="line"></div>
                <div style="margin-top:16px;">
                    <div class="fl">
                        <p class="content">Email: eldhoskaria.safe@gmail.com</p>
                        <p class="content">Email: lucy.microhunter@outlook.com</p>
                    </div>
                    <div class="fl" style="margin-left: 75px">
                        <p class="content">Contact and Whatsapp: +919496763368</p>
                        <p class="content">Contact and Whatsapp: +8618351951090</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import {customToDate,dDateValue} from "@/utils/dateUtils"
    export default {
        name: "showFooter",
        mounted() {
            // console.log("hahah",customToDate("15/10/2021 10:45:00"))
            // dDateValue(customToDate("15/10/2021 10:45:00"),customToDate("15/10/2021 12:45:00"));
        }
    }
</script>

<style scoped>
    .footer {
        width: 100%;
        height: 157px;
        line-height: 66px;
        background: #000000;
        margin-top: 50px;
    }

    .footer_box {
        width: 996px;
        font-size: 14px;
        font-family: BentonSans;
        font-weight: normal;
        color: #FFFFFF;
        padding-top: 38px;
    }

    .title {
        font-size: 14px;
        font-family: BentonSans;
        line-height: 26px;
        color: #FFFFFF;
    }

    .line {
        width: 25px;
        height: 0px;
        border: 2px solid #707070;
        margin-top: 9px;
    }

    .content {
        font-size: 12px;
        font-family: BentonSans;
        line-height: 26px;
        color: #FFFFFF;
    }

</style>
