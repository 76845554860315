<template>
    <div class="container">
        <loginHeader></loginHeader>
        <div class="recommend_nav auto">
            <span :class="{cur:true,recommend_nav_active:isTabs == 1}" @click="isTabs = 1">{{ $t("recommendMember.recommendMember") }}</span>
            <span :class="{cur:true,recommend_nav_active:isTabs == 2}" @click="isTabs = 2">{{ $t("bulletFrame.recommendNonMembers") }}</span>
        </div>
        <!--  推荐会员  -->
        <div class="member_box auto" v-if="isTabs == 1">
            <div class="member_title">
                <span>*</span>
                {{ $t("publish.phone") }}
            </div>
            <!-- <div class="input_box_number">
              <span>+86</span>
              <input type="text" v-model="mobileNumber" :placeholder="$t('publish.input')">
            </div> -->
            <div>
                <div style="display: inline-block;">
                    <el-select filterable style="width:150px !important;height:50px;" v-model="mobileCode">
                        <el-option :key="item.id"
                                   :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                                   :value="item.code" v-for="item in areaList.phoneCountriesCodeList">
                        </el-option>
                    </el-select>
                </div>
                <div class="input_box_number" style="display: inline-block;width:390px;">
                    <!--<span style="position:absolute;" v-if="mobileCode">+{{mobileCode}}</span>-->
                    <input :placeholder="$t('publish.input')" style="width:250px;" type="text" v-model="mobileNumber">
                </div>
            </div>
            <div class="member_title">
                <span>*</span>
                {{ $t("publish.mailbox") }}
            </div>
            <div class="input_box_email">
                <input :placeholder="$t('publish.input')" type="text" v-model="mailBox">
            </div>
            <div class="member_title" style="margin-left: 10px">
                <span>* </span>WhatsApp
            </div>
            <div style="display: inline-block;">
                <el-select filterable style="width:150px !important;height:50px;" v-model="whatsappCode">
                    <el-option :key="item.id" :label="formatCode(item.code, item.countrysEnglish, item.countrysChinese)"
                               :value="item.code"
                               v-for="item in areaList.phoneCountriesCodeList">
                    </el-option>
                </el-select>
            </div>
            <div class="input_box_number" style="display: inline-block;width:390px;">
                <!--<span style="position:absolute;" v-if="whatsappCode">+{{whatsappCode}}</span>-->
                <input :placeholder="$t('publish.input')" style="width:250px;" type="text" v-model="whatsApp1">
            </div>
            <!-- <div class="input_box_email">
              <input type="text" v-model="whatsApp" :placeholder="$t('publish.input')">
            </div> -->
            <div class="input_box_search">
                <input placeholder="sitek" type="text" v-model="memberName">
                <div @click="getMemberListByName()" class="fr search_btn cur">
                    <img alt="" src="../assets/images/icon_search@2x.png" width="18">
                </div>
            </div>
            <div :key="index" class="person_item" v-for="(item,index) in memberList">
                <div @click="()=>{item.isChecked=!item.isChecked;$forceUpdate()}" class="choose_box fl cur">
                    <img :key="item.member_id" alt="" src="../assets/images/radio_n@2x.png" v-if="!item.isChecked"
                         width="16px">
                    <img :key="item.member_id" alt="" src="../assets/images/radio_s@2x.png" v-if="item.isChecked"
                         width="16px">
                </div>
                <div class="fl person_msg">
                    <div class="person_msg_one">
                        <img :src="item.avatar" alt="" class="fl">
                        <div class="fl">
                            <h2>{{ item.member_name }}</h2>
                            <p>{{ item.company_name }} · {{ item.position }}</p>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="person_msg_two">
                        <div class="person_msg_title fl">
                            <span>*</span>
                            {{ $t("publish.phone") }}
                        </div>
                        <div>
                            <div style="display: inline-block;">
                                <el-select @change="changeWhatsApp" filterable
                                           style="width:100px !important;height:50px;" v-model="item.mobileCode">
                                    <el-option :key="itemTwo.id"
                                               :label="formatCode(itemTwo.code, itemTwo.countrysEnglish, itemTwo.countrysChinese)"
                                               :value="itemTwo.code" v-for="itemTwo in areaList.phoneCountriesCodeList">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="input_box_number" style="display: inline-block;width:290px;">
                                <!--<span style="position:absolute;" v-if="item.mobileCode">+{{item.mobileCode}}</span>-->
                                <input :placeholder="$t('publish.input')" style="width:150px;" type="text"
                                       v-model="item.mobileNumber">
                            </div>
                        </div>
                        <!-- <div class="person_input_number fl">
                          <span>+86</span>
                          <input type="text" v-model="item.mobileNumber" :placeholder="$t('publish.input')">
                        </div> -->
                        <!-- <div class="clearfix"></div> -->
                    </div>
                    <div class="person_msg_two">
                        <div class="person_msg_title fl">
                            <span>*</span>
                            {{ $t("publish.mailbox") }}
                        </div>
                        <div class="person_input_email fl">
                            <input :placeholder="$t('publish.input')" type="text" v-model="item.mailBox">
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="person_msg_two">
                        <div class="person_msg_title fl">
                            <span>*</span>WhatsApp
                        </div>
                        <div style="display: inline-block;">
                            <el-select @change="changeWhatsApp" filterable style="width:100px !important;height:50px;"
                                       v-model="item.whatsappCode">
                                <el-option :key="itemTwo.id"
                                           :label="formatCode(itemTwo.code, itemTwo.countrysEnglish, itemTwo.countrysChinese)"
                                           :value="itemTwo.code" v-for="itemTwo in areaList.phoneCountriesCodeList">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="input_box_number" style="display: inline-block;width:290px;">
                            <!--<span style="position:absolute;" v-if="item.whatsappCode">+{{item.whatsappCode}}</span>-->
                            <input :placeholder="$t('publish.input')" style="width:150px;" type="text"
                                   v-model="item.whatsApp">
                        </div>
                        <!-- <div class="person_input_email fl" style="width: 365px;margin-left: 25px">
                          <input type="text" v-model="item.whatsApp" :placeholder="$t('publish.input')">
                        </div> -->
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div @click="recommendMember()" class="recommend_btn cur">
                {{ $t("bulletFrame.recommendNow") }}
            </div>

        </div>
        <!--  推荐非会员  -->
        <div class="noMember_box auto" v-else>
            <p class="noMember_hint_one">{{ $t("recommendMember.nonMembersRecommend") }}</p>
            <div class="noMember_input_box">
                <input :placeholder="$t('recommendMember.linkedinWebsite')" type="text" v-model="linkedinWebsite">
            </div>
            <div style="margin-top: 30px;">
                <div style="margin-bottom: 10px;">WhatsApp</div>
                <!--<input type="text" v-model="whatsApp2" placeholder="WhatsApp">-->
                <div class="area-code">
                    <el-input :placeholder="$t('publish.input')/*请输入*/" class="input-with-select" v-model="whatsApp2">
                        <el-select :placeholder="$t('message.pleaseSelect')/*请选择*/" slot="prepend"
                                   v-model="whatsappCode">
                            <el-option :key="item.id" :label="'+'+item.code + '(' + item.countrysEnglish + ')'"
                                       :value="item.code"
                                       v-for="item in areaList.phoneCountriesCodeList"></el-option>
                        </el-select>
                    </el-input>
                </div>
            </div>

            <p class="file-name">{{ fileName }}</p>
            <div class="cur upload_btn auto">
                <img alt="" src="../assets/images/icon_upload@2x.png" width="20px">
                {{ $t("recommendMember.uploadBusinessCardResume") }}
                <el-upload
                        :action="actionUrl"
                        :before-upload="handleBefore"
                        :on-change="handleChange"
                        :on-error="handleError"
                        :on-success="handleSuccess"
                        :show-file-list="false"
                        class="upload-recommend"
                        list-type="text"
                        ref="upload"
                >
                    <el-button size="small" type="primary">{{ $t("recommendMember.clickUpload") }}</el-button>
                </el-upload>
            </div>
            <p class="noMember_hint_two">
                {{ $t("recommendMember.supportJpgPngPdfNotMoreThan3MB") }}
            </p>
            <div @click="recommendNoMember()" class="submit_btn auto cur">
                {{ $t("recommendMember.submit") }}
            </div>
        </div>
    </div>
</template>

<script>
    import loginHeader from "@/components/login/loginHeader";
    import {projectRecommend, recommendOtherMember} from "@/api/projectAbout";
    import {uploadResume} from "@/api/commonApi";
    import {selectCountriesCodeInfo} from '@/api/userApi'
    import {findMemberListByName} from "@/api/auxiliaryInterfaceApi";
    import {uploadingResume} from "@/api/uploadApi";
    import {Loading} from "element-ui";

    let loadingInstance = null;
    export default {
        name: "recommend",
        data() {
            return {
                actionUrl: uploadingResume,
                isTabs: 1,
                linkedinWebsite: '',
                whatsApp1: '',
                whatsApp2: '',
                mobileNumber: '',
                mobileCode: '86',
                whatsappCode: '86',
                areaList: [],
                mailBox: '',
                referralInfo: {},
                resume: '',
                fileName: '',

                memberName: '',//关键字
                memberList: [],//通过关键字检索的会员列表
            }
        },
        components: {
            loginHeader,
        },
        created() {
            //获取地区编号
            this.getArea();
        },
        methods: {
            formatCode(code, en, cn) {
                if (this.$i18n.locale == 'zh') {
                    return "+" + code + "(" + cn + ")";
                } else {
                    return "+" + code + "(" + en + ")";

                }
            },
            changeWhatsApp() {
                this.$forceUpdate();
            },
            //获取地区
            getArea() {
                selectCountriesCodeInfo().then((res) => {
                    if (res.code == 200) {
                        this.areaList = res.data;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            handleChange(file, fileList) {

                if (fileList.length > 1) {
                    this.$refs.upload.uploadFiles.splice(0, 1)
                }
            },
            handleSuccess(response, file, fileList) {
                if (response.code === 200) {
                    this.fileName = file.name;
                    this.$message({
                        message: this.$t('user.ploadSucceeded'),
                        type: 'success'
                    });
                    loadingInstance.close();
                    this.resume = response.data.path;
                } else {
                    loadingInstance.close();
                    this.$message.error(response.msg)
                }
            },
            handleError(err, file, fileList) {
                this.$message.error(err)
            },
            handleBefore(file) {
                loadingInstance = Loading.service({
                    text: 'Desperately uploading',//拼命上传中',
                    // spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            //memberList数据json处理
            memberListJson() {
                let results = [];
                this.memberList.forEach((item) => {
                    if (item.isChecked) {
                        let object = {
                            referralId: item.member_id,
                            referralName: item.member_name,
                            referralAvatar: item.avatar,
                            mobileNumber: item.mobileNumber,
                            mailBox: item.mailBox,
                            whatsApp: item.whatsApp,
                            whatsappCode: item.whatsappCode,
                            mobileCode: item.mobileCode,
                        };
                        results.push(object);
                    }
                });
                return JSON.stringify(results);
            },
            //会员给项目推荐其他会员
            recommendMember() {
                let formData = {
                    projectId: this.$route.query.projectId,
                    mobileNumber: this.mobileNumber,
                    mailBox: this.mailBox,
                    whatsApp: this.whatsApp1,
                    mobileCode: this.mobileCode,
                    whatsappCode: this.whatsappCode,
                    referralInfo: this.memberListJson(),
                    token: localStorage.getItem('token'),
                }
                this.isLoading = true;
                recommendOtherMember(formData).then((res) => {
                    this.isLoading = false;
                    if (res.code === 200) {
                        this.$message({message: res.msg, type: 'success'});
                        this.$router.go(-1)
                    } else if (res.code === 400) {
                        this.$message({message: res.msg, type: 'warning'});
                    } else {
                        this.$message.error(res?.msg ?? "未知错误");
                    }
                })
            },
            //会员给项目推荐非会员
            recommendNoMember() {
                let formData = {
                    isAllotConsultant: 1,
                    linkedinWebsite: this.linkedinWebsite,
                    projectId: this.$route.query.projectId,
                    projectName: this.$route.query.projprojectNameectId,
                    resume: this.resume,
                    sourceType: 3,
                    supervisorId: this.$route.query.supervisorId,
                    whatsApp: this.whatsApp2,
                    mobileCode: this.mobileCode,
                    whatsappCode: this.whatsappCode,
                    token: localStorage.getItem('token'),
                }
                this.isLoading = true;
                projectRecommend(formData).then((res) => {
                    this.isLoading = false;
                    if (res.code === 200) {
                        this.$message({message: res.msg, type: 'success'});
                        this.$router.go(-1)
                    } else if (res.code === 400) {
                        this.$message({message: res.msg, type: 'warning'});
                    } else {
                        this.$message.error(res?.msg ?? "未知错误");

                    }
                })
            },
            //通过关键字检索的会员列表
            getMemberListByName() {
                let formData = {
                    memberName: this.memberName,
                    pageNum: 1,
                    pageSize: 10,
                    token: localStorage.getItem('token'),
                }
                this.isLoading = true;
                findMemberListByName(formData).then((res) => {
                    this.isLoading = false;
                    if (res.code === 200) {
                        this.memberList = res.data;
                        this.memberList.forEach((item) => {
                            item.mobileNumber = '';
                            item.mailBox = '';
                            item.whatsApp = '';
                            item.isChecked = false;
                            item.whatsappCode = '86';
                            item.mobileCode = '86';
                        });

                    } else if (res.code === 204) {
                        this.$message({message: res.msg, type: 'warning'});
                    } else {
                        this.$message.error(res?.msg ?? "未知错误");
                    }
                })
            }


        }
    }
</script>

<style scoped>
    .container {
        width: 100%;
        min-height: 100%;
    }

    .recommend_nav {
        width: 1200px;
        text-align: center;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 29px;
        color: #000000;
        margin-top: 36px;
        margin-bottom: 30px;
    }

    .recommend_nav span:nth-child(2) {
        display: inline-block;
        margin-left: 86px;
    }

    .recommend_nav .recommend_nav_active {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .member_box {
        width: 540px;
    }

    .member_title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-bottom: 21px;
    }

    .member_title span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #707070;
    }

    .input_box_number, .input_box_email {
        width: 536px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        line-height: 50px;
        padding: 0 26px;
        box-sizing: border-box;
        margin-bottom: 29px;
    }

    .input_box_number input {
        background: #FAFAFA;
        height: 22px;
        /*border-left: 1px solid #d6d6d6;
        margin-left: 49px;
        padding-left: 45px;*/
        width: 300px;
    }

    .input_box_email input, .input_box_search input {
        background: #FAFAFA;
        height: 22px;
        width: 300px;
    }

    .input_box_search {
        width: 536px;
        height: 40px;
        background: #FAFAFA;
        border-radius: 131px;
        line-height: 40px;
        padding-left: 26px;
        box-sizing: border-box;
    }

    .search_btn {
        width: 65px;
        height: 40px;
        background: #000000;
        border-radius: 131px;
        text-align: center;
        line-height: 38px;
    }

    .person_item {
        margin-top: 35px;
    }

    .choose_box {
        width: 51px;
        padding-left: 5px;
        box-sizing: border-box;
        padding-top: 17px;
    }

    .person_msg_one {

    }

    .person_msg_one img {
        width: 49px;
        height: 49px;
        border-radius: 50%;
        margin-right: 12px;
    }

    .person_msg_one h2 {
        font-size: 15px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 17px;
        color: #000000;
        margin-top: 4px;
    }

    .person_msg_one p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        margin-top: 6px;
    }

    .person_msg_two {
        margin-top: 26px;
        line-height: 50px;
    }

    .person_msg_title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 94px;
    }

    .person_msg_title span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .person_input_number, .person_input_email {
        width: 391px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        padding: 0 26px;
        box-sizing: border-box;
    }

    .person_input_number span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .person_input_number input {
        height: 22px;
        border-left: 1px solid #d6d6d6;
        padding-left: 45px;
        background: #FAFAFA;
        margin-left: 49px;
    }

    .person_input_email input {
        background: #FAFAFA;
    }

    .recommend_btn {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        text-align: center;
        line-height: 48px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin: 42px auto 73px;
        transition: .3s all;
    }

    .recommend_btn:hover {
        background: #000000;
        color: #FFFFFF;
    }

    .noMember_box {
        width: 500px;
        min-height: 77vh;
    }

    .noMember_box .noMember_hint_one {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #707070;
        text-align: center;
    }

    .noMember_input_box {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #D6D6D6;
        margin-top: 30px;
    }

    .noMember_input_box input {
        width: 100%;
    }

    .upload_btn {
        position: relative;
        width: 274px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        line-height: 48px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-top: 40px;

    }

    .file-name {
        margin-top: 46px;
        text-align: center;
        margin-bottom: 0;
    }

    .upload_btn img {
        margin-bottom: 4px;
    }

    .inputFile {
        position: absolute;
        top: 0;
        left: 0;
        width: 358px;
        height: 50px;
        opacity: 0;
    }

    .noMember_hint_two {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #949494;
        text-align: center;
        margin-top: 5px;
    }

    .submit_btn {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        line-height: 48px;
        margin-top: 34px;
        transition: .3s all;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .submit_btn:hover {
        background: #000000;
        color: #FFFFFF;
    }
</style>
