module.exports = {
    language: {
        name: '中文'
    },
    user: {
        login: '登录',
        register: '注册',
        loginUsername: '请输入邮箱/手机号',
        ploadSucceeded: '上传成功',
        forgetPsdSendEmail: '发送邮箱成功',
        registSucceeded: '注册成功！审核结果会在24小时内反馈到您的联系邮箱，请注意查收',
        notesToBuy: "点击查看全部内容",
    },
    header: {
        home: '首页',
        memberClassify: '会员分类',
        member: '会员',
        company: '公司',
        project: '项目',
        personalCenter: '个人中心',
        wallet: '我的钱包',
        affiche: '活动公告',
        message: '消息中心',
        editData: '编辑资料',
        companyMaintains: '公司信息',
        accountManagement: '账号管理',
        orderManagement: '订单管理',
        myProject: '我的项目',
        myWallet: '我的钱包',
        logOut: '退出登录',
    },
    project: {
        title: '项目',
        whole: '全部',
        talent: '人才项目',
        financing: '融资项目',
        smart: '智慧项目',
        keyword: '关键字',
        publish: '发布项目',
        state: '状态',
        progress: '项目进行中',
        end: '项目结束',
        sort: '排序',
        composite: '综合排序',
        views: '浏览次数',
        total: '个项目',
        go: '前往',
        details: '项目详情',
        view: '看过',
        interested: '我感兴趣愿意免费沟通',
        eliminate: '取消免费沟通',
        recommend: '推荐人脉',
        buyNow: '立即购买',
        follow: '立即关注',
        followed: '已关注',
        pTime: '时间',
        pReason: '原因',
        videoCall: '视频通话',
        leaveCurrentChannel: '离开当前频道',
        endCurrentInterview: '结束当前访谈',
        remoteUser: '远程用户',
        joinedRoom: '加入房间成功',
    },
    publish: {
        title: '发布项目',
        position: '职位名称',
        treatment: '待遇',
        dollar: '美元',
        yuan: '元',
        brief: '项目简介',
        input: '请输入',
        inputStr: '请输入内容',
        releaseMode: '发布方式',
        publicRelease: '公开发布',
        vipSketch: '第一个月免费赠送VIP私密推荐',
        hide: '隐藏发布人',
        private: 'VIP私密推荐',
        whatSketch: '什么是VIP私密推荐',
        content: '内容',
        whole: '旨在跨越故事部分宽度的图像的最小宽度应为695像素。宽于695像素的图像将按比例调整大小。',
        text: '请输入正文',
        phone: '手机号',
        mailbox: '邮箱',
        bottomStr: '获得免费精准人脉推荐',
        button: '发布',
        tips: '提示',
        unPublished: '项目未成功发布',
        switch1: '是否切换至“找人”项目？',
        switch2: '是否切换至“找钱”项目？',
        switch3: '是否切换至“找智慧”项目？',
        cancel: '取消',
        determine: '确定',
        projectName: '项目名称',
        financingStage: '融资阶段',
        financingAmount: '融资金额',
        transferOfShares: '出让股份',
        otherDemands: '其他融资诉求',
        budget: '预算',
        fiveTime: '您可以预约5个时间段，建议挑选不同日期。可选择日期范围:系统时间往后7-37天之间',
        kefuliuyan: '关于预约给客服的留言',
        selectDateTime: '选择日期时间',
        selectDateTime1: '选择日期时间1',
        selectDateTime2: '选择日期时间2',
        selectDateTime3: '选择日期时间3',
        selectDateTime4: '选择日期时间4',
        selectDateTime5: '选择日期时间5',
        releaseInterview: '发布访谈',
        releasedOneDayAgo: '1天前发布',
        delPayPal: '此操作将删除该PayPal账户，是否继续',
    },
    home: {
        home: '首 页',
        findTalents: '找 人',
        findMoney: '找 钱',
        findWisdom: '找 智 慧',
        categories: '选择分类',
        selectUp: '最多选择1项',
        leaveUs: '给我们留言',
        phone: '电话',
        messageContent: '留言内容',
        more: '更多',
        submit: '提交',
    },
    //活动公告
    activityNotice: {
        activityNotice: '活动公告',
        all: '全部',
        enrolling: '报名中',
        enrolled: '已报名',
        enrollDead: '报名截止',
        enrollEnd: '报名结束',
        activityDead: '活动结束',
        keyword: '关键字',
        publishedProjects: '已发布项目',
        iWantToSignUp: '我要报名',
        payImmediately: '立即缴费',
        payed: '已缴费',
        eventDetails: '活动详情',
    },
    //消息中心
    message: {
        message: '消息中心',
        project: '项目',
        communicate: '沟通',
        customerServiceReply: '客服回复',
        tongyi: '同意',
        noNews: '暂无消息',
        member: '会员',
        designation: '职称',
        suozaididian: '所在地点',
        pleaseSelect: '请选择',
        noData: '无数据',
        memberName: '会员名称',
        companyName: '公司名称',
        immediateAttention: '立即关注',
        professionalSearchBar: '专业搜索',
        theGlobalMatching: '专业搜索',
        searchIndustry: '所属行业',
        noInformation: '未获得此会员相关信息',
    },

    //VIP私密推荐项目
    vipRecommend: {
        detailsOfMyProjects: '我参与的项目详情',
        vipPrivateRecommendation: 'VIP私密推荐',
        projectName: '项目名称',
        buyNow: '立即购买',
        reasonsForRecommendation: '推荐原因',
        reasonsForRefuse: '拒绝原因',
        postedComment: '发表评论',
        projectDetail: '项目详情',
        messageAboutBookingToCustomerService: '关于预约给客服的留言',
        publisherCancelsReason: '取消原因',
        mobileNumber: '手机号',
        email: '邮箱',
        recommendReason: '推荐原因',
        appointmentTime: '预约时间',
    },
    // 我的项目
    myProject: {
        all: '全部',
        myProject: '我的项目',
        myPublishedProject: '我发布的项目',
        myJoinProject: '我参与的项目',
        myInterview: '我的访谈',
        myContacts: '我的人脉',
        iAmInterestedInFreeCommunicationProjects: '我感兴趣愿意免费沟通项目',
        itemsIRecommendToOtherMembers: '我推荐给其他会员的项目',
        otherMembersRecommendProjectsToMe: '其他会员推荐给我项目',
        projectsIBrowsed: '我浏览过的项目',
        multiPersonCommunication: '多人沟通',
        recommendedPerson: '被推荐人',
        recommendPerson: '推荐人',
        init3Minute: '我发起的3分钟访谈',
        receive3Minute: '我收到的3分钟访谈',
        vipPrivateRecommendation3Minute: 'VIP私密推荐我的3分钟访谈',
        vipRecommendContact: '会员推荐给我的人脉',
        platformRecommendContact: '平台推荐给我的人脉',
        meToVipContact: '我推荐给会员的人脉',
        iFollowContact: '我关注的人脉',
        // number 人可免费沟通
        personFreeCommunication: '人可免费沟通',
        // number 人同意
        personAgreed: '人同意',
        // number 人查看
        toView: '人查看',
        // number 人推荐过此项目
        recommendProject: '人推荐过此项目',
        multiUserCommunicationTime: '多人沟通时间',
        vipExpireTime: 'VIP到期时间',
        vipOrder: 'VIP订单',
        orderSerialNumber: '订单编号',
        payerBankId: '付款方银行ID',
        vipPaymentTime: 'VIP交费时间',
        paymentAmount: '交费金额（$）',
        paymentStatus: '支付状态',
        assessmentResults: '评估结果',
        vipStartDate: 'VIP开始日期',
        vipExpirationDate: 'VIP到期日',
        afterSales: '售后',
        agreed: '已同意',
        rejected: '已拒绝',
        underEvaluation: '评估中',
        beInterested: '感兴趣',
        memberRecommendation: '会员推荐',
        have: '浏览过',
        selectAll: '全选',
        freeCommunication: '免费沟通',
        otherSideAgreed: '对方同意',
        otherSideRefused: '对方拒绝',
        cancelReservation: '取消预约',
        otherPartyHasCancelled: '对方已取消',
        interview: '访谈',
        interviewCompleted: '访谈完成',
        immediateEvaluation: '立即评价',
        multiPersonInvitationRecord: '多人邀约记录',
        multiPersonCommunicationTime: '多人沟通时间',
        progressOfInvitees: '邀约人进度',
        waitingForFeedback: '待反馈',
        dateOfInvitation: '发起邀约日期',
        recommendedTemplate: '推荐原因',
        timeOfAppointment: '预约时间',
        initiateAnInterview: '发起访谈',
        multiPersonFreeCommunicationContent: '多人免费沟通，您可以预约1个时间段。可选择日期范围：系统时间往后7-37天之间',
        releaseMode: '发布方式',
        publicRelease: '公开发布',
        goTo: '前往',
        basicProjectInfo: '项目基本信息',
        reasonsForRecommendation: '推荐原因',
        interviewTime: '访谈时间',
        operation: '操作',
        speedOfProgress: '进度',
        startTime: '发起时间',
        recommendedDetails: '推荐原因',
        buyNow: '立即购买',
        immediateAttention: '立即关注',
        willingToCommunicateForFree: '愿意免费沟通',
        multiPersonFreeCommunication: '多人免费沟通',
        refuse: '拒绝',
        interestedProject: '我感兴趣的项目',
        vipPrivateRecommendation: 'VIP私密推荐',
        recommendItem: '我推荐的项目',
        recommendedProjectToMe: '推荐给我的项目',
        multiPersonCommunication: '多人沟通',
        invitee: '被邀约人',
        vipPrivateRecommendedContact: 'VIP私密推荐人脉',
        price: '价格',
        recommendedType: '推荐类型',
        contactsRecommendedByMember: '会员推荐给我的人脉',
        networkRecommendedByPlatform: '平台推荐给我的人脉',
        contactsRecommendToMember: '我推荐给会员的人脉',
        recommendedTime: '推荐时间',
        focusOnTime: '关注时间',
        recommender: '推荐者',
        recommendedPerson: '被推荐者',
        getFreeAccurateNetworkRecommendation: '获取免费精准人脉推荐',
        receiver: '接收者',
        follower: '被关注者',
        unfollow: '取消关注',
        // 关注相关提示消息
        followMsg: {
            followSuccess: '关注成功',
            unfollowSuccess: '取消关注成功'
        },
        clickFrequentMsg: '点击太频繁了！',
        overLimit: '超出最大限制数量!',
        publish: '发布',
        detail: '查看详情',
        delete: '删除',
        edit: '编辑',
        vipBuy: 'VIP购买',
        noOrder: '无订单',
        participant: '参与人',
        pleaseInput: '请输入',
        evaluationInterview: '本次访谈评价',
        submit: '提交',
        editor: '编辑',
        // RecommendTable 中的消息提示
        recommendTableMsg: {
            refuseSucess: '拒绝成功',
            cancelSucess: '取消预约成功',
            postedCommentSuccess: '发表评论成功'
        },
        invitationNum: '邀约人数',
        agreedNum: '已同意人数',
        interview: '访谈',
        confirmDel: '此操作将永久删除该项目, 是否继续?',
    },
    //我发布的项目
    myPublishedProjects: {
        projectDetails: '项目详情',
        multiperson: "发起多人访谈",
        editProject: "编辑项目",
        declinedInvitation: "您已拒绝邀请",
        reasonInvitation: "拒绝邀请原因",
        communTopic: "沟通话题",
        cancelledAppointment: '您已取消预约',
    },
    ///我感兴趣愿意免费沟通的项目
    interestedProject: {
        publisherPush5Times: '发布者推送5个时间',
    },
    ///我推荐给其他会员的项目
    participateProjectProgress: {
        participateProjectProgress: '参与项目进度',

    },
    //我的访谈
    myInterview: {
        myInterviewDetail: '我的访谈详情',
    },
    memberClassification: {
        globalCapital: '全球资本',
        globalUniversities: '全球院校',
        globalHeadhunters: '全球猎头',
        companiesNumber: '公司数量',
        peopleNumber: '人数',
        company: '公司',
        location: '所在地点',
        select: '请选择',
        corporateName: '公司名称',
        enterCompanyName: '请输入公司名称',//
        goTo: '前往',
        globalAi: '全球交通AI领域',//
        individualAngel: '个人/天使',//
    },
    personalCenter: {
        personCenter: '个人中心',
        cancelAttention: '取消关注',
        buyNow: '立即购买',
        videoCommunication: '视频沟通',
        audioCommunication: '音频沟通',
        planExpiry: '购买须知',
        openTo: '打开',
        previewAttachment: '预览附件',
        evaluate: '评价',
        complaint: '投诉',
        mobileNumber: '手机号',
        companyWebsite: '公司官网',
        contactTime: '联系时间段',
        changeBackground: '更换背景',
        recommend: '推荐',
        acceptInvitation: '同意邀约',
        buy3Minites: '立刻购买他的3分钟沟通',
        gender: '性别',
        male: '男',
        female: '女',
        unknown: '未知'
    },
    // 推荐会员
    recommendMember: {
        recommendMember: '推荐会员',
        disclaimerAndMembership: '免责声明及会员资格',
        disclaimer: '免责声明( Disclaimer )，即法律明文规定的当事人对其不履行合同不承担违约责任的声明。不可抗力、货物本身的自然性质、货物的合理损耗和债权人的过错是免责的条件。免责声明(Disclaimer )，即法律明文规定的当事人对其不履行合同不承担违约责任的声明。不可抗力、货物本身的自然性质、货物的合理损耗和债权人的过错是免责的条件。免责声明( Disclaimer )，即法律明文规定的当事人对其不履行合同不承担违约责任的声明。不可抗力、货物本身的自然性质、货物的合理损耗和债权人的过错是免责的条件。',
        nonMembersRecommend: '您可上传非会员的名片，简历，领英网址推荐其成为BlessBoss网站会员 ',
        linkedinWebsite: '领英网址',
        uploadBusinessCardResume: '上传名片/简历',
        supportJpgPngPdfNotMoreThan3MB: '支持 jpg, png, pdf 不能超过3M ',
        submit: '提交',
        clickUpload: '点击上传',//
        sender: '发送人',//
    },
    // 账号管理
    accountManagement: {
        accountManagement: '账号管理',
        accountNumber: '账号',
        originalPassword: '原密码',
        newPassword: '新密码',
        confirmPassword: '确认密码',
        pleaseEnterTheOriginalPassword: '请输入原密码',
        pleaseEnterANewPassword: '请输入新密码',
        pleaseEnterTheNewPasswordAgain: '请再次输入新密码',
        changePassword: '修改密码',
        logOut: '退出登录',
        registerNow: '立即注册',
        thirdPartySocialAccountLogin: '三方社交账号登陆',
        editingMaterials: '编辑资料',
        selectFile: '选择文件',
        noFilesSelected: '未选择任何文件',
        fullName: '姓名',
        gender: '性别',
        male: '男',
        female: '女',
        officialWebsiteAddress: '官网地址',
        professionalTitle: '职称',
        voiceIntroduction: '语音介绍',
        uploadEnclosure: '上传附件',
        priceOf3MinutesCommunication: '3分钟沟通价格',
        lowestPrice: '最低价格',
        wikipediaLink: '维基百科链接',
        title: '标题',
        pleasePasteTheLink: '请粘贴链接',
        link: '链接',
        addMore: '更多添加',
        privacyRequirements: '私密性要求',
        welcomeToContact: '欢迎联系',
        refuseAllContact: '拒绝一切联系',
        pleaseContactMeInTheFollowingTimePeriod: '请在以下时间段联系我',
        addTo: '添加',
        see: '查看',
        companyMaintenance: '公司维护',
        project: '项目',
        mobile: '手机号',
        email: '邮箱',
        companyName: '公司名称'
    },
    // VIP
    vip: {
        vip: 'VIP',
        premiumFinancingServiceCharge: 'VIP融资服务收费',
        serviceContent: '服务内容',
        premiumChargesForFindingPeopleMoneyAndWisdom: 'VIP找人、找钱、找智慧收费',
        initiateA3MinuteInterview: '发起单人3分钟访谈',
        anAppointmentRecommended: '您可以预约5个时间段，建议挑选不通日期。可选日期范围：系统时间往后7-37天之间',
        myProject: '我的项目',
        buyNow: '立即购买',
    },
    //我的钱包
    myWallet: {
        all: '全部',
        wallet: '我的钱包',
        paypalAccount: 'Paypal账户',
        modify: '修改',
        delete: '删除',
        toBePaid: '待支付',
        paid: '已支付',
        Refunded: '已退款',
        Date: '日期',
        selectADate: '选择日期',
        query: '查询',
        orderId: '订单ID',
        transactionDate: '交易日期',
        paymentStatus: '支付状态',
        amountUSD: '金额（美元）',
        orderContent: '订单内容',
        recordsInTotal: '条记录',
        noDataAvailable: '暂无数据',
        payImmediately: '立即支付',
        applyForARefund: '申请退款',
        refundInProgress: '退款中',
        refundFailed: '退款失败',
        refundRequested: '已申请退款',
        refundApplicationSuccessful: '退款申请成功',
        paymentFailed: '支付失败',
        itWillJumpToMyWalletInSeconds: '5秒后将跳转到我的钱包',
        backToWallet: '返回钱包',
        youHavePaidSuccessfully: '您已支付成功',
        newlyAdded: '新增',
        premium: 'VIP',
        modifyPayPal: '修改PayPal账户',//
        addPayPal: '修改PayPal账户',//
        payment: '支付'
    },
    //弹框
    bulletFrame: {
        cancelReason: '请输入拒绝原因',
        updatePersonBack: '修改个人主页背景',
        uploadPictures: '上传图片',
        personMainPage: '个人主页面大图',
        saveSettings: '保存设置',
        operationSuccessful: '操作成功',
        confirm: '确认',
        confirmSubmission: '确认提交',
        recommendThisMember: '您可将此会员推荐给其他会员',
        recommendNow: '立即推荐',
        complaint: '投诉',
        theReasonForTheComplaint: '请输入投诉原因',
        publishComments: '发表评价',
        paymentStatus: '支付状态',
        refundReason: '请输入退款原因',
        purchaseAndRefundInstructions: '购买与退款说明：',
        recommendNonMembers: '推荐非会员',
        blessBoss: '您可上传非会员的名片、简历、领英地址推荐其成为BlessBoss网站会员',
        agreeToMakeAnAppointment: '同意预约',
        reasonForCancellation: '取消预约原因',
        recommendOther: '您可将其他会员推荐给此会员',//
        appointmentTwoHours: '若要取消预约，请在访谈2小时前操作',//
    },
    //我的访谈详情
    myInterviewDetail: {
        vipBuy: 'VIP购买',
        publicRelease: '公开发布',
        alreadyPublicRelease: '已公开发布',
        private: 'VIP私密推荐',
        interviewDetails: '访谈详情',
        selectAll: '全选',
        referenceInformation: '推荐人信息',
        reasonsForRecommendation: '推荐原因',
        interviewTime: '访谈时间',
        operation: '操作',
        speedOfProgress: '进度',
        startTime: '发起时间',
        recommendMail: '推荐邮件',
        willingToCommunicateForFree: '愿意免费沟通',
        refuse: '拒绝',
        cancelReservation: '取消预约',
        interview: '访谈',
        anImmediateRefund: '立即退款',
        refundHasBeenAppliedFor: '已申请退款',
        successfullyApplyForRefund: '申请退款成功',
        failedToApplyForRefund: '申请退款失败',
        immediateEvaluation: '立即评价',
        haveEvaluation: '已评价',
        go: '前往',
        invitee: '受邀者',
        orderSerialNumber: '订单编号',
        orderTime: '订单时间 ',
        money: '金额',
        paymentStatus: '支付状态',
        multiPersonCommunication: '发起多人沟通',
        manyPeopleOffer: '多人邀约',
        invitationBecause: '邀约原因',
        phone: '手机号',
        email: '邮箱',
        invitationTime: '邀约时间',
        optionDate: '选择日期',
        selectTime: '选择时间',
        cancel: '取 消',
        determine: '确 定',
        multiPersonInvitationRecord: '多人邀约记录',
        multiUserCommunicationTime: '多人沟通时间',
        progressOfInvitees: '邀约人进度',
        agreed: '已同意',
        waitingForFeedback: '待反馈',
        dateOfInvitation: '发起邀约日期',
        invitationNum: '邀约人数',
        agreedNum: '已同意人数',
        attention: '关注',
        YouHaveBeenWillingToCommunicateForFree: '您已愿意免费沟通',
        ThePublisherHasBeenCancelled: '发布者已取消',
        YouHaveToCancel: '您已取消',
        declinedInvitation: '拒绝邀请',
        selectTime: '请选择一个时间段接受您的预约申请',
        acceptInvitation: '接受邀请',
        haveInvitation: '您已接受邀请',
        publisher: '发布者',
        aPersonInterview: '发起单人访谈',
    },
    companyDetail: {
        title: '公司详情',
    },
    stripePay: {
        quarterly: '每季度',
        payWithBankCard: '或用银行卡支付',
        mail: '邮件',
        bankCard: '银行卡',
        cardNumber: '卡号',
        monthYear: '月/年',
        cardSecurityCode: '卡安全码(CVC)',
        countryOrAddress: '国家或地址',
        zipCode: '邮编',
    }
}
