<template>
    <div class="container">
        <loginHeader></loginHeader>
        <div class="register_box auto">
            <h2>sign up</h2>
            <p>Already have an account? Log in here.</p>
            <div class="input_box_one">
                <input placeholder="Your email" type="text" v-model="email"/>
            </div>
            <div class="input_box_one" v-if="type == 'member'">
                <input placeholder="Password" type="password" v-model="password"/>
            </div>
            <div class="input_box_one" v-if="type == 'member'">
                <input placeholder="Confirm password" type="password" v-model="confirmPassword"/>
            </div>
            <p class="file-name">{{fileName}}</p>
            <div class="register_btn cur auto">
                <img alt="" src="../../assets/images/icon_upload@2x.png" width="20px">
                Upload resume/business card
                <el-upload
                        :action="actionUrl"
                        :before-upload="handleBefore"
                        :on-change="handleChange"
                        :on-error="handleError"
                        :on-success="handleSuccess"
                        :show-file-list="false"
                        class="inputFile"
                        list-type="text"
                        ref="upload"
                >
                    <el-button size="small" type="primary">{{$t("recommendMember.clickUpload")}}</el-button>
                </el-upload>
            </div>
            <div class="hint_one">
                <span>PDF</span>
                <span>PNG</span>
                <span>JPG</span>
                Not more than 3M
            </div>
            <!-- 登录 -->
            <div @click="register" class="login_btn cur">sign up</div>
            <div class="hint_two">
                <p>By clicking "Sign up", you agree to the Terms of Use and <u @click="$router.push('/agreement')"
                                                                               class="cur">Membership Statement</u>.</p>
                <p>Continue with your social network:</p>
            </div>
            <!-- 三方登录 -->
            <div class="three_login">
                <img @click="linkedinLogin()" alt="" class="cur" src="../../assets/images/logoin_sfdl1@2x.png"
                     width="18px"/><!--领英-->
                <img @click="login('twitter')" alt="" class="cur" src="../../assets/images/logoin_sfdl2@2x.png"
                     width="18px"/><!--Twitter-->
                <img @click="login('facebook')" alt="" class="cur" src="../../assets/images/logoin_sfdl3@2x.png"
                     width="18px"/><!--Facebook-->
            </div>
        </div>
        <div class="pop_success" v-if="isPop">
            <div class="pop_success_box">
                <p>
                    <img alt="" class="cur" src="../../assets/images/icon_closed@2x.png" width="16px">
                </p>
                <h2>registration success!</h2>
                <div class="pop_hint">
                    The review result will be fed back to your
                    contact email within 24 hours, please check
                </div>
                <div @click="isPop = false" class="confirm_btn cur">
                    confirm
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import loginHeader from "../../components/login/loginHeader";
    import {nonmemberRegister, preRegisteredMemberRegister} from '@/api/loginApi';
    import {uploadingResume} from "@/api/uploadApi";
    import hello from 'hellojs/dist/hello.all';
    import {Loading as elLoading} from "element-ui";


    let loadingInstance = null;

    export default {
        name: "register",
        data() {
            return {
                fileName: '',
                actionUrl: uploadingResume,
                isPop: false,
                email: '',
                password: '',
                confirmPassword: '',
                file: '1',
                type: '',
                resumeUrl: ''
            };
        },
        components: {
            loginHeader,
        },
        methods: {
            handleChange(file, fileList) {
                console.log("改变", file, fileList)
                if (fileList.length > 1) {
                    this.$refs.upload.uploadFiles.splice(0, 1)
                }
            },
            handleSuccess(response, file, fileList) {
                loadingInstance.close();
                if (response.code === 200) {
                    this.fileName = file.name;
                    this.$message({
                        message: this.$t('user.ploadSucceeded'),
                        type: 'success'
                    });
                    this.resumeUrl = response.data.path;
                } else {
                    this.$message.error(response.msg)
                }
            },
            handleError(err, file, fileList) {
                this.$message.error(err)
            },
            handleBefore(file) {
                loadingInstance = elLoading.service({
                    text: 'Desperately uploading',//拼命上传中
                    // spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            // 预注册会员注册
            member() {
                let formData = {
                    account: this.email,
                    password: this.password,
                    confirmPassword: this.confirmPassword,
                    resume: this.resumeUrl,
                    identify: this.$store.state.identify,
                }
                preRegisteredMemberRegister(formData).then((res) => {
                    if (res.code == 200) {
                        this.$router.replace('/login');
                        this.$message.success(res.msg);
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },

            //非会员注册
            noMember() {
                let formData = {
                    contactMail: this.email,
                    resume: this.resumeUrl,
                }
                nonmemberRegister(formData).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(this.$t('user.registSucceeded'));
                    } else {
                        this.$message.error(res.msg)
                    }
                })

            },
            //注册
            register() {
                if (this.type == 'member') {
                    this.member();
                } else {
                    this.noMember();
                }
            },
            //领英登录
            linkedinLogin() {
                var url = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=780zfar2d5gddp' +
                    '&redirect_uri=https://www.blessboss.com/login&scope=r_liteprofile%20r_emailaddress%20w_member_social';
                window.open(url, "_blank");
            },

            //领英回调
            linkedinLoginCode() {
                console.log("lingkeind code = " + this.$route.query.code)
                let linkedinData = {
                    code: this.$route.query.code,
                }
                loginByLinkedin(linkedinData).then((res) => {
                    if (res.code == 200) {
                        this.$store.dispatch("userLogin", res.data)
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.$router.replace('/')
                    } else {
                        this.$message({
                            message: "Error: authorization login failed",
                            type: 'error'
                        });
                    }
                })
            },
            login: function (network) {
                var this_com = this;
                hello(network).login().then((response) => {
                    console.log("=============response=============");
                    console.log(response);
                }, (e) => {
                    console.log(e);
                });
                hello(network).api("me").then(function (json) {
                    console.log("=============memememememe=============");
                    console.log(json)
                    this_com.facebookData = {
                        userId: json._id,
                        userName: json.name,
                        avatar: json.picture,
                        email: json.email,
                    };
                    loginByFacebook(this_com.facebookData).then((res) => {
                        console.log("=============resresresresresresres=============");
                        console.log(res)
                        if (res.code == 200) {
                            this_com.$store.dispatch("userLogin", res.data)
                            this_com.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this_com.$router.replace('/')
                        } else {
                            this.$message({
                                message: "Error: authorization login failed",
                                type: 'error'
                            });
                        }
                    });
                });
            },
        },
        created() {
            this.type = this.$store.state.identify ? 'member' : '';
        },
        mounted() {
            if (this.$route.query.code) {
                this.linkedinLoginCode();
            }
            hello.init({
                facebook: '1350761151942887',
                twitter: 'mpaIzelt3PHMYcdXsC3NS6Y5O',
            }, {redirect_uri: 'https://www.blessboss.com/login'}, {scope: 'email'});
        }
    }
</script>

<style scoped>
    .container {
        height: 93.5vh;
    }

    .register_box {
        width: 500px;
        margin-top: 8%;
    }

    .register_box > h2 {
        text-align: center;
        font-size: 38px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 44px;
        color: #000000;
    }

    .register_box > p {
        text-align: center;
        font-size: 15px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 18px;
        color: #707070;
        margin-top: 14px;
        margin-bottom: 35px;
    }

    .input_box_one {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #d6d6d6;
        margin-bottom: 25px;
    }

    .input_box_one input {
        width: 300px;
    }

    .register_btn {
        width: 358px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-top: 10px;
        position: relative;
    }

    .file-name {
        margin-top: 46px !important;
        text-align: center !important;
        margin-bottom: 0 !important;
    }

    .inputFile {
        position: absolute;
        top: 0;
        left: 0;
        width: 358px;
        height: 50px;
        opacity: 0;
    }


    .register_btn img {
        margin-bottom: 4px;
    }

    .hint_one {
        text-align: center;
        font-size: 15px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 18px;
        color: #949494;
        margin-top: 8px;
    }

    .hint_one span {
        padding-right: 5px;
    }

    .login_btn {
        width: 140px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 50px;
        box-sizing: border-box;
        margin: 33px auto 44px;
        transition: .3s all;
    }

    .login_btn:hover {
        color: #FFFFFF;
        background: #000000;
    }

    .hint_two {
        text-align: center;
        width: 700px;
        font-size: 15px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 18px;
        color: #000000;
        word-wrap: normal;
        margin-left: -110px;
    }

    .hint_two p:nth-of-type(1) {
        margin-bottom: 14px;
    }

    .three_login {
        text-align: center;
    }

    .three_login img {
        margin-right: 14px;
        margin-top: 28px;
    }

    .three_login img:nth-child(4) {
        margin-right: 0;
    }

    .pop_success {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.22);
    }

    .pop_success_box {
        width: 600px;
        height: 319px;
        background: #FFFFFF;
        border-radius: 8px;
        margin: 13% auto;
        padding: 23px 22px;
        box-sizing: border-box;
    }

    .pop_success_box > p {
        text-align: right;
    }

    .pop_success_box > h2 {
        font-size: 38px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 44px;
        color: #000000;
        text-align: center;
        margin-top: 34px;
    }

    .pop_success_box .pop_hint {
        width: 320px;
        height: 36px;
        font-size: 15px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 18px;
        color: #707070;
        margin: 23px auto 0;
    }

    .pop_success_box .confirm_btn {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        text-align: center;
        line-height: 50px;
        margin: 42px auto 0;
    }
</style>
