<template>
    <div class="header">
        <div class="logo fl">
            <img alt="" height="50px" src="../assets/images/logo@2x.png"/>
        </div>
        <div class="header_nav">
            <ul class="fl">
                <li :class="{'nav_active' : this.$route.path === '/home' ,'fl':true}"
                    @click="$router.push(`/`)">
                    {{ $t("header.home") }}
                </li>
                <li :class="{'nav_active' : this.$route.path === '/classify' ,'fl':true}"
                    @click="$router.push('/user/index')">
                    {{ $t("header.memberClassify") }}
                </li>
                <!--@click="$router.push('/classify')">-->

                <li :class="{'nav_active' : this.$route.path === '/member' ,'fl':true}"
                    @click="$router.push('/user/index')">
                    {{ $t("header.member") }}
                </li>
                <!--@click="$router.push('/member')">-->

                <li :class="{'nav_active' : this.$route.path === '/companyList' ,'fl':true}"
                    @click="$router.push('/user/index')">
                    {{ $t("header.company") }}
                </li>
                <!--@click="$router.push('/companyList')">-->

                <li :class="{'nav_active' : this.$route.path === '/project/index' ,'fl':true}"
                    @click="$router.push('/user/index')">
                    {{ $t("header.project") }}
                </li>
                <!--@click="$router.push('/project/index')">-->

                <li :class="{'nav_active' : this.$route.path === '/user/index' ,'fl':true}"
                    @click="$router.push('/user/index')">
                    {{ $t("header.personalCenter") }}
                </li>
                <li :class="{'nav_active' : this.$route.path === '/wallet' ,'fl':true}"
                    @click="$router.push('/wallet')">
                    {{ $t("header.wallet") }}
                </li>
                <li :class="{'nav_active' : this.$route.path === '/activityNotice/index' ,'fl':true}"
                    @click="gopage(`/activityNotice/index`)">
                    {{ $t("header.affiche") }}
                </li>
                <li :class="{'nav_active' : this.$route.path === '/messageCenter' ,'fl':true}"
                    @click="gopage(`/messageCenter`)">
                    {{ $t("header.message") }}
                </li>
                <li class="clearfix"></li>
            </ul>

            <div class="user_box fr" v-if="token != null&&token!=''">
                <div style="display: inline-block;position: relative">
                    <img @click="gopage(`/messageCenter`)" alt="" class="cur" src="../assets/images/tz@2x.png"
                         width="20px"/>
                    <div style="position: absolute;top: 10px;right: -20px;width:25px;height:15px;line-height:15px;background-color: red ;font-size: 8px;border-radius: 5px;text-align: center;color: white;padding-bottom: 2px;"
                         v-show="isNew">new
                    </div>
                </div>
                <div class="user_line" style="display: inline-block">
                    <img :src="$store.state.currentUser.avatar?$store.state.currentUser.avatar:defaultAvatar"
                         @click="$router.push('/user/index?type=1')" alt="" class="userPhoto cur" height="40px"
                         width="40px"/>
                    <img alt="" class="down_logo cur" src="../assets/images/more_d@2x.png" width="15px"/>
                    <ul class="user_select">
                        <li @click="gopage('/user/redact')">{{ $t("header.editData") }}</li>
                        <li @click="gopage('/user/companyDetails')">{{ $t("header.companyMaintains") }}</li>
                        <li @click="gopage('/user/account')">{{ $t("header.accountManagement") }}</li>
                        <li @click="gopage('/myProject')">{{ $t("header.myProject") }}</li>
                        <li @click="gopage('/wallet')">{{ $t("header.myWallet") }}</li>
                        <li @click="online">{{ $t("header.logOut") }}</li>
                    </ul>
                </div>
            </div>
            <div class="user_box out_line_box fr" v-else>
                <div @click="gopage('/login')" class="fl cur" style="margin-right: 29px">
                    {{ $t("user.login") }}
                </div>
                <div @click="gopage('/register')" class="fl cur">{{ $t("user.register") }}</div>
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="switchover">
            <div
                    :class="[
          { switchover_active: type == 'en' },
          'switchover_item',
          'cur',
          'fl',
        ]"
                    @click="changeLanguage('en')"
            >
                EN
            </div>
            <div
                    :class="[
          { switchover_active: type == 'zh' },
          'switchover_item',
          'cur',
          'fl',
        ]"
                    @click="changeLanguage('zh')"
            >
                CN
            </div>
        </div>
    </div>
</template>

<script>
    import {logOutCurrentAccount} from "@/api/loginApi"
    import {projectDetails} from "@/api/projectAbout";
    import {selectNewsOfMemberUnRead} from "@/api/commonApi";

    export default {
        name: "showHeader",
        data() {
            return {
                type: "en",
                token: null,
                defaultAvatar: require('../assets/images/defaultphoto@2x.png'),
                msgNum: 0,
            };
        },
        watch: {
            // $route() {
            //   console.log(this.$route.path);
            //   localStorage.setItem('isNav', this.$route.meta.isNav);
            //   this.isNav = localStorage.getItem('isNav')
            // }
        },
        computed: {
            isNew() {
                return this.msgNum > 0;
            }
        },
        methods: {
            changeLanguage(type) {
                this.$i18n.locale = type;
                if (type == 'zh') {
                    this.$elLangZh();
                } else {
                    this.$elLangEn();
                }
                this.type = type;
                localStorage.setItem("languageSet", this.$i18n.locale); //将用户设置存储到localStorage以便用户下次打开时使用此设置
            },
            gopage(url) {
                this.$router.push(url);
            },
            changeNav(number) {
                this.isNav = number
            },
            //退出登录
            online() {
                let formData = {
                    token: localStorage.getItem('token'),
                }
                logOutCurrentAccount(formData).then((res) => {
                    if (res.code == 200) {
                        this.$store.dispatch("userLogin", null)
                        this.token = null
                        this.$router.push('/login')
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //获取用户未读推送信息
            selectNewsOfMemberUnRead() {
                let formData = {
                    token: localStorage.getItem('token'),
                    // token: "20220104c8409102d6f24ebeb791e1b777a0d934",
                }
                selectNewsOfMemberUnRead(formData).then((res) => {
                    if (res.code == 200) {
                        this.msgNum = res.data.newsNumber;
                    } else {
                        this.msgNum = 0;

                    }
                })
            }
        },
        mounted() {
            // this.isNav = localStorage.getItem('isNav')
            this.selectNewsOfMemberUnRead();
            this.token = localStorage.getItem('token')
            console.log(" this.token = this.token =", this.token)
            this.type = localStorage.getItem('languageSet') || 'en'
            if (this.type == 'zh') {
                this.$elLangZh();
            } else {
                this.$elLangEn();
            }


        }

    };
</script>

<style scoped>
    .header {
        position: relative;
        height: 66px;
        line-height: 66px;
        border-bottom: 1px solid #e5e5e5;
        padding: 0 40px;
    }

    .header_nav {
        width: 1318px;
        margin: 0 auto;
    }

    .header_nav li {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-right: 45px;
        cursor: pointer;
        transition: .3s all;
    }

    .header_nav li:hover {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold !important;
        color: #000000 !important;
    }

    .nav_active {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold !important;
        color: #000000 !important;
    }

    .userPhoto {
        margin-left: 20px;
        border-radius: 50%;
    }

    .down_logo {
        margin-left: 12px;
    }

    .out_line_box {
        font-size: 16px;
        font-family: BentonSans;
        font-weight: 500;
        color: #707070;
    }

    .switchover {
        font-size: 12px;
        font-family: BentonSans;
        font-weight: 500;
        color: #949494;
        position: absolute;
        top: 20px;
        right: 40px;
    }

    @media (max-width: 1530px) {
        .switchover {
            top: 85px;
        }

        .header_nav {
            width: 1318px;
            margin: 0 auto;
        }
    }

    .switchover_item {
        width: 28px;
        height: 27px;
        line-height: 27px;
        background: #ffffff;
        border-radius: 4px;
        text-align: center;
        margin-left: 6px;
    }

    .switchover_active {
        border: 1px solid #f1f1f1;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
    }

    .user_box {
        position: relative;
    }

    .user_line:hover .user_select {
        height: 332px;
        padding-top: 22px;
        border: 1px solid #E5E5E5;
        z-index: 999;
    }

    .user_select {
        position: absolute;
        top: 60px;
        left: -30px;
        width: 160px;
        height: 0;
        background: #FFFFFF;
        border-radius: 4px;
        box-sizing: border-box;
        line-height: 19px;
        text-align: center;
        transition: .3s all;
        overflow: hidden;
    }

    .user_select li {
        width: 127px;
        margin: 0 auto 24px;
    }

    .user_select li:last-child {
        border-top: 1px solid #E5E5E5;
        line-height: 53px;
    }
</style>
