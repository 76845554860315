import { render, staticRenderFns } from "./showFooter.vue?vue&type=template&id=56e9653a&scoped=true&"
import script from "./showFooter.vue?vue&type=script&lang=js&"
export * from "./showFooter.vue?vue&type=script&lang=js&"
import style0 from "./showFooter.vue?vue&type=style&index=0&id=56e9653a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56e9653a",
  null
  
)

export default component.exports