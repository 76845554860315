<template>
    <div class="">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "user"
    }
</script>

<style scoped>

</style>
